import React, { useState } from "react";
import styled from 'styled-components';
import {MdDeleteForever} from 'react-icons/md';
import SweetAlert from "react-bootstrap-sweetalert";

const PostCard = ({data}) => {

    const [confirm_both, setconfirm_both] = useState(false)
    const [success_dlg, setsuccess_dlg] = useState(false)
    const [dynamic_title, setdynamic_title] = useState("")
    const [dynamic_description, setdynamic_description] = useState("")

    const [selectedId, setSelectedId] = useState(null)
    const [selectedName, setSelectedName] = useState('')

    return(
        <>
        <EnterpriseCardContainer>
            <Cover style={{backgroundImage:'url("' + data?.media + '")'}} onClick={() => {
                setTimeout(() => {
                    window.open(`${window.location.href}/id?${data.id}`, '_parent')
                }, 0);
            }}></Cover>
            <Content onClick={() => {
                setTimeout(() => {
                    window.open(`${window.location.href}/id?${data.id}`, '_parent')
                }, 0);
            }}>
                <p style={{fontWeight:600, lineHeight:'1.2'}}>{data.title}</p>
            </Content>
            <CloseButton onClick={() => {
                setSelectedId(data.id)
                setSelectedName(data.name)
                setconfirm_both(true)
            }}><MdDeleteForever size="1.5rem" color="#fff"/></CloseButton>
        </EnterpriseCardContainer>

        {confirm_both ? (
            <SweetAlert
                title={`Tem certeza que deseja excluir a construtora ${selectedName} ?`}
                warning
                showCancel
                cancelButtonText='Cancelar'
                confirmBtnBsStyle="success"
                confirmButtonText='Excluir'
                cancelBtnBsStyle="danger"
                onConfirm={() => {
                    fetch(process.env.REACT_APP_API_URL + '/delete-post/' + selectedId,{
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem('token')}`,
                          },
                    })
                    .then((res) => res.json())
                    .then(response => {
                        setconfirm_both(false)
                        setsuccess_dlg(true)
                        setdynamic_title("Deletado")
                        setdynamic_description("Usuário deletado.")
                    })
                }}
                onCancel={() => {
                setconfirm_both(false)
                setsuccess_dlg(true)
                setdynamic_title("Cancelado")
                setdynamic_description("O usuário ainda está cadastrado.")
                }}
            >
                Esta exclusão é permanente e não poderá ser revertida.
            </SweetAlert>
            ) : null}

            {success_dlg ? (
                <SweetAlert
                success
                title={dynamic_title}
                onConfirm={() => {
                    setTimeout(() => {
                        window.location.reload()
                    }, 300);
                }}
                >
                {dynamic_description}
                </SweetAlert>
            ) : null}

        </>
    )
}

export default PostCard;

const EnterpriseCardContainer = styled.div`
    width:160px;
    height:200px;
    box-shadow:0 0 5px rgba(0,0,0,0.5);
    border-radius:8px;
    padding:10px;
    transition:0.2s;
    position:relative;
    cursor:pointer;

    &:hover{
        transform:scale(1.03);
    }
`;

const Cover = styled.div`
    background-position:center center;
    background-size:cover;
    width:100%;
    height:60px;
`;

const Content = styled.div`
    text-align:center;
    padding-top:10px;

    h3{
        font-size:16px;
    }
`;

const CloseButton = styled.div`
    position:absolute;
    top:-8px;
    z-index:10;
    right:-8px;
    background-color:#B91C1C;
    border-radius:50%;
    cursor:pointer;
    width:30px;
    height:30px;
    display:flex;
    align-items:center;
    justify-content:center;
    box-shadow:0 0 5px rgba(0,0,0,0.3);

    &:hover{
        transform:scale(1.2);
    }
`;