import React, { useState, useRef } from "react"
import {
  Button,
    Card,
    CardBody,
    Col,
    Input,
    NavItem,
    NavLink,
    Row,
    Spinner,
    TabContent,
    TabPane
  } from "reactstrap"
import Breadcrumbs from "../../../../components/Common/Breadcrumb";
import styled from 'styled-components';
import classnames from "classnames"
import { Link } from "react-router-dom"
import Step1 from "./Step1"
import Step2 from "./Step2"
import Step3 from "./Step3"
import Step4 from "./Step4"
import Step5 from "./Step5"

const AddConstruction = () => {
    const [activeTab, setactiveTab] = useState(1);
    const [childDataStep1, setChildDataStep1] = useState({});
    const [childDataStep2, setChildDataStep2] = useState({});
    const [childDataStep3, setChildDataStep3] = useState({});
    const [childDataStep4, setChildDataStep4] = useState({});
    const [childDataStep5, setChildDataStep5] = useState({});
    const [typeRent, setTypeRent] = useState([]);
    const [sendProp, setSendPro] = useState([])
    const [status, setStatus] = useState(0)
  
  
    function toggleTab(tab) {
      if (activeTab !== tab) {
        if (tab >= 1 && tab <= 2) {
          setactiveTab(tab)
        }
      }
    }
  
    const handleChildStep1 = (receivedChildData) => {
      setChildDataStep1(receivedChildData);
    };
  
    const handleChildStep2 = (receivedChildData) => {
      setChildDataStep2(receivedChildData);
      console.log(receivedChildData)
    };
  
    const handleChildStep3 = (receivedChildData) => {
      setChildDataStep3(receivedChildData);
    };
  
     const handleChildStep4 = (receivedChildData) => {
       setChildDataStep4(receivedChildData);
     };
  
    const handleChildStep5 = (receivedChildData) => {
      setChildDataStep5(receivedChildData);
    };
  
    const handleSubmit = (event) => {
      event.preventDefault();
       document.getElementById('load').classList.add('active');

       const data = new FormData()

       data.append('name', childDataStep2?.name)
       data.append('slug', childDataStep2?.urlParameter)
       data.append('city', childDataStep2?.city?.label)
       data.append('state', childDataStep2?.state?.value)
       data.append('logo', childDataStep2?.selectedFile?.file)


      fetch(process.env.REACT_APP_API_URL + '/create-construction', {
        method: 'POST',
        body: data,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      .then(response => response.json())
      .then(async resp => {

           setTimeout(() => {
             window.open('./../cadastro-propriedade', "_parent")
           }, 300);
      
      })
        .catch(error => {
          console.error(error);
        });    
    }

    return(
    <React.Fragment>
        <div className="page-content">
            <Breadcrumbs title="Gestão de Imóveis" breadcrumbItem="Novo Imóvel" />
            <Row>
            <Col lg="12">
                <Card>
                <CardBody>
                    <h4 className="card-title">Adicionar construtora</h4>
                    <div className="form-wizard-wrapper wizard clearfix">
                        <div className="steps clearfix">
                            <ul>
                            <NavItem
                                className={classnames({ current: activeTab === 1 })}>
                                <NavLink
                                className={classnames({ current: activeTab === 1 })}
                                onClick={() => {
                                    setactiveTab(1)
                                }}
                                >
                                <span className="number">1.</span>{" "}
                                    Sobre a construtora
                                </NavLink>
                            </NavItem>
                            <NavItem className={classnames({ current: activeTab === 2 })}>
                                <NavLink
                                className={classnames({ active: activeTab === 2 })}
                                onClick={() => {
                                    setactiveTab(2)
                                }}
                                >
                                <span className="number">2.</span>
                                Confirmar cadastro
                                </NavLink>
                            </NavItem>
                            </ul>
                        </div>
                        <div className="content clearfix">
                            <TabContent
                            activeTab={activeTab}
                            className="body"
                            >
                            {/* <TabPane tabId={1}>
                                <Step1 handleChildStep1={handleChildStep1}/>
                            </TabPane> */}

                            <TabPane tabId={1}>
                                <Step2 handleChildStep2={handleChildStep2}/>
                            </TabPane>
{/* 
                            <TabPane tabId={3}>
                                <Step3 handleChildStep3={handleChildStep3}/>
                            </TabPane>

                            <TabPane tabId={4}>
                                <Step5
                                  handleChildStep5={handleChildStep5}
                                  title={childDataStep2?.name}
                                  url={childDataStep2?.urlParameter}
                                  send={sendProp}
                                />
                            </TabPane> */}

                            <TabPane tabId={2}>
                                <div className="row justify-content-center">
                                <Col lg="6">
                                    <div className="text-center">
                                    <div className="mb-4">
                                        <i className="mdi mdi-check-circle-outline text-success display-4" />
                                    </div>
                                    <div>
                                        <h5>Tudo pronto, confirmar cadastro.</h5>
                                        <p className="text-muted">
                                        Você pode voltar e conferir tudo antes se quiser.
                                        </p>
                                        <Row>

                                    </Row>
                                        <Button onClick={handleSubmit}>Enviar</Button>
                                    </div>
                                    </div>


                                </Col>
                                </div>
                            </TabPane>
                            </TabContent>
                        </div>
                        <div className="actions clearfix">
                            <ul>
                            <li
                                className={
                                activeTab === 1 ? "previous disabled" : "previous"
                                }
                            >
                                <Link
                                to="#"
                                className="btn btn-primary"
                                onClick={() => {
                                    toggleTab(activeTab - 1)
                                }}
                                >
                                Anterior
                                </Link>
                            </li>
                            <li
                                className={activeTab === 2 ? "next disabled" : "next"}
                            >
                                <Link
                                to="#"
                                className="btn btn-primary"
                                onClick={() => {
                                    toggleTab(activeTab + 1)
                                }}
                                >
                                Próximo
                                </Link>
                            </li>
                            </ul>
                        </div>
                        </div>
                </CardBody>
                </Card>
            </Col>
            </Row>

        </div>

        <Load id="load">
          <Spinner className="me-2" color="success" />
        </Load>
    </React.Fragment>
    )
}

export default AddConstruction;

const Load = styled.div`
  display:none;

  &.active{
    display:flex;
    position: fixed;
    top:0;
    bottom:0;
    left:0;
    right:0;
    background-color: rgba(0,0,0,0.5);
    align-items: center;
    justify-content: center;
    z-index:99999999999999;
  }
`;