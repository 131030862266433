import React from "react";
import {
  Card,
  CardBody,
  Col,
  Row,
} from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import styled from 'styled-components';
import {BsBuildingAdd, BsBuildingGear, BsBuilding, BsHouseDoor, BsBoundingBoxCircles, BsBoundingBox, BsBoxSeam} from 'react-icons/bs';

const PropertyRegister = () => {

    return(
    <React.Fragment>
        <div className="page-content">
            <Breadcrumbs title="Gestão de Imóveis" breadcrumbItem="Novo Imóvel" />
            <Row>
            <Col lg="12">
                <Card>
                <CardBody>
                    <h4 className="card-title">Tipo do imóvel</h4>
                    <OptionsGrid>
                        <Option onClick={() => {
                            setTimeout(() => {
                                window.open('./../novo-empreendimento', '_parent')
                            }, 0);
                        }}><BsBuilding size="2.2rem"/><p>Empreendimento</p></Option>
                        <Option onClick={() => {
                            setTimeout(() => {
                                window.open('./../nova-construtora', '_parent')
                            }, 0);
                        }}><BsBuildingGear size="2.2rem"/><p>Construtora</p></Option>
                        <Option onClick={() => {
                            setTimeout(() => {
                                window.open('./../novo-apartamento', '_parent')
                            }, 0);
                        }}><BsBuildingAdd size="2.2rem"/><p>Apartamento</p></Option>
                        <Option onClick={() => {
                            setTimeout(() => {
                                window.open('./../nova-casa', '_parent')
                            }, 0);
                        }}><BsHouseDoor size="2.2rem"/><p>Casa</p></Option>
                        <Option onClick={() => {
                            setTimeout(() => {
                                window.open('./../novo-terreno', '_parent')
                            }, 0);
                        }}><BsBoundingBoxCircles size="2.2rem"/><p>Terreno residencial</p></Option>
                        <Option onClick={() => {
                            setTimeout(() => {
                                window.open('./../novo-galpao-logistico', '_parent')
                            }, 0);
                        }}><BsBoxSeam size="2.2rem"/><p>Galpão logístico</p></Option>
                        <Option onClick={() => {
                            setTimeout(() => {
                                window.open('./../novo-terreno-logistico', '_parent')
                            }, 0);
                        }}><BsBoundingBox size="2.2rem"/><p>Terreno logístico</p></Option>
                    </OptionsGrid>
                </CardBody>
                </Card>
            </Col>
            </Row>
        </div>
    </React.Fragment>
    )
}

export default PropertyRegister;

const OptionsGrid = styled.div`
    display:grid;
    margin:30px 0;
    padding:20px;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap:30px;
`;

const Option = styled.div`
    background-color:#888;
    border-radius:10px;
    height:150px;
    width:200px;
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    color:#fff;
    gap:20px;
    padding-top:15px;
    cursor:pointer;

    &:hover{
        background-color:rgb(0, 62, 14);
    }
`;