import { useState, useEffect } from 'react';
import './style.scss';
import {
  Col,
  Form,
  Input,
  Row
} from "reactstrap"

const Step1 = ({ handleChildStep1 }) => {
  const [owner, setOwner] = useState('');
  const [whatsapp, setWhatsapp] = useState('');
  const [unity, setUnity] = useState('');
  const [objective, setObjective] = useState('');
  const [typeImmobile, setTypeImmobile] = useState('');
  const [fee, setFee] = useState('');
  const [iptu, setIptu] = useState('');
  const [calcao, setCalcao] = useState(false);
  const [seguro, setSeguro] = useState(false);
  const [fiador, setFiador] = useState(false);
  const [observation, setObservation] = useState('');
  const [construction, setConstruction] = useState('');
  const [enterprise, setEnterprise] = useState('');
  const [partnership, setPartnership] = useState('');

  const dataToSend = {
    owner,
    whatsapp,
    unity,
    objective,
    typeImmobile,
    fee,
    iptu,
    calcao,
    seguro,
    fiador,
    observation,
    construction,
    enterprise,
    partnership
  };

  useEffect(() => {
    handleChildStep1(dataToSend);
  }, [owner, 
      whatsapp, 
      unity, 
      objective, 
      typeImmobile, 
      fee, 
      iptu, 
      calcao, 
      seguro, 
      fiador, 
      observation, 
      construction, 
      enterprise, 
      partnership]);

  return(
    <div>
      <Form>
        <Row>
          <h5 className="title-proprietario">Sobre o proprietário</h5>
          <Row>
            <Col md={6}>
              <Row className="mb-3">
                <label htmlFor="txtFirstNameBilling"
                  className="col-lg-3 col-form-label">Nome</label>
                <div className="col-lg-9">
                  <Input 
                    id="txtFirstNameBilling" 
                    name="txtFirstNameBilling" 
                    placeholder="Nome do proprietário"
                    type="text" 
                    className="form-control" 
                    value={owner}
                    onChange={e => setOwner(e.target.value)}
                  />
                </div>
              </Row>
            </Col>
            <Col md={6}>
              <Row className="mb-3">
                <label htmlFor="txtLastNameBilling"
                  className="col-lg-3 col-form-label">Telefone</label>
                <div className="col-lg-9">
                  <Input 
                    id="txtLastNameBilling" 
                    name="txtLastNameBilling" 
                    placeholder="Telefone do proprietário"
                    type="text" 
                    className="form-control"
                    value={whatsapp}
                    onChange={e => setWhatsapp(e.target.value)}
                  />
                </div>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Row className="mb-3">
                <label htmlFor="txtCompanyBilling"
                  className="col-lg-3 col-form-label">Unidade</label>
                <div className="col-lg-9">
                  <Input 
                    id="txtCompanyBilling" 
                    name="txtCompanyBilling" 
                    placeholder="Identificação da propriedade"
                    type="text" 
                    className="form-control" 
                    value={unity}
                    onChange={e => setUnity(e.target.value)}
                  />
                </div>
              </Row>
            </Col>
          </Row>
        </Row>

        <Row>
          <h5 className="title-negocio">Sobre o negócio</h5>                            
          <Row>
            <Col md={6}>
              <Row className="mb-3">
                <label htmlFor="txtAddress1Billing"
                  className="col-lg-3 col-form-label">Negócio</label>
                <div className="col-lg-9">
                  <Input 
                    type="select" 
                    id="ddlCreditCardType" 
                    name="ddlCreditCardType"
                    className="form-select"
                    value={objective}
                    onChange={e => setObjective(e.target.value)}
                  >
                    <option value={null}>Selecione um negócio</option>
                    <option value="sell">Venda</option>
                    <option value="rent">Locação</option>
                  </Input>
                </div>
              </Row>
            </Col>
            <Col md={6}>
              <Row className="mb-3">
                <label htmlFor="txtAddress1Billing"
                  className="col-lg-3 col-form-label">Tipo Imóvel</label>
                <div className="col-lg-9">
                  <Input 
                    type="select" 
                    id="ddlCreditCardType" 
                    name="ddlCreditCardType"
                    className="form-select"
                    value={typeImmobile}
                    onChange={e => setTypeImmobile(e.target.value)}
                  >
                    <option value={null}>Selecione um tipo</option>
                    <option value="land_shed">Galpoões e terrenos logísticos</option>
                    <option value="roof">Coberturas</option>
                    <option value="facing_sea">Apartamentos Frente mar</option>
                    <option value="sea_court">Apartamentos Quadra mar</option>
                    <option value="apartament_dif">Apartamentos Diferenciados</option>
                    <option value="plant">Apartamentos na planta</option>
                    <option value="others_apartament">Outros Apartamentos</option>
                    <option value="terrain">Terrenos residenciais</option>
                    <option value="house">Casa</option>
                  </Input>
                </div>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Row className="mb-3">
                <label htmlFor="txtCityBilling" className="col-lg-3 col-form-label">Condomínio</label>
                <div className="col-lg-9">
                  <Input 
                    id="txtCityBilling" 
                    name="txtCityBilling" 
                    type="text" 
                    placeholder="Condomínio"
                    className="form-control" 
                    value={fee}
                    onChange={e => setFee(e.target.value)}
                  />
                </div>
              </Row>
            </Col>
            <Col md={6}>
              <Row className="mb-3">
                <label htmlFor="txtStateProvinceBilling"
                  className="col-lg-3 col-form-label">IPTU</label>
                <div className="col-lg-9">
                  <Input 
                    id="txtStateProvinceBilling"
                    name="txtStateProvinceBilling" 
                    type="text" 
                    placeholder="IPTU"
                    className="form-control" 
                    value={iptu}
                    onChange={e => setIptu(e.target.value)}
                  />
                </div>
              </Row>
            </Col>
          </Row>
        </Row>

        <Row>
          <Col md={6}>
            <Row className="mb-3">
              <label htmlFor="txtTelephoneBilling"
                className="col-lg-3 col-form-label">Garantia de Locação</label>
              <div className="col-lg-9">
                <div className="content-location">
                  <Input 
                    id="txtTelephoneBilling" 
                    name="txtTelephoneBilling"
                    type="checkbox" 
                    className="form-control" 
                    value={calcao}
                    onChange={e => setCalcao(e.target.value)}
                  />
                  <span>Déposito Caução</span>
                </div>
                <div className="content-location">
                  <Input 
                    id="txtTelephoneBilling"
                    name="txtTelephoneBilling"
                    type="checkbox" 
                    className="form-control" 
                    value={seguro}
                    onChange={e => setSeguro(e.target.value)}
                  />
                  <span>Seguro Fiança</span>
                </div>
                <div className="content-location">
                  <Input 
                    id="txtTelephoneBilling" 
                    name="txtTelephoneBilling"
                    type="checkbox" 
                    className="form-control" 
                    value={fiador}
                    onChange={e => setFiador(e.target.value)}
                    />
                  <span>Fiador</span>
                </div>
              </div>
            </Row>
          </Col>
          <Col md={6}>
            <Row className="mb-3">
              <label htmlFor="txtFaxBilling" className="col-lg-3 col-form-label">Observação</label>
              <div className="col-lg-9">
                <Input 
                  id="txtFaxBilling" 
                  name="txtFaxBilling" 
                  type="textarea"
                  className="form-control" 
                  value={observation}
                  onChange={e => setObservation(e.target.value)}
                />
              </div>
            </Row>
          </Col>
        </Row>  

        <Row>
          <Col md={6}>
            <Row className="mb-3">
              <label htmlFor="txtAddress1Billing"
                className="col-lg-3 col-form-label">Construtora</label>
              <div className="col-lg-9">
                <Input 
                  type="select" 
                  id="ddlCreditCardType" 
                  name="ddlCreditCardType"
                  className="form-select"
                  value={construction}
                  onChange={e => setConstruction(e.target.value)}
                >
                  <option value="">Construtoras Cadastradas</option>
                  <option value="AE">Embraed</option>
                  <option value="AE">FG Empreendimentos</option>
                  <option value="AE">Incorporadora Cechinel</option>
                  <option value="AE">Pasqualotto&GT</option>
                </Input>
              </div>
            </Row>
          </Col>
          <Col md={6}>
            <Row className="mb-3">
              <label htmlFor="txtAddress1Billing"
                className="col-lg-3 col-form-label">Empreendimento</label>
              <div className="col-lg-9">
                <Input 
                  type="select" 
                  id="ddlCreditCardType" 
                  name="ddlCreditCardType"
                  className="form-select"
                  value={enterprise}
                  onChange={e => setEnterprise(e.target.value)}
                >
                  <option value="">Empreendimentos Cadastradas</option>
                  <option value="AE">Acqualina Residence</option>
                  <option value="VI">Aurora</option>
                </Input>
              </div>
            </Row>
          </Col>
        </Row>
        
        <Row>
          <Col md={6}>
            <Row className="mb-3">
              <label htmlFor="txtFaxBilling" className="col-lg-3 col-form-label">Parceria com corretor</label>
              <div className="col-lg-9">
                <Input 
                  id="txtFaxBilling" 
                  name="txtFaxBilling" 
                  type="text" 
                  placeholder="Parceria com corretor"
                  className="form-control" 
                  value={partnership}
                  onChange={e => setPartnership(e.target.value)}
                />
              </div>
            </Row>
          </Col>
        </Row>
      </Form>
    </div>
  )
}

export default Step1