import React, { useState } from "react"
import { Card, CardBody, Table, CardTitle, Col, Pagination, PaginationItem, PaginationLink } from "reactstrap"
import { Link } from "react-router-dom"
import moment from "moment";

const LatestTransaction = ({imoveis}) => {
    const itemsPerPage = 4;
    const [currentPage, setCurrentPage] = useState(1);

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = imoveis?.slice(indexOfFirstItem, indexOfLastItem);

    const totalPages = Math.ceil(imoveis?.length / itemsPerPage);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    return (
        <Col lg={8}>
            <Card>
                <CardBody>
                    <CardTitle className="h4 mb-4">Últimos imóveis atualizados</CardTitle>
                    <div className="table-responsive">
                        <Table className="table-centered">
                            <thead>
                                <tr>
                                    <th scope="col">Data</th>
                                    <th scope="col">Código</th>
                                    <th scope="col">Título</th>
                                    <th scope="col">Valor</th>
                                    <th scope="col" colSpan="2">Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentItems?.map((imovel, index) => (
                                <tr key={index}>
                                    <td>{moment(imovel?.updated_at).format('DD/MM/YYYY')}</td>
                                    <td>{imovel?.sku}</td>
                                    <td>{imovel?.name}</td>
                                    <td>{(imovel?.price / 100).toLocaleString('pt-br', {minimumFractionDigits: 2})}</td>
                                    <td>
                                        {imovel?.status === 1 ? <span className="badge badge-soft-success font-size-12">Ativo</span> : imovel.status === 2 ? <span className="badge badge-soft-warning font-size-12">Vendido</span> : <span className="badge badge-soft-danger font-size-12">Inativo</span>}
                                    </td>
                                    <td><Link to="#" className="btn btn-primary btn-sm">Ver</Link></td>
                                </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                    <div className="mt-3">
                        <Pagination className="pagination pagination-rounded justify-content-center mb-0">
                        <PaginationItem disabled={currentPage === 1}>
                            <PaginationLink onClick={() => handlePageChange(currentPage - 1)}>Anterior</PaginationLink>
                        </PaginationItem>
                        {[...Array(totalPages).keys()].map((page) => (
                            <PaginationItem key={page} active={page + 1 === currentPage}>
                            <PaginationLink onClick={() => handlePageChange(page + 1)}>{page + 1}</PaginationLink>
                            </PaginationItem>
                        ))}
                        <PaginationItem disabled={currentPage === totalPages}>
                            <PaginationLink onClick={() => handlePageChange(currentPage + 1)}>Próximo</PaginationLink>
                        </PaginationItem>
                        </Pagination>
                    </div>
                </CardBody>
            </Card>
        </Col>
    )
}

export default LatestTransaction