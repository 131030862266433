import { useState, useEffect, useRef } from 'react';
import './style.scss';
import {
  Button,
  Col,
  Form,
  Input,
  Row
} from "reactstrap";
import Select from "react-select";
import { Editor } from '@tinymce/tinymce-react';
import Dropzone from 'react-dropzone';
import { Link } from 'react-router-dom/cjs/react-router-dom';

const Step2 = ({ handleChildStep2, enterprise }) => {
  const editorRef = useRef(null);
  const [name, setName] = useState('');
  const [urlParameter, setUrlParameter] = useState('');
  const [cep, setCep] = useState('');
  const [address, setAddress] = useState('');
  const [number, setNumber] = useState('');
  const [complement, setComplement] = useState('');
  const [district, setDistrict] = useState('');
  const [state, setState] = useState([]);
  const [statesAddress, setStatesAddress] = useState([])
  const [citiesAddress, setCitiesAddress] = useState([])
  const [country, setCountry] = useState('Brasil');
  const [city, setCity] = useState([]);
  const [construction, setConstruction] = useState([]);
  const [ constructionList, setConstructionList] = useState([]);
  const [description, setDescription] = useState('');
  const [type, setType] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [logo, setLogo] = useState('')
  const [video, setVideo] = useState('')
 
  const dataToSend = {
    name,
    urlParameter,
    cep,
    address,
    number,
    complement,
    district,
    city,
    description,
    state,
    country,
    type,
    selectedFile,
    construction,
    video
  };

  useEffect(() => {
    handleChildStep2(dataToSend);
  }, [name, 
      urlParameter, 
      cep, 
      address, 
      number, 
      complement, 
      district, 
      city,
      description,
      state,
      country,
      type,
      selectedFile,
      construction,
      video])

      const handleAcceptedFiles = (files) => {
        // Aceitar apenas um arquivo
        const file = files[0];
        if (file) {
          setSelectedFile({
            ...file,
            preview: URL.createObjectURL(file),
            name: file.name,
            formattedSize: formatBytes(file.size),
            file:file
          });
        }
      };

      const formatBytes = (bytes, decimals = 2) => {
        if (bytes === 0) return "0 Bytes"
        const k = 1024
        const dm = decimals < 0 ? 0 : decimals
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
        const i = Math.floor(Math.log(bytes) / Math.log(k))
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
      }


      useEffect(() => {
        fetch('https://servicodados.ibge.gov.br/api/v1/localidades/estados',)
        .then((res) => res.json())
        .then(response => {
          const states = []
          for(var i = 0; response.length > i; i++){
            states.push({
              value: response[i].sigla,
              label: response[i].nome
            })
          }
          setStatesAddress(states)
        })

        fetch(process.env.REACT_APP_API_URL + '/all-constructions-no-paginate',{
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        })
        .then((res) => res.json())
        .then(response => {
          const constructions = []
          for(var i = 0; response.constructions.length > i; i++){
            constructions.push({
              value: response.constructions[i].id,
              label: response.constructions[i].name
            })
          }
          setConstructionList(constructions)

        })
      }, [])

      useEffect(() => {
        fetch('https://servicodados.ibge.gov.br/api/v1/localidades/estados/' + state.value + '/municipios',)
        .then((res) => res.json())
        .then(response => {
          const cities = []
          for(var i = 0; response.length > i; i++){
            cities.push({
              value: response[i].nome,
              label: response[i].nome
            })
          }
          setCitiesAddress(cities)
        })
      }, [state])

      const consultaCep = cepValue => {
        const cep = cepValue
          if (cep?.length !== 8) {
          setAddress('');
          setDistrict('');
          setState([])
          setCity([]);
          }else{
            fetch('https://viacep.com.br/ws/' + cep + '/json/')
            .then((res) => res.json())
            .then((data) => {
                if (data.erro === true) {
                  setAddress('');
                  setDistrict('');
                  setState([])
                  setCity([]);
                } else {
                  setAddress(data.logradouro);
                  setDistrict(data.bairro);
                  setState({
                    value:data.uf,
                    label:data.uf
                  })
                  setCity({
                    value:data.localidade,
                    label:data.localidade
                  });
                }
              })
          }  
      }
      
      useEffect(() => {
        setName(enterprise?.name)
        setUrlParameter(enterprise?.slug)
        setCep(enterprise?.cep)
        setAddress(enterprise?.address)
        setNumber(enterprise?.number)
        setComplement(enterprise?.complement)
        setDistrict(enterprise?.district)
        setState({value: enterprise?.state, label: enterprise?.state})
        if(enterprise?.city){
          setCity({value: enterprise?.city, label: enterprise?.city})
        }
        setCountry(enterprise?.country)
        setConstruction({value: enterprise?.construction_id, label:enterprise?.construction?.name})
        setDescription(enterprise?.description)
        setType(enterprise?.type)
        setVideo(enterprise?.video)
        setLogo(enterprise?.high_photo)
      },[enterprise])


      // const editorRef = useRef(null);

      // const [cep, setCep] = useState('');
      // const [address, setAddress] = useState('');
      // const [number, setNumber] = useState('');
      // const [complement, setComplement] = useState('');
      // const [district, setDistrict] = useState('');
      // const [state, setState] = useState([]);
      // const [statesAddress, setStatesAddress] = useState([])
      // const [citiesAddress, setCitiesAddress] = useState([])
      // const [country, setCountry] = useState('Brasil');
      // const [city, setCity] = useState([]);
      // const [construction, setConstruction] = useState([]);
      // const [ constructionList, setConstructionList] = useState([]);
      // const [description, setDescription] = useState('');
      // const [type, setType] = useState('');
      // const [selectedFile, setSelectedFile] = useState(null);

  return(
    <div>
      <Form>
      <Row>
              <h5 className="title-imovel">Construtora do empreendimento</h5>
              <Row style={{margin:'30px 0'}}>
                <Col md={4}>
                <Row className="mb-3">
                  <label htmlFor="txtStateProvinceShipping"
                    className="col-lg-3 col-form-label">Construtora</label>
                  <div className="col-lg-9">
                    <Select
                        value={construction}
                        placeholder="Selecione"
                        noOptionsMessage={(inputValue) => `Sem resultados`}
                        onChange={(e) => {
                            setConstruction(e)
                        }}
                        options={constructionList}
                        classNamePrefix="select2-selection"
                      />
                  </div>
                </Row>
                </Col>
              </Row>
            </Row>
        <Row>
              <h5 className="title-imovel">Sobre o imóvel</h5>
              <Row style={{margin:'30px 0'}}>
                <Col md={4}>
                  <Row className="mb-3">
                    <label htmlFor="txtFirstNameShipping"
                      className="col-lg-3 col-form-label">Nome do Imóvel</label>
                    <div className="col-lg-9">
                      <Input 
                        id="txtFirstNameShipping" 
                        name="txtFirstNameShipping" 
                        placeholder="Nome"
                        type="text" 
                        className="form-control" 
                        value={name}
                        onChange={e => {
                          setName(e.target.value)
                          setUrlParameter(e.target.value.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/[^\w\s-]/g, "").replace(/\s+/g, '-').trim())
                        }}
                      />
                    <small>Nome do empreendimento, como será exibido no site.</small>
                    </div>
                  </Row>
                </Col>
                <Col md={4}>
                  <Row className="mb-3">
                    <label htmlFor="txtFirstNameShipping"
                      className="col-lg-4 col-form-label">Sub título</label>
                    <div className="col-lg-8">
                      <Input 
                          id="txtFirstNameShipping" 
                          name="txtFirstNameShipping" 
                          placeholder="Sub título"
                          type="text" 
                          className="form-control" 
                          value={type}
                          onChange={e => {
                            setType(e.target.value)
                          }}
                        />
                      <small>Característica destaque, por exemplo Frente Mar.</small>
                    </div>
                  </Row>
                </Col>
                <Col md={4}>
                  <Row className="mb-3">
                    <label htmlFor="txtCompanyShipping" className="col-lg-3 col-form-label">Url</label>
                    <div className="col-lg-9">
                      <Input 
                        id="txtCompanyShipping" 
                        name="txtCompanyShipping" 
                        placeholder="Url"
                        type="text" 
                        className="form-control" 
                        value={urlParameter}
                        onChange={e => setUrlParameter(e.target.value)}
                      />
                    </div>
                  </Row>
                </Col>
                <Col md={12} style={{margin:'10px 0 50px'}}>
                <label htmlFor="txtCompanyShipping" className="col-form-label">Descrição</label>
                <Editor
                  apiKey='w3485derw88wqxe25q8jpatcv6be8cvrbwkrta6n2f63trk2'
                  onInit={(evt, editor) => editorRef.current = editor}
                  onChange={() => 
                    editorRef.current &&
                      setDescription(editorRef.current.getContent())
                  }
                  initialValue={description}
                  init={{
                    height: 400,
                    menubar: false,
                    plugins: [
                      'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                      'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                      'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
                    ],
                    toolbar: 'blocks | a11ycheck undo redo | bold italic | forecolor backcolor | template codesample | alignleft aligncenter alignright alignjustify | bullist numlist | link image',
                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                  }}
                />
                </Col>
              </Row>
            </Row>
            <Row>
              <h5 className="title-imovel-endereco">Endereço do empreendimento</h5>
              <Row style={{margin:'30px 0'}}>
                <Col md={6}>
                  <Row className="mb-3">
                    <label htmlFor="txtFirstNameShipping"
                      className="col-lg-3 col-form-label">CEP</label>
                    <div className="col-lg-9">
                      <Input
                        id="txtFirstNameShipping" 
                        name="txtFirstNameShipping" 
                        placeholder="CEP"
                        type="tel"
                        pattern="[0-9]*"
                        className="form-control" 
                        value={cep}
                        onChange={e => {
                          setCep(e.target.value.replace(/\D/g, ''))
                          consultaCep(e.target.value.replace(/\D/g, ''))
                        }}
                      />
                    </div>
                  </Row>
                </Col>
                <Col md={6}>
                  <Row className="mb-3">
                    <label htmlFor="txtLastNameShipping"
                      className="col-lg-3 col-form-label">Nome da Rua</label>
                    <div className="col-lg-9">
                      <Input 
                        id="txtLastNameShipping" 
                        name="txtLastNameShipping" 
                        placeholder="Nome da Rua"
                        type="text" 
                        className="form-control" 
                        value={address}
                        onChange={e => setAddress(e.target.value)}
                      />
                    </div>
                  </Row>
                </Col>
              <Col md={6}>
                <Row className="mb-3">
                  <label htmlFor="txtCompanyShipping" className="col-lg-3 col-form-label">Nº</label>
                  <div className="col-lg-9">
                    <Input 
                      id="txtCompanyShipping" 
                      name="txtCompanyShipping" 
                      placeholder="Número"
                      type="number" 
                      className="form-control" 
                      value={number}
                      onChange={e => setNumber(e.target.value)}
                    />
                  </div>
                </Row>
              </Col>
              <Col md={6}>
                <Row className="mb-3">
                  <label htmlFor="txtEmailAddressShipping"
                    className="col-lg-3 col-form-label">Complemento</label>
                  <div className="col-lg-9">
                    <Input 
                      id="txtEmailAddressShipping" 
                      name="txtEmailAddressShipping" 
                      type="text" 
                      className="form-control" 
                      placeholder="Complemento" 
                      value={complement}
                      onChange={e => setComplement(e.target.value)}
                    />
                  </div>
                </Row>
              </Col>
              <Col md={6}>
                <Row className="mb-3">
                  <label htmlFor="txtCityShipping" className="col-lg-3 col-form-label">Bairro</label>
                  <div className="col-lg-9">
                    <Input 
                      id="txtCityShipping" 
                      name="txtCityShipping" 
                      type="text" 
                      placeholder="Bairro"
                      className="form-control" 
                      value={district}
                      onChange={e => setDistrict(e.target.value)}
                    />
                  </div>
                </Row>
              </Col>
              <Col md={6}>
                <Row className="mb-3">
                  <label htmlFor="txtStateProvinceShipping"
                    className="col-lg-3 col-form-label">Cidade</label>
                  <div className="col-lg-9">
                    <Select
                        value={city}
                        placeholder="Selecione"
                        noOptionsMessage={(inputValue) => `Sem resultados`}
                        onChange={(e) => {
                            setCity(e)
                        }}
                        options={citiesAddress}
                        classNamePrefix="select2-selection"
                      />
                  </div>
                </Row>
              </Col>
              <Col md={6}>
                <Row className="mb-3">
                  <label htmlFor="txtStateProvinceShipping"
                    className="col-lg-3 col-form-label">Estado</label>
                  <div className="col-lg-9">
                    <Select
                        value={state}
                        placeholder="Selecione"
                        noOptionsMessage={(inputValue) => `Sem resultados`}
                        onChange={(e) => {
                            setState(e)
                        }}
                        options={statesAddress}
                        classNamePrefix="select2-selection"
                      />
                  </div>
                </Row>
              </Col>
              <Col md={6}>
                <Row className="mb-3">
                  <label htmlFor="txtStateProvinceShipping"
                    className="col-lg-3 col-form-label">País</label>
                  <div className="col-lg-9">
                    <Input 
                      id="txtStateProvinceShipping"
                      name="txtStateProvinceShipping" 
                      type="text" 
                      placeholder="País"
                      className="form-control" 
                      value={country}
                      onChange={e => setCountry(e.target.value)}
                    />
                  </div>
                </Row>
              </Col>
            </Row>
        </Row>
        <Row>
              <h5 className="title-imovel-endereco">Imagem destaque (plano de fundo)</h5>
              <Row style={{margin:'30px 0'}}>
              <Col md={12}>
                  {logo &&
                    <div style={{
                      backgroundImage:'url(' + logo + ')',
                      backgroungPosition:'center center',
                      backgroundSize:'cover',
                      width:'200px',
                      height:'120px',
                      borderRadius:'5px',
                      marginBottom:'15px'
                    }}></div>
                  }
                  {logo &&
                    <Button style={{marginBottom:'15px'}} onClick={(e) => {
                      e.preventDefault()
                      fetch(process.env.REACT_APP_API_URL + '/remove-high-photo/' + enterprise.id,{
                        headers: {
                          Authorization: `Bearer ${localStorage.getItem('token')}`,
                        },
                      })
                      .then((res) => window.location.reload())
                    }}>Remover</Button>
                  }
                </Col>
              <Col md={6}>
                  <Dropzone
                  accept=".png, .jpeg, .jpg, .heic"
                  onDrop={acceptedFiles => handleAcceptedFiles(acceptedFiles)}
                  // Limitar a 1 arquivo
                  maxFiles={1}
                >
                  {({ getRootProps, getInputProps }) => (
                    <div className="dropzone" {...getRootProps()}>
                      <div className="dz-message needsclick" {...getRootProps()}>
                        <input {...getInputProps()} />
                        <div className="mb-3">
                          <i className="display-4 text-muted ri-upload-cloud-2-line"></i>
                        </div>
                        <h4>Para adicionar uma imagem, arraste ou clique aqui.</h4>
                      </div>
                    </div>
                  )}
                </Dropzone>
              </Col>
              <Col md={6}>
                <div className="dropzone-previews mt-3" id="file-previews">
                  {selectedFile && (
                    <div className="p-2">
                      <Row className="align-items-center">
                        <Col className="col-auto">
                          <img
                            data-dz-thumbnail=""
                            height="220"
                            className="avatar-xl rounded bg-light image-send"
                            style={{ objectFit: "cover" }}
                            src={selectedFile.preview}
                          />
                        </Col>
                        <Col>
                          <Link to="#" className="text-muted fw-bold">
                            {selectedFile.name}
                          </Link>
                          <p className="mb-0">
                            <strong>{selectedFile.formattedSize}</strong>
                          </p>
                        </Col>
                      </Row>
                    </div>
                  )}
                </div>
              </Col>
              <Col md={6} style={{marginTop:'30px'}}>
                <Row className="mb-3">
                  <label htmlFor="txtNameCard" className="col-lg-3 col-form-label">Iframe do Vídeo</label>
                  <div className="col-lg-9">
                    <Input 
                      id="txtNameCard" 
                      name="txtNameCard" 
                      type="text" 
                      placeholder="<iframe....."
                      className="form-control" 
                      value={video}
                      onChange={(e) => setVideo(e.target.value)}
                    />
                  </div>
                </Row>
              </Col>
              </Row>
            </Row>
      </Form>
    </div>
  )
}

export default Step2;