import { useState, useEffect } from 'react';
import './style.scss';
import {
  Col,
  Form,
  Label,
  Button,
  Input,
  Row
} from "reactstrap";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const id = window.location.search.replace('?', '')

const Step3 = ({ handleChildStep3, property }) => {

  const [zone, setZone] = useState('');
  const [zoneFull, setZoneFull] = useState('');
  const [rooms, setRooms] = useState('');
  const [demiSuite, setDemiSuite] = useState('');
  const [suites, setSuites] = useState('');
  const [bathrooms, setBathrooms] = useState('');
  const [garage, setGarage] = useState('');

  const [valueImobile, setValueImobile] = useState('');
  const [detailsImobile, setDetailsImobile] = useState([]);

  const dataToSend = {
    zone,
    zoneFull,
    rooms,
    demiSuite,
    suites,
    bathrooms,
    garage,
    detailsImobile
  };

  useEffect(() => {
    handleChildStep3(dataToSend);
  }, [zone,
    zoneFull,
    rooms,
    demiSuite,
    suites,
    bathrooms,
    detailsImobile,
    garage])

    /* IMÓVEL */
  const handleChangeImobile = (event) => {
    setValueImobile(event.target.value);
  };

  const loadDetailsImobile = () => {
    setDetailsImobile([])
    if (valueImobile.trim() !== '') {
      const lines = valueImobile.split('\n');

      const details = new FormData()
        for(let i = 0; i < lines.length; i++){
          details.append(`detail[${i}][detail]`, lines[i])
          details.append(`detail[${i}][position]`, 0)
          details.append(`detail[${i}][property_id]`, id)
        }
        setTimeout(() => {
          fetch(process.env.REACT_APP_API_URL + '/create-property-detail', {
            method: 'POST',
            body: details,
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          });

          setTimeout(() => {
            fetch(process.env.REACT_APP_API_URL + '/new-property-id/' + id, {
              method: 'GET',
              headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
              },
            })
            .then(response => response.json())
            .then(async resp => {
              setDetailsImobile(resp?.property?.details)
            })
          }, 300);

        }, 300);
  
      setValueImobile('');
    }
  };

  const removeDetailImobile = (id) => { 
    fetch(process.env.REACT_APP_API_URL + '/delete-property-detail/' + id, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    }).then(response => {
      setDetailsImobile(detailsImobile.filter(detail => detail.id !== id));
    })
  };

  const onDragEndImobile = (result) => {
    if (!result.destination) return;
  
    const updatedDetails = reorder(
      detailsImobile,
      result.source.index,
      result.destination.index
    ).map((item, index) => ({
      ...item,
      position: (index + 1).toString(),
    }));
  
    setDetailsImobile(updatedDetails);
  };


  useEffect(() => {
    setZone(property?.zone)
    setZoneFull(property?.zone_full)
    setRooms(property?.rooms)
    setDemiSuite(property?.demi_suite)
    setSuites(property?.suites)
    setBathrooms(property?.bathrooms)
    setGarage(property?.garage)
    setDetailsImobile(property?.details)
  },[property])


  return(
    <div>
        <Form>
          <Row>
            <h5 className="title-description">Características principais</h5>
            <Row>
              <Col md={6}>
                <Row className="mb-3">
                  <label htmlFor="txtStateProvinceShipping"
                    className="col-lg-3 col-form-label">Metragem Útil</label>
                  <div className="col-lg-9">
                    <Input 
                      id="txtStateProvinceShipping"
                      name="txtStateProvinceShipping" 
                      type="number" 
                      placeholder="Metragem Útil"
                      className="form-control" 
                      value={zone}
                      onChange={e => setZone(e.target.value)}
                    />
                  </div>
                </Row>
              </Col>
              <Col md={6}>
                <Row className="mb-3">
                  <label htmlFor="txtCityShipping" className="col-lg-3 col-form-label">Metragem Total</label>
                  <div className="col-lg-9">
                    <Input 
                      id="txtCityShipping" 
                      name="txtCityShipping" 
                      type="number" 
                      placeholder="Metragem Total"
                      className="form-control" 
                      value={zoneFull}
                      onChange={e => setZoneFull(e.target.value)}
                    />
                  </div>
                </Row>
              </Col>

              {(property?.type !== 'land_shed' && property?.type !== 'terrain' && property?.type !== 'terrain_land_shed') &&
              <>
              <Col md={6}>
                <Row className="mb-3">
                  <label htmlFor="txtStateProvinceShipping"
                    className="col-lg-3 col-form-label">Nº de Quartos</label>
                  <div className="col-lg-9">
                    <Input 
                      id="txtStateProvinceShipping"
                      name="txtStateProvinceShipping" 
                      type="number" 
                      placeholder="Nº de Quartos"
                      className="form-control" 
                      value={rooms}
                      onChange={e => setRooms(e.target.value)}
                    />
                  </div>
                </Row>
              </Col>
              <Col md={6}>
                <Row className="mb-3">
                  <label htmlFor="txtCityShipping" className="col-lg-3 col-form-label">Nº de Demi-Suítes</label>
                  <div className="col-lg-9">
                    <Input 
                      id="txtCityShipping" 
                      name="txtCityShipping" 
                      type="number" 
                      placeholder="Nº de Demi-Suítes"
                      className="form-control"
                      value={demiSuite}
                      onChange={e => setDemiSuite(e.target.value)}
                    />
                  </div>
                </Row>
              </Col>
              <Col md={6}>
                <Row className="mb-3">
                  <label htmlFor="txtStateProvinceShipping"
                    className="col-lg-3 col-form-label">Nº de Suítes</label>
                  <div className="col-lg-9">
                    <Input 
                      id="txtStateProvinceShipping"
                      name="txtStateProvinceShipping" 
                      type="number" 
                      placeholder="Nº de Suítes"
                      className="form-control" 
                      value={suites}
                      onChange={e => setSuites(e.target.value)}
                    />
                  </div>
                </Row>
              </Col>
              <Col md={6}>
                <Row className="mb-3">
                  <label htmlFor="txtCityShipping" className="col-lg-3 col-form-label">Nº de Banheiros</label>
                  <div className="col-lg-9">
                    <Input 
                      id="txtCityShipping" 
                      name="txtCityShipping" 
                      type="number" 
                      placeholder="Nº de Banheiros"
                      className="form-control" 
                      value={bathrooms}
                      onChange={e => setBathrooms(e.target.value)}
                    />
                  </div>
                </Row>
              </Col>
              <Col md={6}>
                <Row className="mb-3">
                  <label htmlFor="txtStateProvinceShipping"
                    className="col-lg-3 col-form-label">Vagas de Garagem</label>
                  <div className="col-lg-9">
                    <Input 
                      id="txtStateProvinceShipping"
                      name="txtStateProvinceShipping" 
                      type="number" 
                      placeholder="Vagas de Garagem"
                      className="form-control" 
                      value={garage}
                      onChange={e => setGarage(e.target.value)}
                    />
                  </div>
                </Row>
              </Col>
              </>
              }
            </Row>
          </Row>


          <div style={{margin: '1rem auto 3rem auto' }}>
        <h5>Outras características</h5>
        <Form>
          <Row>
            <Col md={6}>
              <Row className="mb-3">
                <Label htmlFor="txtNameCard" className="col-lg-4 col-form-label title">Característica do Imóvel</Label>
                <div className="col-lg-12">
                  <Input 
                    id="txtNameCard" 
                    name="txtNameCard" 
                    type="textarea" 
                    rows={6}
                    placeholder="Descrição"
                    className="form-control"
                    value={valueImobile}
                    onChange={handleChangeImobile}
                  />
                </div>
                <div className='add-button'>
                  <Button onClick={loadDetailsImobile}>Adicionar</Button>
                </div>
              </Row>
            </Col>
            <Col md={6} style={{marginTop:'40px'}}>
            <DragDropContext onDragEnd={onDragEndImobile}>
              <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    className='container-drag'
                    style={{ backgroundColor: snapshot.isDraggingOver ? 'lightblue' : 'lightgrey' }}
                  >
                    {detailsImobile?.slice().sort((a, b) => a.position - b.position).map((item, index) => (
                      <Draggable key={item.id} draggableId={item.id.toString()} index={index}>
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className='content-drag'
                            style={{
                              userSelect: 'none',
                              padding: '16px',
                              margin: '4px 0 4px 0',
                              minHeight: '50px',
                              backgroundColor: snapshot.isDragging ? '#3E5E3F' : '#648465',
                              color: 'white',
                              ...provided.draggableProps.style 
                            }}
                          >
                            {item.detail}
                            <Button color="danger" size="sm" style={{float: 'right'}} onClick={() => removeDetailImobile(item.id)}>Excluir</Button>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            </Col>
          </Row>
        </Form>
      </div>
        </Form>
    </div>
  )
}

export default Step3