import React, { useState, useEffect, ChangeEvent } from 'react';

const formatValue = (value) => {
    const formattedValue = (value / 100).toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
      minimumFractionDigits: 2,
    });
    return formattedValue;
  };


const MoneyInput = ({ defaultValue, onChange }) => {
  const [value, setValue] = useState(formatValue(defaultValue));

  useEffect(() => {
    setValue(formatValue(defaultValue));
  }, [defaultValue]);

  const handleChange = (event) => {
    const { value } = event.target;
    const valueWithoutFormatting = value
      .replace(/[^\d]/g, '') // Remove caracteres não numéricos
      .replace(/^0+/g, ''); // Remove zeros à esquerda

    const numericValue = parseInt(valueWithoutFormatting, 10) || 0;
    setValue(formatValue(numericValue));
    onChange(numericValue);
  };

  return (
    <input
      type="text"
      inputMode="numeric"
      placeholder="R$ 0"
      className="form-control"
      value={value}
      onChange={handleChange}
    />
  );
};

export default MoneyInput;