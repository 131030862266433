import PropTypes from 'prop-types'
import React, { useEffect, useState } from "react"

import { Row, Col, Alert, Container } from "reactstrap"

// Redux
import { connect } from "react-redux"
import { withRouter, Link } from "react-router-dom"

// actions
import { loginUser, apiError, socialLogin } from "../../store/actions"

// import images
import logo from "../../assets/images/logosort.png"

const Login = (props) => {
  useEffect(() => {
    document.body.className = "authentication-bg";
    return function cleanup() {
      document.body.className = "";
    };
  });

  // handleValidSubmit
  const handleValidSubmit = async (e) => {
    e.preventDefault()
    try {
      const response = await fetch(process.env.REACT_APP_API_URL + '/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email: login, password: senha })
      });
      
      if (!response.ok) {
        throw new Error('Erro ao efetuar login');
      }
  
      const data = await response.json();
  
      localStorage.setItem('token', data.token);
      localStorage.setItem('name', data.name);
      localStorage.setItem('permission', data.permission);
      localStorage.setItem('id', data.id);
      localStorage.setItem('profile_image', data.profile_image);
  
      setTimeout(() => {
        window.location.href = '/dashboard';
      }, 300);
    } catch (error) {
      console.log('O login falhou:', error);
    }
  };

  const [login, setLogin] = useState('')
  const [senha, setSenha] = useState('')

  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <div className="card overflow-hidden">
                <div className="bg-login text-center">
                  <div className="bg-login-overlay"></div>
                  <div className="position-relative">
                    <h5 className="text-white font-size-20">Sort Investimentos</h5>
                    <p className="text-white-50 mb-0">Painel administrativo.</p>
                    <Link to="/" className="logo logo-admin mt-4">
                      <img src={logo} alt="" height="20" />
                    </Link>
                  </div>
                </div>
                <div className="card-body pt-5">
                  <div className="p-2">
                    <form
                      className="form-horizontal"
                      onSubmit={(e) => handleValidSubmit(e)}
                    >
                      {props.error && typeof props.error === "string" ? (
                        <Alert color="danger">{props.error}</Alert>
                      ) : null}

                      <div className="mb-3">
                        <input
                          name="email"
                          label="Email"
                          value={login}
                          onChange={(e) => setLogin(e.target.value)}
                          className="form-control"
                          placeholder="E-mail"
                          type="email"
                          required
                        />
                      </div>

                      <div className="mb-3">
                        <input
                          name="password"
                          label="Senha"
                          value={senha}
                          onChange={(e) => setSenha(e.target.value)}
                          type="password"
                          required
                          className="form-control"
                          placeholder="Senha"
                        />
                      </div>

                      <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="customControlInline"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="customControlInline"
                        >
                          Lembrar
                        </label>
                      </div>

                      <div className="mt-3">
                        <button
                          className="btn btn-primary w-100 waves-effect waves-light"
                          type="submit"
                        >
                          Entrar
                        </button>
                      </div>

                      <div className="mt-4 text-center">
                        <Link to="/forgot-password" className="text-muted"><i
                          className="mdi mdi-lock me-1"></i> Esqueceu a senha?</Link>
                      </div>
                    </form>

                  </div>
                </div>
              </div>

            </Col>
          </Row>

        </Container>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  const { error } = state.Login
  return { error }
}

export default withRouter(
  connect(mapStateToProps, { loginUser, apiError, socialLogin })(Login)
)

Login.propTypes = {
  error: PropTypes.any,
  history: PropTypes.object,
  loginUser: PropTypes.func,
  socialLogin: PropTypes.func
}