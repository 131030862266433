import React, { useState, useRef, useEffect } from "react"
import {
  Button,
    Card,
    CardBody,
    Col,
    Input,
    NavItem,
    NavLink,
    Row,
    Spinner,
    TabContent,
    TabPane
  } from "reactstrap"
import Breadcrumbs from "../../../../components/Common/Breadcrumb";
import styled from 'styled-components';
import classnames from "classnames"
import { Link } from "react-router-dom"
import Step1 from "./Step1"
import Step5 from "./Step5"
import Axios from "axios"

const id = window.location.search.replace('?', '')

const EditPost = () => {
    const [activeTab, setactiveTab] = useState(1);
    const [childDataStep1, setChildDataStep1] = useState({});
    const [childDataStep5, setChildDataStep5] = useState({});
    const [typeRent, setTypeRent] = useState([]);
    const [sendProp, setSendPro] = useState([])
    const [status, setStatus] = useState(0)
    const [post, setPost] = useState([])
  
  
    function toggleTab(tab) {
      if (activeTab !== tab) {
        if (tab >= 1 && tab <= 2) {
          setactiveTab(tab)
        }
      }
    }
  
    const handleChildStep1 = (receivedChildData) => {
      setChildDataStep1(receivedChildData);
    };
  
    const handleChildStep5 = (receivedChildData) => {
      setChildDataStep5(receivedChildData);
    };
  
    const handleSubmit = (event) => {
      event.preventDefault();
      document.getElementById('load').classList.add('active');

      const data = new FormData();
      data.append('category', childDataStep1.category)
      data.append('status', status)
      data.append('content', childDataStep1.content)
      data.append('sub_title', childDataStep1.subtitle)
      data.append('title', childDataStep1.title)
      data.append('url', childDataStep1.url)
      if(childDataStep1.media?.file){data.append('media', childDataStep1.media?.file)}

      fetch(process.env.REACT_APP_API_URL + '/update-post/' + id, {
        method: 'POST',
        body: data,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      .then(response => response.json())
      .then(async resp => {
        setTimeout(() => {
          window.open('/editar-post', '_parent')
        }, 300);
        
      })
        .catch(error => {
          console.error(error);
        });    
    }

    useEffect(() => {
      fetch(process.env.REACT_APP_API_URL + '/post/' + id, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        })
        .then(response => response.json())
        .then(async resp => {
          setPost(resp.post)
          setStatus(resp.post.status)
        })
    },[id])

    return(
    <React.Fragment>
        <div className="page-content">
            <Breadcrumbs title="Clog" breadcrumbItem="Novo post" />
            <Row>
            <Col lg="12">
                <Card>
                <CardBody>
                    <h4 className="card-title">Adicionar post</h4>
                    <div className="form-wizard-wrapper wizard clearfix">
                        <div className="steps clearfix">
                            <ul>
                            <NavItem
                                className={classnames({ current: activeTab === 1 })}>
                                <NavLink
                                className={classnames({ current: activeTab === 1 })}
                                onClick={() => {
                                    setactiveTab(1)
                                }}
                                >
                                <span className="number">1.</span>{" "}
                                    Sobre o post
                                </NavLink>
                            </NavItem>
                            <NavItem className={classnames({ current: activeTab === 2 })}>
                                <NavLink
                                className={classnames({ active: activeTab === 2 })}
                                onClick={() => {
                                    setactiveTab(2)
                                }}
                                >
                                <span className="number">2.</span>
                                Confirmar atualização
                                </NavLink>
                            </NavItem>
                            </ul>
                        </div>
                        <div className="content clearfix">
                            <TabContent
                            activeTab={activeTab}
                            className="body"
                            >
                            <TabPane tabId={1}>
                                <Step1 post={post} handleChildStep1={handleChildStep1}/>
                            </TabPane>

                            <TabPane tabId={2}>
                                <div className="row justify-content-center">
                                <Col lg="6">
                                    <div className="text-center">
                                    <div className="mb-4">

                                        <i className="mdi mdi-check-circle-outline text-success display-4" />
                                    </div>
                                    <Row style={{justifyContent:'center', margin:'20px 0'}}>
                                        <Col md={12}>
                                          <Row className="mb-3">
                                            <label htmlFor="txtAddress1Billing"
                                              className="col-lg-4 col-form-label">Status do imóvel</label>
                                            <div className="col-lg-7">
                                              <Input 
                                                type="select" 
                                                id="ddlCreditCardType" 
                                                name="ddlCreditCardType"
                                                className="form-select"
                                                value={status}
                                                onChange={e => setStatus(e.target.value)}
                                              >
                                                <option value="">Selecione o Status</option>
                                                <option value={0}>Inativo</option>
                                                <option value={1}>Ativo</option>
                                              </Input>
                                            </div>
                                          </Row>
                                        </Col>
                                      </Row>
                                    <div>
                                        <h5>Tudo pronto, atualizar cadastro.</h5>
                                        <p className="text-muted">
                                        Você pode voltar e conferir tudo antes se quiser.
                                        </p>
                                        <Button onClick={handleSubmit}>Enviar</Button>
                                    </div>
                                    </div>


                                </Col>
                                </div>
                            </TabPane>
                            </TabContent>
                        </div>
                        <div className="actions clearfix">
                            <ul>
                            <li
                                className={
                                activeTab === 1 ? "previous disabled" : "previous"
                                }
                            >
                                <Link
                                to="#"
                                className="btn btn-primary"
                                onClick={() => {
                                    toggleTab(activeTab - 1)
                                }}
                                >
                                Anterior
                                </Link>
                            </li>
                            <li
                                className={activeTab === 2 ? "next disabled" : "next"}
                            >
                                <Link
                                to="#"
                                className="btn btn-primary"
                                onClick={() => {
                                    toggleTab(activeTab + 1)
                                }}
                                >
                                Próximo
                                </Link>
                            </li>
                            </ul>
                        </div>
                        </div>
                </CardBody>
                </Card>
            </Col>
            </Row>

        </div>

        <Load id="load">
          <Spinner className="me-2" color="success" />
        </Load>
    </React.Fragment>
    )
}

export default EditPost;

const Load = styled.div`
  display:none;

  &.active{
    display:flex;
    position: fixed;
    top:0;
    bottom:0;
    left:0;
    right:0;
    background-color: rgba(0,0,0,0.5);
    align-items: center;
    justify-content: center;
    z-index:99999999999999;
  }
`;