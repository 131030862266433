import React, { useState, useEffect } from "react"
import { Card, Row, Col, Form, Button, Input } from "reactstrap"
import Dropzone from "react-dropzone"
import './style.scss'
import { ImageDragCard } from "./imageCard";
import { ImageDragCardAtual } from "./imageCardAtual";


const Gallery = ({ handleChildStep5, send, title, url, gallery }) => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [photosArray, setPhotosArray] = useState([]);
  const [show, setShow] = useState(false);
  const [atualPhotos, setAtualPhotos] = useState([]);
  const [editShow, setEditShow] = useState(false);

  const handleAcceptedFiles = (files) => {
    files = files.map((file, index) => ({
      ...file,
      preview: URL.createObjectURL(file),
      formattedSize: formatBytes(file.size),
      file: file,
      title: title,
      url: url,
      position: index
    }));

    setSelectedFiles(files);
    setPhotosArray(files);
  };

  const dataToSend = {
    selectedFiles
  };

  useEffect(() => {
    handleChildStep5(dataToSend);
    setShow(selectedFiles.length > 0)
    setSelectedFiles(photosArray)
  }, [selectedFiles, photosArray])

  useEffect(() => {
    handleChildStep5(dataToSend);
  }, [atualPhotos])

  const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  const handlePositionChange = (index, newPosition) => {
    setPhotosArray((prevPhotosArray) => {
      const updatedPhotosArray = [...prevPhotosArray];
      updatedPhotosArray[index].position = newPosition;
      return updatedPhotosArray;
    });
  };

  const handleRemoveImage = (path) => {
    const indexToRemove = photosArray.findIndex(image => image.path === path);

    if (indexToRemove !== -1) {
      const updatedImages = [...photosArray];
      updatedImages.splice(indexToRemove, 1);
      setSelectedFiles(updatedImages);
      setPhotosArray(updatedImages);
    }
  };

  const handleAtualPositionChange = (index, newPosition) => {
    setAtualPhotos((prevPhotosArray) => {
      const updatedPhotosArray = [...prevPhotosArray];
      updatedPhotosArray[index].position = newPosition;
      return updatedPhotosArray;
    });
  };

  useEffect(() => {
    setAtualPhotos(gallery.photos?.sort((a, b) => a.position - b.position))
    setTimeout(() => {
      setEditShow(true)
    }, 300);
  },[gallery])

  const handleRemoveImageAtual = (id) => {
    setAtualPhotos([])
     fetch(process.env.REACT_APP_API_URL + '/delete-gallery-photo/' + id, {
       method: 'GET',
       headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
     })
     .then(response => setAtualPhotos(gallery.photos?.filter(photo => photo.id !== id)))
    
  };

  return (
    <div>
        <Form>
          <Dropzone
            accept=".png, .jpeg, .jpg, .heic"
            onDrop={acceptedFiles => handleAcceptedFiles(acceptedFiles)}
          >
            {({ getRootProps, getInputProps }) => (
              <div className="dropzone" {...getRootProps()}>
                <div
                  className="dz-message needsclick"
                  {...getRootProps()}
                >
                  <input {...getInputProps()} />
                  <div className="mb-3">
                    <i className="display-4 text-muted ri-upload-cloud-2-line"></i>
                  </div>
                  <h4>Para adicionar fotos arraste ou clique aqui.</h4>
                </div>
              </div>
            )}
          </Dropzone>
              <div
                className="dropzone-previews mt-3"
                id="file-previews"
              >
                {photosArray.map((image, index) => (
                  <ImageDragCard
                    key={index}
                    image={image}
                    index={index}
                    onPositionChange={handlePositionChange}
                    onRemove={() => handleRemoveImage(image.path)}
                  />
                ))}
          </div>
        </Form>
        <div style={{widht:'100%', height:'1px', backgroundColor:"#cdcdcd", margin:'20px 0'}}></div>
        <h3 style={{fontSize:'16px'}}>Imagens adicionadas</h3>
        <div
          className="dropzone-previews mt-3"
          id="file-previews2"
        >
            {atualPhotos?.length > 0 ? (
              atualPhotos?.map((image, index) => (
              <ImageDragCardAtual
                key={index}
                image={image}
                index={index}
                onPositionChange={handleAtualPositionChange}
                onRemove={() => handleRemoveImageAtual(image.id)}
              />
            ))):(
              <p>Ainda não tem imagens nesta galeria.</p>
            )}
        </div>
    </div>
  )
}

export default Gallery;