import React from "react";
import {
    Button,
  Card,
  CardBody,
  Col,
  Input,
  Row,
  Spinner,
} from "reactstrap";
import Breadcrumbs from "../../../../components/Common/Breadcrumb";
import styled from 'styled-components';
import {BsBuildingAdd, BsBuildingGear, BsBuilding, BsHouseDoor, BsBoundingBoxCircles, BsBoundingBox, BsBoxSeam} from 'react-icons/bs';
import { useState } from "react";
import { useEffect } from "react";
import ImmobileCard from "./immobileCard";
import Pagination from '@mui/material/Pagination';


const ImmobileIntegration = () => {

    const [ constructionList, setConstructionList ] = useState([]);
    const [ search, setSearch ] = useState('');
    const [ page, setPage ] = useState(1);
    const [ totalPages, setTotalPages] = useState(1)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        fetchData();
    }, [])


      const fetchData = async () => {
        setLoading(true)
        try {
            const response = await fetch('https://dev.fastsaleimoveis.com.br/api/property?page=' + page + '&search=' + search, {
                headers: {
                    'X-Custom-Token': process.env.REACT_APP_SORT_INTEGRATION_TOKEN,
                  },
            })
            .then((res) => res.json())
            .then(response => {
                console.log(response)
              setConstructionList(response.data)
              setLoading(false)
              setTotalPages(response.last_page)
            })
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        window.scrollTo(0,0)
        setPage(value);
        fetchData();
      };

      const submitSearch = () => {
        fetchData();
      }

      const handleClearFilter = () => {
        window.location.reload()
      }

    return(
    <React.Fragment>
        <div className="page-content">
            <Breadcrumbs title="Gestão de Imóveis" breadcrumbItem="Imóveis" />
            <Row>
            <Col xs={12}>
                <Card>
                <CardBody>
                    <h4 className="card-title">Imóveis</h4>
                    <Row>
                        <Col md={8}>
                            <Row className="mb-3">
                                <div className="col-lg-10">
                                    <Input 
                                        id="txtFirstNameBilling" 
                                        name="txtFirstNameBilling" 
                                        placeholder="Busque por nome ou código"
                                        type="text" 
                                        className="form-control" 
                                        value={search}
                                        onChange={e => setSearch(e.target.value)}
                                    />
                                </div>
                                <div className="col-lg-2">
                                    <Button onClick={submitSearch}>Buscar</Button>
                                </div>
                            </Row>
                        </Col>
                    </Row>
                    <OptionsGrid>
                        {constructionList?.map((construction, index) => (
                        // .filter(types => type !== '' ? types.type === type : types)
                        // .filter(constructions => constructions?.name?.toLowerCase()?.includes(search?.toLowerCase()) || constructions?.id?.toString().toLowerCase().includes(search?.toLowerCase()) || constructions?.city?.toLowerCase().includes(search?.toLowerCase()))

                            <ImmobileCard key={index} data={construction}/>
                        ))}
                    </OptionsGrid>
                </CardBody>
                </Card>
            </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <Pagination count={totalPages} page={page} onChange={handleChange}/>
                </Col>
            </Row>
        </div>

        {loading &&
            <Loading>
                <Spinner></Spinner>
            </Loading>
        }

    </React.Fragment>
    )
}

export default ImmobileIntegration;

const Loading = styled.div`
    width:100%;
    height:100%;
    position:fixed;
    bottom:0;
    top:0;
    left:0;
    right:0;
    background-color:rgba(0,0,0,0.6);
    display:flex;
    align-items:center;
    justify-content:center;
    z-index:99999;
`;

const ClearFilter = styled.span`
    cursor:pointer;
    margin-top:8px;
`;

const OptionsGrid = styled.div`
    display:grid;
    margin:30px 0;
    padding:20px;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap:20px;
`;