import {
  Button,
    Card,
    CardBody,
    Col,
    Input,
    Label,
    Row,
    Spinner,
  } from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb";
import styled from 'styled-components';
import React, { useEffect, useState } from "react";
import Dropzone from "react-dropzone";
import Select from "react-select";


const PageGalpoes = () => {

  const [aboutPage, setAboutPage] = useState([])

  const [hightPosts1, setHightPosts1] = useState([])
  const [hightPosts2, setHightPosts2] = useState([])
  const [blogPosts, setBlogPosts] = useState([])
  const [blogPostsSelected, setBlogPostsSelected] = useState([])
  const [atualBlog, setAtualBlog] = useState('')
  const [title, setTitle] = useState('')
  const [button, setButton] = useState('')
  const [background, setBackground] = useState('')
  const [selectedBGFile, setSelectedBGFile] = useState(null);
  const [sendProp, setSendPro] = useState([])


  useEffect(() => {
    fetch(process.env.REACT_APP_API_URL + '/page-land/1')
    .then(response => response.json())
    .then(async resp => {
      setAboutPage(resp);
      setTitle(resp.title)
      setAtualBlog(resp.high_posts)
      setButton(resp.button)
      setBackground(resp.background)
    })

    fetch(process.env.REACT_APP_API_URL + '/posts-no-paginate')
    .then(response => response.json())
    .then(async resp => {
      const newPosts = []
      for(var i = 0; resp.posts.length > i; i++){
        newPosts.push({
          label:resp.posts[i].title,
          value:resp.posts[i].id
        })
      }
      setBlogPosts(newPosts)
    })

}, [])


const handleAcceptedBGFiles = (files) => {
  // Aceitar apenas um arquivo
  const file = files[0];
  if (file) {
    setSelectedBGFile({
      ...file,
      preview: URL.createObjectURL(file),
      name: file.name,
      formattedSize: formatBytes(file.size),
      file:file
    });
  }
};

const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return "0 Bytes"
  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
  const i = Math.floor(Math.log(bytes) / Math.log(k))
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
}

const updatePage = () => {
    document.getElementById('load').classList.add('active')

    const blogPostsSelectedString = blogPostsSelected.join(',');

    const data = new FormData()

    data.append('high_posts', blogPostsSelectedString !== '' ? blogPostsSelectedString : atualBlog)
    data.append('title', title)
    data.append('button', button)
    data.append('background', selectedBGFile?.file)

    fetch(process.env.REACT_APP_API_URL + '/update-page-land/1', {
      method: 'POST',
      body: data,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
    .then(response => response.json())
    .then(async resp => {
      document.getElementById('load').classList.remove('active')
    })
    .catch(error => {
      console.error(error);
      document.getElementById('load').classList.remove('active')
    });  
}



    return(
    <React.Fragment>
        <div className="page-content">
            <Breadcrumbs title="Páginas" breadcrumbItem="Sobre a Sort" />
            <Row>
            <Col lg="12" md="12">
                <Card>
                <CardBody>
                <Row>
                  <Col md={6}>
                    <InputContainer>
                      <Label>Título</Label>
                      <Input
                        value={title}
                        type="text"
                        onChange={(e) => setTitle(e.target.value)}
                      ></Input>
                    </InputContainer>
                  </Col>
                  <Col md={6}>
                    <InputContainer>
                      <Label>Botão</Label>
                      <Input
                        value={button}
                        type="text"
                        onChange={(e) => setButton(e.target.value)}
                      ></Input>
                    </InputContainer>
                  </Col>
                </Row>

                <Divider></Divider>

                <InputContainer>
                    <Label>Destaques Blog</Label>
                    <Row>
                      <Col md={6}>
                        <Select
                          value={hightPosts1}
                          placeholder="Selecione"
                          noOptionsMessage={(inputValue) => `Sem resultados`}
                          onChange={(e) => {
                            blogPostsSelected.push(e.value)
                            setHightPosts1(e)
                          }}
                          options={blogPosts}
                          classNamePrefix="select2-selection"
                        />
                      </Col>
                      <Col md={6}>
                        <Select
                          value={hightPosts2}
                          placeholder="Selecione"
                          noOptionsMessage={(inputValue) => `Sem resultados`}
                          onChange={(e) => {
                            blogPostsSelected.push(e.value)
                            setHightPosts2(e)
                          }}
                          options={blogPosts}
                          classNamePrefix="select2-selection"
                        />
                      </Col>
                    </Row>
                  </InputContainer>

                  <Divider></Divider>

                  <InputContainer>
                  <Label>Plano de fundo</Label>
                  <Row style={{margin:'30px 0'}}>
                    <Col md={6}>
                      <Dropzone
                        accept=".png, .jpeg, .jpg, .heic"
                        onDrop={acceptedFiles => handleAcceptedBGFiles(acceptedFiles)}
                        // Limitar a 1 arquivo
                        maxFiles={1}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div className="dropzone" {...getRootProps()}>
                            <div className="dz-message needsclick" {...getRootProps()}>
                              <input {...getInputProps()} />
                              <div className="mb-3">
                                <i className="display-4 text-muted ri-upload-cloud-2-line"></i>
                              </div>
                              <h4>Para adicionar uma imagem, arraste ou clique aqui.</h4>
                            </div>
                          </div>
                        )}
                      </Dropzone>
                    </Col>
                    <Col md={6}>
                      <div className="dropzone-previews mt-3" id="file-previews">
                        {selectedBGFile ? (
                          <div className="p-2">
                            <Row className="align-items-center">
                              <Col className="col-auto">
                                <img
                                  data-dz-thumbnail=""
                                  height="220"
                                  className="avatar-xl rounded bg-light image-send"
                                  style={{ objectFit: "cover" }}
                                  src={selectedBGFile.preview}
                                />
                              </Col>
                              <Col>
                                <h5 className="text-muted fw-bold">
                                  {selectedBGFile.name}
                                </h5>
                                <p className="mb-0">
                                  <strong>{selectedBGFile.formattedSize}</strong>
                                </p>
                              </Col>
                            </Row>
                          </div>
                        ):(
                          <AtualImage style={{backgroundImage: `url('${background}')`}}></AtualImage>
                        )}
                      </div>
                    </Col>
                  </Row>
                  </InputContainer>

                  <ButtonContainer>
                    <Button onClick={updatePage}>Salvar</Button>
                  </ButtonContainer>

                </CardBody>
                </Card>
            </Col>
            </Row>
        </div>
        <Load id="load">
          <Spinner className="me-2" color="success" />
        </Load>
    </React.Fragment>
    )
}

export default PageGalpoes;

const ButtonContainer = styled.div`
  text-align:right;
`;

const InputContainer = styled.div`
  margin-top:20px;
`;

const Load = styled.div`
  display:none;

  &.active{
    display:flex;
    position: fixed;
    top:0;
    bottom:0;
    left:0;
    right:0;
    background-color: rgba(0,0,0,0.5);
    align-items: center;
    justify-content: center;
    z-index:99999999999999;
  }
`;

const AtualImage = styled.div`
  width:200px;
  height:200px;
  background-repeat:no-repeat;
  background-size:contain;
`;

const Divider = styled.span`
  width:100%;
  height:1px;
  background-color:#cdcdcd;
  display:block;
  margin-top:30px;
`;