import { useState, useEffect } from 'react';
import './style.scss';
import {
  Button,
  Col,
  Form,
  Input,
  Row
} from "reactstrap";
import Select from "react-select";
import Dropzone from 'react-dropzone';
import { Link } from "react-router-dom";

const Step2 = ({ handleChildStep2 }) => {
  const [name, setName] = useState('');
  const [urlParameter, setUrlParameter] = useState('');
  const [state, setState] = useState([]);
  const [statesAddress, setStatesAddress] = useState([])
  const [citiesAddress, setCitiesAddress] = useState([])
  const [city, setCity] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);

  const dataToSend = {
    name,
    urlParameter,
    city,
    state,
    selectedFile,
  };

  useEffect(() => {
    handleChildStep2(dataToSend);
  }, [name, 
      urlParameter, 
      city,
      state,
      selectedFile])

      const handleAcceptedFiles = (files) => {
        // Aceitar apenas um arquivo
        const file = files[0];
        if (file) {
          setSelectedFile({
            ...file,
            preview: URL.createObjectURL(file),
            name: file.name,
            formattedSize: formatBytes(file.size),
            file:file
          });
        }
      };

      const formatBytes = (bytes, decimals = 2) => {
        if (bytes === 0) return "0 Bytes"
        const k = 1024
        const dm = decimals < 0 ? 0 : decimals
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
        const i = Math.floor(Math.log(bytes) / Math.log(k))
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
      }

      useEffect(() => {
        fetch('https://servicodados.ibge.gov.br/api/v1/localidades/estados',)
        .then((res) => res.json())
        .then(response => {
          const states = []
          for(var i = 0; response.length > i; i++){
            states.push({
              value: response[i].sigla,
              label: response[i].nome
            })
          }
          setStatesAddress(states)
        })
      }, [])

      useEffect(() => {
        fetch('https://servicodados.ibge.gov.br/api/v1/localidades/estados/' + state.value + '/municipios',)
        .then((res) => res.json())
        .then(response => {
          const cities = []
          for(var i = 0; response.length > i; i++){
            cities.push({
              value: response[i].nome,
              label: response[i].nome
            })
          }
          setCitiesAddress(cities)
        })
      }, [state])

      

  return(
    <div>
      <Form>
        <Row>
              <h5 className="title-imovel">Sobre a construtora</h5>
              <Row style={{margin:'30px 0'}}>
                <Col md={6}>
                  <Row className="mb-3">
                    <label htmlFor="txtFirstNameShipping"
                      className="col-lg-3 col-form-label">Nome da construtora</label>
                    <div className="col-lg-9">
                      <Input 
                        id="txtFirstNameShipping" 
                        name="txtFirstNameShipping" 
                        placeholder="Nome"
                        type="text" 
                        className="form-control" 
                        value={name}
                        onChange={e => {
                          setName(e.target.value)
                          setUrlParameter(e.target.value.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/[^\w\s-]/g, "").replace(/\s+/g, '-').trim())
                        }}
                      />
                    <small>Como será exibido no site.</small>
                    </div>
                  </Row>
                </Col>
                <Col md={6}>
                  <Row className="mb-3">
                    <label htmlFor="txtCompanyShipping" className="col-lg-3 col-form-label">Url</label>
                    <div className="col-lg-9">
                      <Input 
                        id="txtCompanyShipping" 
                        name="txtCompanyShipping" 
                        placeholder="Url"
                        type="text" 
                        className="form-control" 
                        value={urlParameter}
                        onChange={e => setUrlParameter(e.target.value)}
                      />
                    </div>
                  </Row>
                </Col>
              </Row>
            </Row>
            <Row>
              <h5 className="title-imovel-endereco">Localização da construtora</h5>
              <Row style={{margin:'30px 0'}}>
              <Col md={4}>
                <Row className="mb-3">
                  <label htmlFor="txtStateProvinceShipping"
                    className="col-lg-3 col-form-label">Estado</label>
                  <div className="col-lg-9">
                    <Select
                        value={state}
                        placeholder="Selecione"
                        noOptionsMessage={(inputValue) => `Sem resultados`}
                        onChange={(e) => {
                            setState(e)
                        }}
                        options={statesAddress}
                        classNamePrefix="select2-selection"
                      />
                  </div>
                </Row>
              </Col>
              <Col md={4}>
                <Row className="mb-3">
                  <label htmlFor="txtStateProvinceShipping"
                    className="col-lg-3 col-form-label">Cidade</label>
                  <div className="col-lg-9">
                    <Select
                        value={city}
                        placeholder="Selecione"
                        noOptionsMessage={(inputValue) => `Sem resultados`}
                        onChange={(e) => {
                            setCity(e)
                        }}
                        options={citiesAddress}
                        classNamePrefix="select2-selection"
                      />
                  </div>
                </Row>
              </Col>
              <Col md={4}>
                <Row className="mb-3">
                  <label htmlFor="txtFirstNameShipping"
                    className="col-lg-3 col-form-label">Local</label>
                  <div className="col-lg-9">
                    <Input 
                      id="txtFirstNameShipping" 
                      name="txtFirstNameShipping" 
                      placeholder="Local"
                      type="text" 
                      className="form-control" 
                      value={city.value}
                      onChange={e => {
                        setCity({
                          value:e.target.value,
                          label:e.target.value
                        })
                      }}
                    />
                  </div>
                </Row>
              </Col>
            </Row>
            <Row>
              <h5 className="title-imovel-endereco">Logo da construtora</h5>
              <Row style={{margin:'30px 0'}}>
              <Col md={6}>
                  <Dropzone
                  accept=".png, .jpeg, .jpg, .heic"
                  onDrop={acceptedFiles => handleAcceptedFiles(acceptedFiles)}
                  // Limitar a 1 arquivo
                  maxFiles={1}
                >
                  {({ getRootProps, getInputProps }) => (
                    <div className="dropzone" {...getRootProps()}>
                      <div className="dz-message needsclick" {...getRootProps()}>
                        <input {...getInputProps()} />
                        <div className="mb-3">
                          <i className="display-4 text-muted ri-upload-cloud-2-line"></i>
                        </div>
                        <h4>Para adicionar uma imagem, arraste ou clique aqui.</h4>
                      </div>
                    </div>
                  )}
                </Dropzone>
              </Col>
              <Col md={6}>
                <div className="dropzone-previews mt-3" id="file-previews">
                  {selectedFile && (
                    <div className="p-2">
                      <Row className="align-items-center">
                        <Col className="col-auto">
                          <img
                            data-dz-thumbnail=""
                            height="220"
                            className="avatar-xl rounded bg-light image-send"
                            style={{ objectFit: "cover" }}
                            src={selectedFile.preview}
                          />
                        </Col>
                        <Col>
                          <Link to="#" className="text-muted fw-bold">
                            {selectedFile.name}
                          </Link>
                          <p className="mb-0">
                            <strong>{selectedFile.formattedSize}</strong>
                          </p>
                        </Col>
                      </Row>
                    </div>
                  )}
                </div>
              </Col>
              </Row>
            </Row>
        </Row>
      </Form>
    </div>
  )
}

export default Step2;