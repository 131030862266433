import React, { useState, useEffect } from "react"
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { Card, Row, Col, Form, Button } from "reactstrap"
import Dropzone from "react-dropzone"
import './style.scss'
import { Link } from "react-router-dom"

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)
  return result
}

const Step5 = ({ handleChildStep5, send }) => {
  const [selectedFiles, setSelectedFiles] = useState([])

  const onDrop = acceptedFiles => {
    const updatedFiles = acceptedFiles.map((file, index) => {
      const updatedFile = {
        ...file,
        name: file.name,
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
        position: index,
        fileData: file
      };
      return updatedFile;
    });
  
    setSelectedFiles(prevState => [...prevState, ...updatedFiles]);
  };

  const dataToSend = {
    updatedFiles: selectedFiles
  }

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const items = reorder(
      selectedFiles,
      result.source.index,
      result.destination.index
    );
    setSelectedFiles(items);
  
    console.log("Nova ordem das fotos:", items);
  };
  
  const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  const handleRemove = (index) => {
    const newFiles = selectedFiles.filter((file, i) => i !== index);
    setSelectedFiles(newFiles);
  };

  
  useEffect(() => {
      const formData = new FormData();
  
      selectedFiles.forEach((fileData, index) => {
        formData.append(`entries[${index}][position]`, index + 1);
        formData.append(`entries[${index}][alt]`, send[1] + (index + 1));
        formData.append(`entries[${index}][image]`, fileData);
      });
  
      fetch(`process.env.REACT_APP_API_URL' + '/upload-image/${send[0]}`, {
        method: 'POST',
        body: formData,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
        .then(response => response.json())
        .then(data => {
          // Handle response from the server
        })
        .catch(error => {
          // Handle error
        });
  }, [send])


  return (
    <div>
      <Form>
        <Dropzone onDrop={onDrop}>
          {({ getRootProps, getInputProps }) => (
            <div className="dropzone dz-clickable">
              <div
                className="dz-message needsclick"
                {...getRootProps()}
              >
                <input {...getInputProps()} />
                <div className="mb-3">
                  <i className="display-4 text-muted mdi mdi-upload-network-outline"></i>
                </div>
                <h4>Para adicionar fotos arraste ou clique aqui.</h4>
              </div>
            </div>
          )}
        </Dropzone>

        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                className="dropzone-previews mt-3"
                id="file-previews"
              >
                {selectedFiles.map((file, index) => (
                  <Draggable key={index.toString()} draggableId={index.toString()} index={index}>
                    {(provided, snapshot) => (
                      <div ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}>
                        <Card
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                        >
                          <div className="p-2">
                            <Row className="align-items-center">
                              <Col className="col-auto">
                                <img
                                  data-dz-thumbnail=""
                                  height="80"
                                  className="avatar-sm rounded bg-light"
                                  alt={file.name}
                                  src={file.preview}
                                />
                              </Col>
                              <Col>
                                <Link to="#" className="text-muted font-weight-bold">
                                  {file.name}
                                </Link>
                                <p className="mb-0">
                                  <strong>{file.formattedSize}</strong>
                                </p>
                              </Col>
                              <Col className="col-auto">
                                <Button
                                  color="danger"
                                  size="sm"
                                  style={{ float: 'right' }}
                                  onClick={() => handleRemove(index)}
                                >
                                  Excluir
                                </Button>
                              </Col>
                            </Row>
                          </div>
                        </Card>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </Form>
    </div>
  )
}

export default Step5