import React, { useState } from "react"

import {
  Card,
  CardBody,
  Col,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane
} from "reactstrap"

import classnames from "classnames"
import { Link } from "react-router-dom"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import Step1 from "../../../components/Imobile/Step1"
import Step2 from "../../../components/Imobile/Step2"
import Step3 from "../../../components/Imobile/Step3"
import Step4 from "../../../components/Imobile/Step4"
import Step5 from "../../../components/Imobile/Step5"
import './style.scss'

const EditImobile = () => {
  const [activeTab, setactiveTab] = useState(1);
  const [childDataStep1, setChildDataStep1] = useState({});
  const [childDataStep2, setChildDataStep2] = useState({});
  const [childDataStep3, setChildDataStep3] = useState({});
  const [typeRent, setTypeRent] = useState([]);

  function toggleTab(tab) {
    if (activeTab !== tab) {
      if (tab >= 1 && tab <= 4) {
        setactiveTab(tab)
      }
    }
  }

  const handleChildStep1 = (receivedChildData) => {
    setChildDataStep1(receivedChildData);
  };

  const handleChildStep2 = (receivedChildData) => {
    setChildDataStep2(receivedChildData);
  };

  const handleChildStep3 = (receivedChildData) => {
    setChildDataStep3(receivedChildData);
  };

  const handleSubmit = (event) => {
    event.preventDefault()
    
    const dataOwner = childDataStep1.owner;
    const dataWhatsapp = childDataStep1.whatsapp;
    const dataUnity = childDataStep1.unity;
    const dataObjective = childDataStep1.objective;
    const dataType = childDataStep1.typeImmobile;
    const dataFee = childDataStep1.fee;
    const dataIptu = childDataStep1.iptu;
    const dataObservation = childDataStep1.observation;
    const dataEnterprise = childDataStep1.enterprise;
    const dataConstruction = childDataStep1.construction;
    const dataPartnership = childDataStep1.partnership;

    const dataNameType = childDataStep2.name;
    const dataAgeConstruction = childDataStep2.ageConstruction;
    const dataUrl = childDataStep2.urlParameter;
    const dataPrice = parseInt(childDataStep2.price.replace('.', '').replace('.', ''));
    const dataPricePrev = parseInt(childDataStep2.pricePrevious.replace('.', '').replace('.', ''));
    const dataZone = childDataStep2.zone;
    const dataZoneFull = childDataStep2.zoneFull;
    const dataRooms = childDataStep2.rooms;
    const dataDemiSuite = childDataStep2.demiSuite;
    const dataSuites = childDataStep2.suites;
    const dataBathrooms = childDataStep2.bathrooms;
    const dataGarage = childDataStep2.garage;
    const dataCondition = childDataStep2.condition;
    const dataCep = childDataStep2.cep;
    const dataAddress = childDataStep2.address;
    const dataNumber = childDataStep2.number;
    const dataComplement = childDataStep2.complement;
    const dataDistrict = childDataStep2.district;
    const dataCity = childDataStep2.city;
    const dataLat = childDataStep2.lat;
    const dataLng = childDataStep2.lng;
    const dataShowMap = childDataStep2.showMapData;

    const dataCapture = childDataStep3.child;
    const dataTitleDesc = childDataStep3.titleDescription;
    const dataTax = childDataStep3.tax;
    const dataVideo = childDataStep3.video;
    const dataTour = childDataStep3.tour;
    const dataStatus = childDataStep3.status;

    const dataDate = new Date();
    const typeRentState = typeRent;
    const dataTypeRent = typeRentState.toString(typeRentState);
    const dataPriceFinal = dataPrice;
    const dataPricePrevFinal = dataPricePrev;


    const data = {
      owner: dataOwner,
      whatsapp: dataWhatsapp,
      unity: dataUnity,
      status: dataStatus,
      highlight: 1,
      integration: '0',
      integration_zap: '0',
      best: '0',
      opportunity: '0',
      objective: dataObjective,
      type_immobile: dataType,
      media_one: "1.jpg",
      media_two: "2.jpg",
      media_three: "3.jpg",
      sku: 0,
      name: dataNameType,
      url_parameter: dataUrl.replace('--', '-'),
      price: dataPriceFinal,
      zone: dataZone,
      zone_full: dataZoneFull,
      rooms: dataRooms,
      suites: dataSuites,
      bathrooms: dataBathrooms,
      garage: dataGarage,
      city: dataCity,
      address: dataAddress,
      complement: dataComplement,
      number: dataNumber,
      cep: dataCep,
      state: 'SC',
      district: dataDistrict,
      lat: dataLat,
      lng: dataLng,
      consultant: dataCapture,
      fee: dataFee,
      iptu: dataIptu,
      type_rent: dataTypeRent,
      observation: dataObservation,
      business_condition: dataCondition,
      price_previous: dataPricePrevFinal,
      date_post: dataDate,
      partnership: dataPartnership,
      demi_suite: dataDemiSuite,
      title_description: dataTitleDesc,
      tax: dataTax,
      show_map: dataShowMap,
      enterprise: dataEnterprise,
      construction: dataConstruction,
      video: dataVideo,
      tour: dataTour,
      print_name: '',
      print_vendor: '',
      print_description: '',
      age_construction: dataAgeConstruction
    };
    console.log(data)
  }

  return(
    <React.Fragment>
      <div className="page-content">

        <Breadcrumbs title="Gestão de Imóveis" breadcrumbItem="Editar Imóvel" />
        <Row>
          <Col lg="12">
            <Card>
              <CardBody>
                <h4 className="card-title">Editar Imóvel</h4>
                <p className="card-title-desc">Preencha o maior número de informações possível.</p>
                <div className="form-wizard-wrapper wizard clearfix">
                  <div className="steps clearfix">
                    <ul>
                      <NavItem
                        className={classnames({ current: activeTab === 1 })}>
                        <NavLink
                          className={classnames({ current: activeTab === 1 })}
                          onClick={() => {
                            setactiveTab(1)
                          }}
                        >
                          <span className="number">1.</span>{" "}
                            Sobre o Propri./Negócio
                          </NavLink>
                      </NavItem>
                      <NavItem className={classnames({ current: activeTab === 2 })}>
                        <NavLink
                          className={classnames({ active: activeTab === 2 })}
                          onClick={() => {
                            setactiveTab(2)
                          }}
                        >
                          <span className="number">2.</span>{" "}
                            Sobre Imóvel
                          </NavLink>
                      </NavItem>
                      <NavItem className={classnames({ current: activeTab === 3 })}>
                        <NavLink
                          className={classnames({ active: activeTab === 3 })}
                          onClick={() => {
                            setactiveTab(3)
                          }}
                        >
                          <span className="number">3.</span>
                          Descrições
                        </NavLink>
                      </NavItem>
                      <NavItem className={classnames({ current: activeTab === 4 })}>
                        <NavLink
                          className={classnames({ active: activeTab === 4 })}
                          onClick={() => {
                            setactiveTab(4)
                          }}
                        >
                          <span className="number">4.</span>
                          Caract. do Imóvel
                        </NavLink>
                      </NavItem>
                      <NavItem className={classnames({ current: activeTab === 5 })}>
                        <NavLink
                          className={classnames({ active: activeTab === 5 })}
                          onClick={() => {
                            setactiveTab(5)
                          }}
                        >
                          <span className="number">5.</span>
                          Fotos do Imóvel
                        </NavLink>
                      </NavItem>
                      <NavItem className={classnames({ current: activeTab === 6 })}>
                        <NavLink
                          className={classnames({ active: activeTab === 6 })}
                          onClick={() => {
                            setactiveTab(6)
                          }}
                        >
                          <span className="number">6.</span>
                          Confirm Detail
                        </NavLink>
                      </NavItem>
                    </ul>
                  </div>
                  <div className="content clearfix">
                    <TabContent
                      activeTab={activeTab}
                      className="body"
                    >
                      <TabPane tabId={1}>
                        <Step1 handleChildStep1={handleChildStep1}/>
                      </TabPane>

                      <TabPane tabId={2}>
                        <Step2 handleChildStep2={handleChildStep2}/>
                      </TabPane>

                      <TabPane tabId={3}>
                        <Step3 handleChildStep3={handleChildStep3}/>
                      </TabPane>

                      <TabPane tabId={4}>
                        <Step4 />
                      </TabPane>

                      <TabPane tabId={5}>
                        <Step5 />
                      </TabPane>

                      <TabPane tabId={6}>
                        <div className="row justify-content-center">
                          <Col lg="6">
                            <div className="text-center">
                              <div className="mb-4">
                                <i className="mdi mdi-check-circle-outline text-success display-4" />
                              </div>
                              <div>
                                <h5>Confirm Detail</h5>
                                <p className="text-muted">
                                  If several languages coalesce, the grammar of
                                  the resulting
                                </p>
                                <button onClick={handleSubmit}>Teste DATA</button>
                              </div>
                            </div>
                          </Col>
                        </div>
                      </TabPane>
                    </TabContent>
                  </div>
                  <div className="actions clearfix">
                    <ul>
                      <li
                        className={
                          activeTab === 1 ? "previous disabled" : "previous"
                        }
                      >
                        <Link
                          to="#"
                          className="btn btn-primary"
                          onClick={() => {
                            toggleTab(activeTab - 1)
                          }}
                        >
                          Anterior
                          </Link>
                      </li>
                      <li
                        className={activeTab === 6 ? "next disabled" : "next"}
                      >
                        <Link
                          to="#"
                          className="btn btn-primary"
                          onClick={() => {
                            toggleTab(activeTab + 1)
                          }}
                        >
                          Próximo
                          </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>

      </div>
    </React.Fragment>
  )
}

export default EditImobile