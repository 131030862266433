import { useState, useEffect } from 'react';
import './style.scss';
import {
  Col,
  Form,
  Input,
  Row
} from "reactstrap"

const Step2 = ({ handleChildStep2 }) => {
  const [name, setName] = useState('');
  const [ageConstruction, setAgeConstruction] = useState('')
  const [urlParameter, setUrlParameter] = useState('');
  const [pricePrevious, setPricePrevious] = useState('0');
  const [price, setPrice] = useState('');
  const [zone, setZone] = useState('');
  const [zoneFull, setZoneFull] = useState('');
  const [rooms, setRooms] = useState('');
  const [demiSuite, setDemiSuite] = useState('');
  const [suites, setSuites] = useState('');
  const [bathrooms, setBathrooms] = useState('');
  const [garage, setGarage] = useState('');
  const [condition, setCondition] = useState('');
  const [cep, setCep] = useState('');
  const [address, setAddress] = useState('');
  const [number, setNumber] = useState('');
  const [complement, setComplement] = useState('');
  const [district, setDistrict] = useState('');
  const [city, setCity] = useState('');
  const [lat, setLat] = useState('0');
  const [lng, setLng] = useState('0');
  const [showMap, setShowMap] = useState(false);

  const dataToSend = {
    name,
    ageConstruction,
    urlParameter,
    pricePrevious,
    price,
    zone,
    zoneFull,
    rooms,
    demiSuite,
    suites,
    bathrooms,
    garage,
    condition,
    cep,
    address,
    number,
    complement,
    district,
    city,
    lat,
    lng,
    showMap
  };

  useEffect(() => {
    handleChildStep2(dataToSend);
  }, [name, 
      ageConstruction, 
      urlParameter, 
      pricePrevious, 
      price, 
      zone, 
      zoneFull, 
      rooms, 
      demiSuite, 
      suites, 
      bathrooms, 
      garage, 
      condition, 
      cep, 
      address, 
      number, 
      complement, 
      district, 
      city, 
      lat, 
      lng, 
      showMap])

  return(
    <div>
      <Form>
        <Row>
              <h5 className="title-imovel">Sobre o imóvel</h5>
              <Row>
                <Col md={6}>
                  <Row className="mb-3">
                    <label htmlFor="txtFirstNameShipping"
                      className="col-lg-3 col-form-label">Nome do Imóvel</label>
                    <div className="col-lg-9">
                      <Input 
                        id="txtFirstNameShipping" 
                        name="txtFirstNameShipping" 
                        placeholder="Nome"
                        type="text" 
                        className="form-control" 
                        value={name}
                        onChange={e => setName(e.target.value)}
                      />
                    </div>
                  </Row>
                </Col>
                <Col md={6}>
                  <Row className="mb-3">
                    <label htmlFor="txtLastNameShipping"
                      className="col-lg-3 col-form-label">Ano de Construção</label>
                    <div className="col-lg-9">
                      <Input 
                        id="txtLastNameShipping" 
                        name="txtLastNameShipping" 
                        placeholder="Ano de Construção"
                        type="text" 
                        className="form-control" 
                        value={ageConstruction}
                        onChange={e => setAgeConstruction(e.target.value)}
                      />
                    </div>
                  </Row>
                </Col>
            </Row>

            <Row>
              <Col md={6}>
                <Row className="mb-3">
                  <label htmlFor="txtCompanyShipping" className="col-lg-3 col-form-label">Url</label>
                  <div className="col-lg-9">
                    <Input 
                      id="txtCompanyShipping" 
                      name="txtCompanyShipping" 
                      placeholder="Url"
                      type="text" 
                      className="form-control" 
                      value={urlParameter}
                      onChange={e => setUrlParameter(e.target.value)}
                    />
                  </div>
                </Row>
              </Col>
              <Col md={6}>
                <Row className="mb-3">
                  <label htmlFor="txtEmailAddressShipping"
                    className="col-lg-3 col-form-label">Valor RISCADO em R$.</label>
                  <div className="col-lg-9">
                    <Input 
                      id="txtEmailAddressShipping"
                      name="txtEmailAddressShipping" 
                      type="text"
                      className="form-control" 
                      value={pricePrevious}
                      onChange={e => setPricePrevious(e.target.value)}
                    />
                  </div>
                </Row>
              </Col>
            </Row>

            <Row>
              <Col md={6}>
                <Row className="mb-3">
                  <label htmlFor="txtCityShipping" className="col-lg-3 col-form-label">Valor em R$</label>
                  <div className="col-lg-9">
                    <Input 
                      id="txtCityShipping" 
                      name="txtCityShipping" 
                      type="text" 
                      placeholder="Valor"
                      className="form-control" 
                      value={price}
                      onChange={e => setPrice(e.target.value)}
                    />
                  </div>
                </Row>
              </Col>
              <Col md={6}>
                <Row className="mb-3">
                  <label htmlFor="txtStateProvinceShipping"
                    className="col-lg-3 col-form-label">Metragem Útil</label>
                  <div className="col-lg-9">
                    <Input 
                      id="txtStateProvinceShipping"
                      name="txtStateProvinceShipping" 
                      type="text" 
                      placeholder="Metragem Útil"
                      className="form-control" 
                      value={zone}
                      onChange={e => setZone(e.target.value)}
                    />
                  </div>
                </Row>
              </Col>
            </Row>

            <Row>
              <Col md={6}>
                <Row className="mb-3">
                  <label htmlFor="txtCityShipping" className="col-lg-3 col-form-label">Metragem Total</label>
                  <div className="col-lg-9">
                    <Input 
                      id="txtCityShipping" 
                      name="txtCityShipping" 
                      type="text" 
                      placeholder="Metragem Total"
                      className="form-control" 
                      value={zoneFull}
                      onChange={e => setZoneFull(e.target.value)}
                    />
                  </div>
                </Row>
              </Col>
              <Col md={6}>
                <Row className="mb-3">
                  <label htmlFor="txtStateProvinceShipping"
                    className="col-lg-3 col-form-label">Nº de Quartos</label>
                  <div className="col-lg-9">
                    <Input 
                      id="txtStateProvinceShipping"
                      name="txtStateProvinceShipping" 
                      type="text" 
                      placeholder="Nº de Quartos"
                      className="form-control" 
                      value={rooms}
                      onChange={e => setRooms(e.target.value)}
                    />
                  </div>
                </Row>
              </Col>
            </Row>

            <Row>
              <Col md={6}>
                <Row className="mb-3">
                  <label htmlFor="txtCityShipping" className="col-lg-3 col-form-label">Nº de Demi-Suítes</label>
                  <div className="col-lg-9">
                    <Input 
                      id="txtCityShipping" 
                      name="txtCityShipping" 
                      type="text" 
                      placeholder="Nº de Demi-Suítes"
                      className="form-control"
                      value={demiSuite}
                      onChange={e => setDemiSuite(e.target.value)}
                    />
                  </div>
                </Row>
              </Col>
              <Col md={6}>
                <Row className="mb-3">
                  <label htmlFor="txtStateProvinceShipping"
                    className="col-lg-3 col-form-label">Nº de Suítes</label>
                  <div className="col-lg-9">
                    <Input 
                      id="txtStateProvinceShipping"
                      name="txtStateProvinceShipping" 
                      type="text" 
                      placeholder="Nº de Suítes"
                      className="form-control" 
                      value={suites}
                      onChange={e => setSuites(e.target.value)}
                    />
                  </div>
                </Row>
              </Col>
            </Row>

            <Row>
              <Col md={6}>
                <Row className="mb-3">
                  <label htmlFor="txtCityShipping" className="col-lg-3 col-form-label">Nº de Banheiros</label>
                  <div className="col-lg-9">
                    <Input 
                      id="txtCityShipping" 
                      name="txtCityShipping" 
                      type="text" 
                      placeholder="Nº de Banheiros"
                      className="form-control" 
                      value={bathrooms}
                      onChange={e => setBathrooms(e.target.value)}
                    />
                  </div>
                </Row>
              </Col>
              <Col md={6}>
                <Row className="mb-3">
                  <label htmlFor="txtStateProvinceShipping"
                    className="col-lg-3 col-form-label">Vagas de Garagem</label>
                  <div className="col-lg-9">
                    <Input 
                      id="txtStateProvinceShipping"
                      name="txtStateProvinceShipping" 
                      type="text" 
                      placeholder="Vagas de Garagem"
                      className="form-control" 
                      value={garage}
                      onChange={e => setGarage(e.target.value)}
                    />
                  </div>
                </Row>
              </Col>
            </Row>

            <Row>
                <Col md={6}>
                  <Row className="mb-3">
                    <label htmlFor="txtCityShipping" className="col-lg-3 col-form-label">Condições de Pagamento</label>
                    <div className="col-lg-9">
                      <Input 
                        id="txtCityShipping" 
                        name="txtCityShipping" 
                        type="textarea" 
                        placeholder="Condições de Pagamento"
                        className="form-control" 
                        value={condition}
                        onChange={e => setCondition(e.target.value)}
                      />
                    </div>
                  </Row>
                </Col>
              </Row>
            </Row>

            <Row>
              <h5 className="title-imovel-endereco">Endereço do imóvel</h5>
              <Row>
                <Col md={6}>
                  <Row className="mb-3">
                    <label htmlFor="txtFirstNameShipping"
                      className="col-lg-3 col-form-label">CEP</label>
                    <div className="col-lg-9">
                      <Input
                        id="txtFirstNameShipping" 
                        name="txtFirstNameShipping" 
                        placeholder="CEP"
                        type="tel"
                        pattern="[0-9]*"
                        className="form-control" 
                        value={cep}
                        onChange={e => setCep(e.target.value.replace(/\D/g, ''))}
                      />
                    </div>
                  </Row>
                </Col>
                <Col md={6}>
                  <Row className="mb-3">
                    <label htmlFor="txtLastNameShipping"
                      className="col-lg-3 col-form-label">Nome da Rua</label>
                    <div className="col-lg-9">
                      <Input 
                        id="txtLastNameShipping" 
                        name="txtLastNameShipping" 
                        placeholder="Nome da Rua"
                        type="text" 
                        className="form-control" 
                        value={address}
                        onChange={e => setAddress(e.target.value)}
                      />
                    </div>
                  </Row>
                </Col>
            </Row>

            <Row>
              <Col md={6}>
                <Row className="mb-3">
                  <label htmlFor="txtCompanyShipping" className="col-lg-3 col-form-label">Nº</label>
                  <div className="col-lg-9">
                    <Input 
                      id="txtCompanyShipping" 
                      name="txtCompanyShipping" 
                      placeholder="Número"
                      type="text" 
                      className="form-control" 
                      value={number}
                      onChange={e => setNumber(e.target.value)}
                    />
                  </div>
                </Row>
              </Col>
              <Col md={6}>
                <Row className="mb-3">
                  <label htmlFor="txtEmailAddressShipping"
                    className="col-lg-3 col-form-label">Complemento</label>
                  <div className="col-lg-9">
                    <Input 
                      id="txtEmailAddressShipping" 
                      name="txtEmailAddressShipping" 
                      type="text" 
                      className="form-control" 
                      placeholder="Complemento" 
                      value={complement}
                      onChange={e => setComplement(e.target.value)}
                    />
                  </div>
                </Row>
              </Col>
            </Row>

            <Row>
              <Col md={6}>
                <Row className="mb-3">
                  <label htmlFor="txtCityShipping" className="col-lg-3 col-form-label">Bairro</label>
                  <div className="col-lg-9">
                    <Input 
                      id="txtCityShipping" 
                      name="txtCityShipping" 
                      type="text" 
                      placeholder="Bairro"
                      className="form-control" 
                      value={district}
                      onChange={e => setDistrict(e.target.value)}
                    />
                  </div>
                </Row>
              </Col>
              <Col md={6}>
                <Row className="mb-3">
                  <label htmlFor="txtStateProvinceShipping"
                    className="col-lg-3 col-form-label">Cidade</label>
                  <div className="col-lg-9">
                    <Input 
                      id="txtStateProvinceShipping"
                      name="txtStateProvinceShipping" 
                      type="text" 
                      placeholder="Cidade"
                      className="form-control" 
                      value={city}
                      onChange={e => setCity(e.target.value)}
                    />
                  </div>
                </Row>
              </Col>
            </Row>

            <Row>
              <Col md={6}>
                <Row className="mb-3">
                  <label htmlFor="txtCityShipping" className="col-lg-3 col-form-label">Latitude</label>
                  <div className="col-lg-9">
                    <Input 
                      id="txtCityShipping" 
                      name="txtCityShipping" 
                      type="text" 
                      placeholder="Latitude"
                      className="form-control" 
                      value={lat}
                      onChange={e => setLat(e.target.value)}
                    />
                  </div>
                </Row>
              </Col>
              <Col md={6}>
                <Row className="mb-3">
                  <label htmlFor="txtStateProvinceShipping"
                    className="col-lg-3 col-form-label">Longitude</label>
                  <div className="col-lg-9">
                    <Input 
                      id="txtStateProvinceShipping"
                      name="txtStateProvinceShipping" 
                      type="text" 
                      placeholder="Longitude"
                      className="form-control" 
                      value={lng}
                      onChange={e => setLng(e.target.value)}
                    />
                  </div>
                </Row>
              </Col>
              <Col md={6}>
                <Row className="mb-3">
                  <label htmlFor="txtTelephoneBilling"
                    className="col-lg-3 col-form-label"></label>
                  <div className="col-lg-9">
                    <div className="content-location">
                      <Input 
                        id="txtTelephoneBilling" 
                        name="txtTelephoneBilling"
                        type="checkbox" 
                        className="form-control" 
                        value={showMap}
                        onChange={e => setShowMap(e.target.value)}
                      />
                      <span>Exibir indicador no mapa</span>
                    </div>
                  </div>
                </Row>
              </Col>
            </Row>
        </Row>
      </Form>
    </div>
  )
}

export default Step2