import styled from 'styled-components';
import {MdDeleteForever} from 'react-icons/md';

const ImmobileSelectedCard = ({data, onDelete}) => {

    const types = [
        {type: 'facing_sea', label: 'Frente mar'},
        {type: 'sea_court', label: 'Quadra mar'},
        {type: 'apartament_dif', label: 'Diferenciado'},
        {type: 'roof', label: 'Cobertura'},
        {type: 'others_apartament', label: 'Outros apartamentos'},
        {type: 'house', label: 'Casa'},
        {type: 'terrain', label: 'Terreno residencial'},
        {type: 'terrain_land_shed', label: 'Terreno logístico'},
        {type: 'land_shed', label: 'Galpão logístico'},
    ]

    return(
        <>
    <ConstructionCardContainer>
        {data.photos?.filter(photo => photo?.position === 0)[0]?.medium_image ?
            <Cover style={{backgroundImage:'url(' + data.photos?.filter(photo => photo?.position === 0)[0]?.medium_image + ')'}}></Cover>
            :
            <Cover></Cover>
        }
        <Content>
            <h5>código: {data.sku}</h5>
            <h3>{data.name}</h3>
            <h4>{data.type?.replace(data.type, types?.filter(typeS => typeS.type === data.type)[0]?.label )}</h4>
            <p>{data.city}</p>
        </Content>
        <CloseButton onClick={() => {
                onDelete(data.id)
            }}><MdDeleteForever size="1.5rem" color="#fff"/></CloseButton>

    </ConstructionCardContainer>

    </>
    )
}

export default ImmobileSelectedCard;

const ConstructionCardContainer = styled.div`
    width:200px;
    height:260px;
    box-shadow:0 0 5px rgba(0,0,0,0.5);
    border-radius:8px;
    padding:10px;
    transition:0.2s;
    position:relative;
    cursor:pointer;

    &:hover{
        transform:scale(1.03);
    }
`;

const Cover = styled.div`
    background-position:center center;
    background-size:cover;
    width:100%;
    height:100px;
    background-color:#f4f4f4;
`;

const Content = styled.div`
    text-align:left;
    padding-top:10px;

    h5{
        font-size:14px;
        background-color:#cdcdcd;
        border-radius:8px;
        padding:3px 5px;
    }
    h4{
        font-size:17px;
    }
    h3{
        font-size:16px;
    }
`;

const CloseButton = styled.div`
    position:absolute;
    top:-8px;
    z-index:10;
    right:-8px;
    background-color:#B91C1C;
    border-radius:50%;
    cursor:pointer;
    width:30px;
    height:30px;
    display:flex;
    align-items:center;
    justify-content:center;
    box-shadow:0 0 5px rgba(0,0,0,0.3);

    &:hover{
        transform:scale(1.2);
    }
`;