import { useState, useEffect, useRef } from 'react';
import PasswordChecklist from "react-password-checklist"
import './style.scss';
import {
  Col,
  Form,
  Input,
  Row
} from "reactstrap"
import styled from 'styled-components';
import Dropzone from 'react-dropzone';
import { Editor } from '@tinymce/tinymce-react';
import { Link } from 'react-router-dom/cjs/react-router-dom';

const Step1 = ({ handleChildStep1, post }) => {
  const editorRef = useRef(null);
  const [category, setCategory] = useState('')
  const [content, setContent] = useState('')
  const [subtitle, setSubtitle] = useState('')
  const [title, setTitle] = useState('')
  const [url, setUrl] = useState('')
  const [media, setMedia] = useState(null)
  const [photo, setPhoto] = useState('')

  const dataToSend = {
    category,
    content,
    subtitle,
    title,
    url,
    media,
  };

  useEffect(() => {
    handleChildStep1(dataToSend);
  }, [category,
    content,
    subtitle,
    title,
    url,
    media,]);

    const handleAcceptedFiles = (files) => {
      // Aceitar apenas um arquivo
      const file = files[0];
      if (file) {
        setMedia({
          ...file,
          preview: URL.createObjectURL(file),
          name: file.name,
          formattedSize: formatBytes(file.size),
          file:file
        });
      }
    };

    const formatBytes = (bytes, decimals = 2) => {
      if (bytes === 0) return "0 Bytes"
      const k = 1024
      const dm = decimals < 0 ? 0 : decimals
      const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
      const i = Math.floor(Math.log(bytes) / Math.log(k))
      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
    }

    useEffect(() => {
      setCategory(post?.category)
      setContent(post?.content)
      setSubtitle(post?.sub_title)
      setTitle(post?.title)
      setUrl(post?.url)
      setPhoto(post?.media)
    },[post])


  return(
    <div>
      <Form>
        <Row>
          <h5 className="title-proprietario">Sobre o post</h5>
          <Row>
            <Col md={6}>
              <Row className="mb-3">
                <label htmlFor="txtAddress1Billing"
                  className="col-lg-4 col-form-label">Categoria</label>
                <div className="col-lg-7">
                  <Input 
                    type="select" 
                    id="ddlCreditCardType" 
                    name="ddlCreditCardType"
                    className="form-select"
                    value={category}
                    onChange={e => setCategory(e.target.value)}
                  >
                    <option value="">Selecione a categoria</option>
                    <option value='Notícias'>Notícias</option>
                    <option value='Conteúdos'>Conteúdos</option>
                  </Input>
                </div>
              </Row>
            </Col>
            </Row>
            <Row>
            <Col md={12}>
              <Row className="mb-3">
                <label htmlFor="txtFirstNameBilling"
                  className="col-lg-2 col-form-label">Título</label>
                <div className="col-lg-10">
                  <Input 
                    id="txtFirstNameBilling" 
                    name="txtFirstNameBilling" 
                    placeholder="Título"
                    type="text" 
                    className="form-control" 
                    value={title}
                    onChange={e => {
                      setTitle(e.target.value)
                      setUrl(e.target.value.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/[^\w\s-]/g, "").replace(/\s+/g, '-').trim())
                    }}
                  />
                </div>
              </Row>
            </Col>
            <Col md={12}>
              <Row className="mb-3">
                <label htmlFor="txtFirstNameBilling"
                  className="col-lg-2 col-form-label">Url</label>
                <div className="col-lg-10">
                  <Input 
                    id="txtFirstNameBilling" 
                    name="txtFirstNameBilling" 
                    placeholder="Url"
                    type="text" 
                    className="form-control" 
                    value={url}
                    onChange={e => setUrl(e.target.value)}
                  />
                </div>
              </Row>
            </Col>
            <Col md={12}>
              <Row className="mb-3">
                <label htmlFor="txtLastNameBilling"
                  className="col-lg-2 col-form-label">Sub Título</label>
                <div className="col-lg-10">
                  <Input 
                    id="txtLastNameBilling" 
                    name="txtLastNameBilling" 
                    placeholder="Sub título"
                    type="text" 
                    className="form-control"
                    value={subtitle}
                    onChange={e => setSubtitle(e.target.value)}
                  />
                </div>
              </Row>
            </Col>
          </Row>
          <Row style={{margin:'30px 0'}}>
            <h5 className="title-imovel-endereco">Imagem destaque</h5>
            <Row>
            {(photo !== '' && photo && photo !== 'null') && <Col md={12}>
                  <div style={{
                    backgroundImage:'url(' + photo + ')',
                    backgroungPosition:'center center',
                    backgroundSize:'cover',
                    width:'200px',
                    height:'120px',
                    borderRadius:'5px',
                    marginBottom:'15px'
                  }}></div>
                </Col>}
            <Col md={6}>
                <Dropzone
                accept=".png, .jpeg, .jpg, .heic"
                onDrop={acceptedFiles => handleAcceptedFiles(acceptedFiles)}
                // Limitar a 1 arquivo
                maxFiles={1}
              >
                {({ getRootProps, getInputProps }) => (
                  <div className="dropzone" {...getRootProps()}>
                    <div className="dz-message needsclick" {...getRootProps()}>
                      <input {...getInputProps()} />
                      <div className="mb-3">
                        <i className="display-4 text-muted ri-upload-cloud-2-line"></i>
                      </div>
                      <h4>Para adicionar uma imagem, arraste ou clique aqui.</h4>
                    </div>
                  </div>
                )}
              </Dropzone>
            </Col>
            <Col md={6}>
              <div className="dropzone-previews mt-3" id="file-previews">
                {media && (
                  <div className="p-2">
                    <Row className="align-items-center">
                      <Col className="col-auto">
                        <img
                          data-dz-thumbnail=""
                          height="220"
                          className="avatar-xl rounded bg-light image-send"
                          style={{ objectFit: "cover" }}
                          src={media.preview}
                        />
                      </Col>
                      <Col>
                        <Link to="#" className="text-muted fw-bold">
                          {media.name}
                        </Link>
                        <p className="mb-0">
                          <strong>{media.formattedSize}</strong>
                        </p>
                      </Col>
                    </Row>
                  </div>
                )}
              </div>
            </Col>
            </Row>
          </Row>
          <Row>
            <h5 className="title-imovel-endereco">Conteúdo</h5>
            <Col md={12} style={{margin:'10px 0 50px'}}>
              <label htmlFor="txtCompanyShipping" className="col-form-label">Conteúdo do post</label>
              <Editor
                apiKey='w3485derw88wqxe25q8jpatcv6be8cvrbwkrta6n2f63trk2'
                onInit={(evt, editor) => editorRef.current = editor}
                onChange={() => 
                  editorRef.current &&
                  setContent(editorRef.current.getContent())
                }
                initialValue={content}
                init={{
                  height: 400,
                  menubar: false,
                  plugins: [
                    'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                    'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                    'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
                  ],
                  toolbar: 'blocks | a11ycheck undo redo | bold italic | forecolor backcolor | template codesample | alignleft aligncenter alignright alignjustify | bullist numlist | link image',
                  content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                }}
              />
            </Col>
          </Row>
        </Row>
      
      </Form>
    </div>
  )
}

export default Step1