import React from "react";
import {
    Button,
  Card,
  CardBody,
  Col,
  Input,
  Row,
  Spinner,
} from "reactstrap";
import Breadcrumbs from "../../../../components/Common/Breadcrumb";
import styled from 'styled-components';
import {BsBuildingAdd, BsBuildingGear, BsBuilding, BsHouseDoor, BsBoundingBoxCircles, BsBoundingBox, BsBoxSeam} from 'react-icons/bs';
import { useState } from "react";
import { useEffect } from "react";
import ConstructionCard from "./constructionCard";
import Pagination from '@mui/material/Pagination';

const ConstructionList = () => {

    const [ constructionList, setConstructionList ] = useState([]);
    const [ search, setSearch ] = useState('');
    const [ page, setPage] = useState(1)
    const [ totalPages, setTotalPages] = useState(1)
    const [loading, setLoading] = useState(false)

    const fetchData = async (value = null) => {
        setLoading(true)
        try {
            const response = await fetch(process.env.REACT_APP_API_URL + '/all-constructions/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
                body: JSON.stringify({
                    page: value ? value : page,
                    search: search,
                }),
            });
            const data = await response.json();
            setConstructionList(data.constructions)
            setLoading(false)
            setTotalPages(data.totalPages)
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        fetchData();
    }, [])

    useEffect(() => {
        localStorage.setItem('page_construction_filter', page)
        search !== '' && localStorage.setItem('text_construction_filter', search)
    }, [page, search])

    useEffect(() => {
        if(localStorage.getItem('page_construction_filter')){
            setPage(parseInt(localStorage.getItem('page_construction_filter')))
        }
        if(localStorage.getItem('text_construction_filter') && localStorage.getItem('text_construction_filter') !== 'null'){
            setSearch(localStorage.getItem('text_construction_filter'))
        }
    }, [])

    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        window.scrollTo(0,0)
        setPage(value);
        fetchData(value);
      };

      const submitSearch = () => {
        fetchData();
      }

      const handleClearFilter = () => {
        localStorage.removeItem('page_construction_filter')
        localStorage.removeItem('text_construction_filter')

        window.location.reload()
      }


    return(
    <React.Fragment>
        <div className="page-content">
            <Breadcrumbs title="Gestão de Imóveis" breadcrumbItem="Construtoras" />
            <Row>
            <Col xs={12}>
                <Card>
                <CardBody>
                    <h4 className="card-title">Construtoras</h4>
                    <Row>
                    <Col md={6}>
                            <label htmlFor="txtFirstNameBilling">Busca</label>
                            <Input 
                                id="txtFirstNameBilling" 
                                name="txtFirstNameBilling" 
                                placeholder="Busque por nome ou cidade"
                                type="text" 
                                className="form-control" 
                                value={search}
                                onChange={e => setSearch(e.target.value)}
                            />
                        </Col>
                        <Col className="col-auto" style={{marginTop:'28px', textAlign:'center'}}>
                            <Button onClick={submitSearch}>Buscar</Button>
                            <ClearFilter onClick={handleClearFilter}>LIMPAR</ClearFilter>
                        </Col>
                    </Row>
                    <OptionsGrid>
                        {constructionList?.map((construction, index) => (
                            <ConstructionCard key={index} data={construction}/>
                        ))}
                    </OptionsGrid>
                    <Row>
                        <Col xs={12}>
                            <Pagination count={totalPages} page={page} onChange={handleChange}/>
                        </Col>
                    </Row>
                </CardBody>
                </Card>
            </Col>
            </Row>
        </div>

        {loading &&
            <Loading>
                <Spinner></Spinner>
            </Loading>
        }
    </React.Fragment>
    )
}

export default ConstructionList;

const ClearFilter = styled.span`
    cursor:pointer;
    margin-top:8px;
`;

const Loading = styled.div`
    width:100%;
    height:100%;
    position:fixed;
    bottom:0;
    top:0;
    left:0;
    right:0;
    background-color:rgba(0,0,0,0.6);
    display:flex;
    align-items:center;
    justify-content:center;
    z-index:99999;
`;

const OptionsGrid = styled.div`
    display:grid;
    margin:30px 0;
    padding:20px;
    grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
    gap:20px;
`;