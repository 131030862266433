import React, { useState, useEffect } from "react";
import { Card, Row, Col, Form, Input, Label } from "reactstrap";
import './style.scss';
import { Link } from "react-router-dom";

export const ImageDragCard = ({ image, index, onPositionChange, onTitleChange, onUrlChange, onRemove }) => {


  const [localTitle, setLocalTitle] = useState(image.title || "");
  const [localUrl, setLocalUrl] = useState(image.url || "");
  const [localPosition, setLocalPosition] = useState(image.position || 0);

  const handleTitleChange = (index, newTitle) => {
    setLocalTitle(newTitle);
    onTitleChange(index, newTitle);
  };

  const handleUrlChange = (index, newUrl) => {
    setLocalUrl(newUrl);
    onUrlChange(index, newUrl);
  };

  const handlePositionChange = (index, newPosition) => {
    setLocalPosition(newPosition);
    onPositionChange(index, newPosition);
  };

    return (
      <Col key={index + "-file"} id={image.name}>
        <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
          <div className="p-2">
            <Row className="align-items-center">
              <Col className="col-auto">
                <img
                  data-dz-thumbnail=""
                  height="180"
                  className="avatar-lg rounded bg-light image-send"
                  style={{objectFit:"cover"}}
                  alt={image.name}
                  src={image.preview}
                />
              </Col>
              <Col>
                <Link to="#" className="text-muted fw-bold">
                  {image.name}
                </Link>
                <p className="mb-0">
                  <strong>{image.formattedSize}</strong>
                </p>
              </Col>
              <Col xs={12} style={{ margin: '10px 0' }}>
                <Label>Alt</Label>
                <Input
                  name={`image-title-${index}`}
                  type="text"
                  placeholder="Título da foto"
                  className="form-control"
                  value={localTitle}
                  onChange={(e) => handleTitleChange(index, e.target.value)}
                />
                {/* <Label>Slug</Label>
                <Input
                  name={`image-url-${index}`}
                  type="text"
                  placeholder="URL da imagem"
                  className="form-control"
                  value={localUrl} 
                  onChange={(e) => handleUrlChange(index, e.target.value.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/[^\w\s-]/g, "").replace(/\s+/g, '-').trim())}
                /> */}
                <Label>Posição</Label>
                <Input
                  name={`image-position-${index}`}
                  type="number"
                  placeholder="Posição da imagem"
                  className="form-control"
                  value={localPosition} 
                  onChange={(e) => handlePositionChange(index, e.target.value)}
                />
              </Col>
              <div
                style={{
                  position: 'absolute',
                  top: '10px',
                  backgroundColor: '#da2c3e',
                  color: '#fff',
                  right: '10px',
                  width: '15px',
                  height: '25px',
                  borderRadius: '5px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  cursor: 'pointer',
                }}
                onClick={() => onRemove(image.path)}
              >x</div>
            </Row>
          </div>
        </Card>
      </Col>
    );
  };