import {
  Button,
    Card,
    CardBody,
    Col,
    Input,
    Label,
    Modal,
    Row,
    Spinner,
  } from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb";
import styled from 'styled-components';
import React, { useEffect, useState } from "react";
import Dropzone from "react-dropzone";
import Gallery from "./gallery";
import Select from "react-select";
import ImmobileCard from "../Imobile/PropertyEdit/ImmobileList/immobileCard";
import ImmobileSelectCard from "./immobileSelectCard";
import ImmobileSelectedCard from "./immobileSeletedCard";


const PageHome = () => {

  const [aboutPage, setHomePage] = useState([])

  const [hightPosts1, setHightPosts1] = useState([])
  const [hightPosts2, setHightPosts2] = useState([])
  const [hightOpportunity, setHightOpportunity] = useState('')
  const [highLaunch, setHightLaunch] = useState('')
  const [bannerType, setBannerType] = useState('')
  const [sendProp, setSendPro] = useState([])
  const [gallery, setGallery] = useState([])
  const [atualGallery, setAtualGallery] = useState([])
  const [blogPosts, setBlogPosts] = useState([])
  const [blogPostsSelected, setBlogPostsSelected] = useState([])
  const [atualBlog, setAtualBlog] = useState('')
  const [modal_xlarge, setmodal_xlarge] = useState(false)
  const [modal_xlarge2, setmodal_xlarge2] = useState(false)
  const [propertiesList, setPropertiesList] = useState([])
  const [highOpportunityProducts, setHighOpportunityProducts] = useState([])
  const [highLaunchProducts, setHighLaunchProducts] = useState([])
  const [seachText, setSeachText] = useState('')

  const handleGalery = (data) => {
    setGallery(data);
  };

  useEffect(() => {
    fetch(process.env.REACT_APP_API_URL + '/page-home/1')
    .then(response => response.json())
    .then(async resp => {
      setHomePage(resp);
      setHightOpportunity(resp.high_opportunity)
      setHightLaunch(resp.high_launch)
      setBannerType(resp.banner_type)
      setAtualBlog(resp.high_posts)
      setHighOpportunityProducts(resp.highOpportunityProducts)
      setHighLaunchProducts(resp.highLaunchProducts)

        fetch(process.env.REACT_APP_API_URL + '/posts-no-paginate')
        .then(respons => respons.json())
        .then(async res => {
          const newPosts = []
          for(var i = 0; res.posts.length > i; i++){
            newPosts.push({
              label:res.posts[i].title,
              value:res.posts[i].id
            })
          }
          setBlogPosts(newPosts)

          if(resp.high_posts.split(',')[0]){
            setHightPosts1({
              value: parseInt(resp.high_posts.split(',')[0]),
              label:res.posts.filter(post => post.id === parseInt(resp.high_posts.split(',')[0]))[0].title
            })
          }
          if(resp.high_posts.split(',')[1]){
            setHightPosts2({
              value: parseInt(resp.high_posts.split(',')[1]),
              label:res.posts.filter(post => post.id === parseInt(resp.high_posts.split(',')[1]))[0].title
            })
          }
        })
    })

    fetch(process.env.REACT_APP_API_URL + '/gallery-photo/1')
    .then(response => response.json())
    .then(async resp => {
      setAtualGallery(resp)
    })

    fetch(process.env.REACT_APP_API_URL + '/posts-no-paginate')
    .then(response => response.json())
    .then(async resp => {
      const newPosts = []
      for(var i = 0; resp.posts.length > i; i++){
        newPosts.push({
          label:resp.posts[i].title,
          value:resp.posts[i].id
        })
      }
      setBlogPosts(newPosts)
    })

    fetch(process.env.REACT_APP_API_URL + '/all-properties-no-paginate',{
      headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
    })
    .then((res) => res.json())
    .then(response => {
      setPropertiesList(response.properties)
    })

}, [])

const updatePage = () => {
    document.getElementById('load').classList.add('active')

    const blogPostsSelectedString = hightPosts1.value + ',' + hightPosts2.value;
    const data = new FormData()

    data.append('high_posts', blogPostsSelectedString)
    data.append('high_opportunity', hightOpportunity)
    data.append('high_launch', highLaunch)
    data.append('banner_type', bannerType)

    fetch(process.env.REACT_APP_API_URL + '/update-page-home/1', {
      method: 'POST',
      body: data,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
    .then(response => response.json())
    .then(async resp => {

      const atualPhotosForm = new FormData()
      if(atualGallery && atualGallery.length > 0){
        for(let i = 0; i < atualGallery.length; i++){
          atualPhotosForm.append(`photos[${i}][position]`, atualGallery[i].position)
          atualPhotosForm.append(`photos[${i}][id]`, atualGallery[i].id)
        }
        setTimeout(() => {
          fetch(process.env.REACT_APP_API_URL + '/update-gallery-photo', {
            method: 'POST',
            body: atualPhotosForm,
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          })
        }, 300);
      }


      const photos = new FormData()
      if(gallery.selectedFiles && gallery.selectedFiles.length > 0){
        for(let i = 0; i < gallery.selectedFiles.length; i++){
          photos.append(`photo[${i}][file]`, gallery.selectedFiles[i].file)
          photos.append(`photo[${i}][position]`, gallery.selectedFiles[i].position)
          photos.append(`photo[${i}][gallery_id]`, 1)
        }

        setTimeout(() => {
          fetch(process.env.REACT_APP_API_URL + '/create-gallery-photo', {
            method: 'POST',
            body: photos,
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          }).then(resp =>  setTimeout(() => {
              window.location.reload()
          }, 100)).catch(resp => {
            document.getElementById('load').classList.remove('active')
          })
        }, 300);
        
      
      }else{
        document.getElementById('load').classList.remove('active')
      }
    
    })
    .catch(error => {
      console.error(error);
      document.getElementById('load').classList.remove('active')
    });  
}

  function tog_xlarge() {
    setmodal_xlarge(!modal_xlarge)
    removeBodyCss()
  }

  function tog_xlarge2() {
    setmodal_xlarge2(!modal_xlarge2)
    removeBodyCss()
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  const onSelect = (id) => {
    setHightOpportunity(high => high === null ? id : high + ',' + id)

    const blogPostsSelectedString = blogPostsSelected.join(',');

    const data = new FormData()

    data.append('high_posts', blogPostsSelectedString !== '' ? blogPostsSelectedString : atualBlog)
    data.append('high_opportunity', hightOpportunity === null ? id : hightOpportunity + ',' + id)
    data.append('high_launch', highLaunch)
    data.append('banner_type', bannerType)

    fetch(process.env.REACT_APP_API_URL + '/update-page-home/1', {
      method: 'POST',
      body: data,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    }).then(response => {
      fetch(process.env.REACT_APP_API_URL + '/page-home/1')
      .then(response => response.json())
      .then(async resp => {
        setHomePage(resp);
        setHightOpportunity(resp.high_opportunity)
        setHightLaunch(resp.high_launch)
        setBannerType(resp.banner_type)
        setAtualBlog(resp.high_posts)
        setHighOpportunityProducts(resp.highOpportunityProducts)
        setHighLaunchProducts(resp.highLaunchProducts)
      })
    })
  }

  const onSelect2 = (id) => {
    setHightLaunch(high => high === null ? id : high + ',' + id)

    const blogPostsSelectedString = blogPostsSelected.join(',');

    const data = new FormData()

    data.append('high_posts', blogPostsSelectedString !== '' ? blogPostsSelectedString : atualBlog)
    data.append('high_opportunity', hightOpportunity)
    data.append('high_launch', highLaunch === null ? id : highLaunch + ',' + id)
    data.append('banner_type', bannerType)

    fetch(process.env.REACT_APP_API_URL + '/update-page-home/1', {
      method: 'POST',
      body: data,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    }).then(response => {
      fetch(process.env.REACT_APP_API_URL + '/page-home/1')
      .then(response => response.json())
      .then(async resp => {
        setHomePage(resp);
        setHightOpportunity(resp.high_opportunity)
        setHightLaunch(resp.high_launch)
        setBannerType(resp.banner_type)
        setAtualBlog(resp.high_posts)
        setHighOpportunityProducts(resp.highOpportunityProducts)
        setHighLaunchProducts(resp.highLaunchProducts)
      })
    })
  }

  const onDelete = (id) => {
    setHightOpportunity(high => high === null ? id : high + ',' + id)

    const blogPostsSelectedString = blogPostsSelected.join(',');

    const data = new FormData()

    data.append('high_posts', blogPostsSelectedString !== '' ? blogPostsSelectedString : atualBlog)
    data.append('high_opportunity', hightOpportunity.replace(`${id.toString()}`, ''))
    data.append('high_launch', highLaunch)
    data.append('banner_type', bannerType)

    fetch(process.env.REACT_APP_API_URL + '/update-page-home/1', {
      method: 'POST',
      body: data,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    }).then(response => {
      fetch(process.env.REACT_APP_API_URL + '/page-home/1')
      .then(response => response.json())
      .then(async resp => {
        setHomePage(resp);
        setHightOpportunity(resp.high_opportunity)
        setHightLaunch(resp.high_launch)
        setBannerType(resp.banner_type)
        setAtualBlog(resp.high_posts)
        setHighOpportunityProducts(resp.highOpportunityProducts)
        setHighLaunchProducts(resp.highLaunchProducts)
      })
    })
  }

  const onDelete2 = (id) => {
    setHightLaunch(high => high === null ? id : high + ',' + id)

    const blogPostsSelectedString = blogPostsSelected.join(',');

    const data = new FormData()

    data.append('high_posts', blogPostsSelectedString !== '' ? blogPostsSelectedString : atualBlog)
    data.append('high_opportunity', hightOpportunity)
    data.append('high_launch', highLaunch.replace(`${id.toString()}`, ''))
    data.append('banner_type', bannerType)

    fetch(process.env.REACT_APP_API_URL + '/update-page-home/1', {
      method: 'POST',
      body: data,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    }).then(response => {
      fetch(process.env.REACT_APP_API_URL + '/page-home/1')
      .then(response => response.json())
      .then(async resp => {
        setHomePage(resp);
        setHightOpportunity(resp.high_opportunity)
        setHightLaunch(resp.high_launch)
        setBannerType(resp.banner_type)
        setAtualBlog(resp.high_posts)
        setHighOpportunityProducts(resp.highOpportunityProducts)
        setHighLaunchProducts(resp.highLaunchProducts)
      })
    })
  }

  useEffect(() => {
    console.log(blogPostsSelected)
  },[blogPostsSelected])

    return(
    <React.Fragment>
        <div className="page-content">
            <Breadcrumbs title="Páginas" breadcrumbItem="Home" />
            <Row>
            <Col lg="12" md="12">
                <Card>
                <CardBody>
                  <InputContainer>
                    <Label style={{marginRight:'10px'}}>Oportunidades Imóveis</Label><Button color="primary" onClick={tog_xlarge}>Adicionar oportunidade</Button>
                    <PropertiesList>
                      {highOpportunityProducts?.map((property, index) => (
                        <ImmobileSelectedCard onDelete={onDelete} key={index} data={property} />
                      ))}
                    </PropertiesList>
                  </InputContainer>

                  <Divider></Divider>

                  <InputContainer>
                    <Label style={{marginRight:'10px'}}>Lançamentos Imóveis</Label><Button color="primary" onClick={tog_xlarge2}>Adicionar lançamento</Button>
                    <PropertiesList>
                      {highLaunchProducts?.map((property, index) => (
                        <ImmobileSelectedCard onDelete={onDelete2} key={index} data={property} />
                      ))}
                    </PropertiesList>
                  </InputContainer>

                  <Divider></Divider>

                  <InputContainer>
                    <Label>Destaques Blog</Label>
                    <Row>
                      <Col md={6}>
                        <Select
                          value={hightPosts1}
                          placeholder="Selecione"
                          noOptionsMessage={(inputValue) => `Sem resultados`}
                          onChange={(e) => {
                            setBlogPostsSelected(prevState => [...prevState, e.value])
                            setHightPosts1(e)
                            console.log(e)
                          }}
                          options={blogPosts}
                          classNamePrefix="select2-selection"
                        />
                      </Col>
                      <Col md={6}>
                        <Select
                          value={hightPosts2}
                          placeholder="Selecione"
                          noOptionsMessage={(inputValue) => `Sem resultados`}
                          onChange={(e) => {
                            setBlogPostsSelected(prevState => [...prevState, e.value])
                            setHightPosts2(e)
                            console.log(e)
                          }}
                          options={blogPosts}
                          classNamePrefix="select2-selection"
                        />
                      </Col>
                    </Row>
                  </InputContainer>

                  <Divider></Divider>

                  <Row>
                    <Col md={4}>
                      <InputContainer>
                        <Label>Tipo de banner</Label>
                        <Input
                          value={bannerType}
                          type="select"
                          onChange={(e) => setBannerType(e.target.value)}
                        >
                          <option value="video">Vídeo</option>
                          <option value="slides">Slides</option>
                        </Input>
                      </InputContainer>
                    </Col>
                  </Row>

                  {bannerType === 'slides' &&
                    <InputContainer>
                      <Label>Galeria de imagens</Label>
                      <Gallery
                          handleChildStep5={handleGalery}
                          title={''}
                          url={''}
                          send={sendProp}
                          gallery={atualGallery}
                      />
                    </InputContainer>
                  }

                  <ButtonContainer>
                    <Button onClick={updatePage}>Salvar</Button>
                  </ButtonContainer>

                </CardBody>
                </Card>
            </Col>
            </Row>
        </div>
        <Load id="load">
          <Spinner className="me-2" color="success" />
        </Load>

        <Modal
          size="xl"
          isOpen={modal_xlarge}
          toggle={() => {
            tog_xlarge()
          }}
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="myExtraLargeModalLabel">
              Adicionar imóvel: Oportunidade</h5>
            <button
              onClick={() => {
                setmodal_xlarge(false)
              }}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <div>
              <input
                type="text"
                onChange={(e) => setSeachText(e.target.value)}
                value={seachText}
                placeholder="Busque por nome"
                style={{
                  border:'solid 1px #cdcdcd',
                  borderRadius:'5px',
                  height:'30px'
                }}
              />
            </div>
            <PropertiesList>
              {propertiesList?.filter(props => props.name.toLowerCase().includes(seachText.toLowerCase())).filter(properties  => properties.status == 1).map((property, index) => (
                <div key={index} onClick={() => {
                  onSelect(property.id)
                  tog_xlarge()
                }}>
                  <ImmobileSelectCard data={property} />
                </div>
              ))}
            </PropertiesList>
          </div>
        </Modal>

        <Modal
          size="xl"
          isOpen={modal_xlarge2}
          toggle={() => {
            tog_xlarge2()
          }}
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="myExtraLargeModalLabel">
              Adicionar imóvel: Lançamento</h5>
            <button
              onClick={() => {
                setmodal_xlarge2(false)
              }}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <div>
              <input
                type="text"
                onChange={(e) => setSeachText(e.target.value)}
                value={seachText}
                placeholder="Busque por nome"
                style={{
                  border:'solid 1px #cdcdcd',
                  borderRadius:'5px',
                  height:'30px'
                }}
              />
            </div>
            <PropertiesList>
              {propertiesList?.filter(props => props.name.toLowerCase().includes(seachText.toLowerCase())).filter(properties  => properties.status == 1).map((property, index) => (
                <div key={index} onClick={() => {
                  onSelect2(property.id)
                  tog_xlarge2()
                }}>
                  <ImmobileSelectCard data={property} />
                </div>
              ))}
            </PropertiesList>
          </div>
        </Modal>

    </React.Fragment>
    )
}

export default PageHome;

const Divider = styled.span`
  width:100%;
  height:1px;
  background-color:#cdcdcd;
  display:block;
  margin-top:30px;
`;

const PropertiesList = styled.div`
  display:grid;
  margin:30px 0;
  padding:20px;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap:20px;
`;

const ButtonContainer = styled.div`
  text-align:right;
`;

const InputContainer = styled.div`
  margin-top:20px;
`;

const Load = styled.div`
  display:none;

  &.active{
    display:flex;
    position: fixed;
    top:0;
    bottom:0;
    left:0;
    right:0;
    background-color: rgba(0,0,0,0.5);
    align-items: center;
    justify-content: center;
    z-index:99999999999999;
  }
`;

const AtualImage = styled.div`
  width:200px;
  height:200px;
  background-repeat:no-repeat;
  background-size:contain;
`;