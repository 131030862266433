import { useState, useEffect } from 'react';
import './style.scss';
import {
  Col,
  Form,
  Label,
  Button,
  Input,
  Row
} from "reactstrap";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};


const Step3 = ({ handleChildStep3 }) => {

  const [valueImobile, setValueImobile] = useState('');
  const [detailsImobile, setDetailsImobile] = useState([]);
  const [skillsImobile, setSkillsImobile] = useState([]);
  const [skillValueImobile, setSkillValueImobile] = useState([]);

  const dataToSend = {
    skillsImobile,
    detailsImobile
  };

  useEffect(() => {
    handleChildStep3(dataToSend);
  }, [
    detailsImobile, skillsImobile])

    /* PRINCIPAIS */
  const handleChangeImobile = (event) => {
    setValueImobile(event.target.value);
  };

  const loadDetailsImobile = () => {
    if (valueImobile.trim() !== '') {
      const lines = valueImobile.split('\n');
      
      lines.forEach((line) => {
        if (line.trim() !== '') {
          setDetailsImobile((prevDetails) => [
            ...prevDetails,
            { id: prevDetails.length + 1, details: line, position: (prevDetails.length + 1).toString() },
          ]);
        }
      });
  
      setValueImobile('');
    }
  };

  const removeDetailImobile = (id) => { 
    setDetailsImobile(detailsImobile.filter(detail => detail.id !== id));
  };

  const onDragEndImobile = (result) => {
    if (!result.destination) return;
  
    const updatedDetails = reorder(
      detailsImobile,
      result.source.index,
      result.destination.index
    ).map((item, index) => ({
      ...item,
      position: (index + 1).toString(),
    }));
  
    setDetailsImobile(updatedDetails);
  };

      /* OUTRAS */
      const handleChangeSkill = (event) => {
        setSkillValueImobile(event.target.value);
      };
    
      const loadDetailsSkill = () => {
        if (skillValueImobile.trim() !== '') {
          const lines = skillValueImobile.split('\n');
          
          lines.forEach((line) => {
            if (line.trim() !== '') {
              setSkillsImobile((prevDetails) => [
                ...prevDetails,
                { id: prevDetails.length + 1, details: line, position: (prevDetails.length + 1).toString() },
              ]);
            }
          });
      
          setSkillValueImobile('');
        }
      };
    
      const removeDetailSkill = (id) => { 
        setSkillsImobile(skillsImobile.filter(detail => detail.id !== id));
      };
    
      const onDragEndSkill = (result) => {
        if (!result.destination) return;
      
        const updatedDetails = reorder(
          detailsImobile,
          result.source.index,
          result.destination.index
        ).map((item, index) => ({
          ...item,
          position: (index + 1).toString(),
        }));
      
        setSkillsImobile(updatedDetails);
      };

  return(
    <div>
        <Form>
          <Row>
            <h5 className="title-description">Características principais</h5>
            <small>ex.: 4 suítes (sendo 1 master), 3 a 4 vagas de garagem (max. 4)</small>
            <Row>
            <Col md={6}>
              <Row className="mb-3">
                <Label htmlFor="txtNameCard" className="col-lg-4 col-form-label title">Características principais</Label>
                <div className="col-lg-12">
                  <Input 
                    id="txtNameCard" 
                    name="txtNameCard" 
                    type="textarea" 
                    rows={6}
                    placeholder="Utilize o enter para separar as características"
                    className="form-control"
                    value={skillValueImobile}
                    onChange={handleChangeSkill}
                  />
                </div>
                <div className='add-button'>
                  <Button onClick={loadDetailsSkill}>Adicionar</Button>
                </div>
              </Row>
            </Col>
            <Col md={6} style={{marginTop:'40px'}}>
            <DragDropContext onDragEnd={onDragEndSkill}>
              <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    className='container-drag'
                    style={{ backgroundColor: snapshot.isDraggingOver ? 'lightblue' : 'lightgrey' }}
                  >
                    {skillsImobile.map((item, index) => (
                      <Draggable key={item.id} draggableId={item.id.toString()} index={index}>
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className='content-drag'
                            style={{
                              userSelect: 'none',
                              padding: '16px',
                              margin: '4px 0 4px 0',
                              minHeight: '50px',
                              backgroundColor: snapshot.isDragging ? '#3E5E3F' : '#648465',
                              color: 'white',
                              ...provided.draggableProps.style 
                            }}
                          >
                            {item.details}
                            <Button color="danger" size="sm" style={{float: 'right'}} onClick={() => removeDetailSkill(item.id)}>Excluir</Button>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            </Col>
          </Row>
          </Row>


          <div style={{margin: '1rem auto 3rem auto' }}>
        <h5>Outras características</h5>
        <small>ex.: Piscina, Salão de festas, Playground</small>
        <Form>
          <Row>
            <Col md={6}>
              <Row className="mb-3">
                <Label htmlFor="txtNameCard" className="col-lg-4 col-form-label title">Outras características</Label>
                <div className="col-lg-12">
                  <Input 
                    id="txtNameCard" 
                    name="txtNameCard" 
                    type="textarea" 
                    rows={6}
                    placeholder="Utilize o enter para separar as características"
                    className="form-control"
                    value={valueImobile}
                    onChange={handleChangeImobile}
                  />
                </div>
                <div className='add-button'>
                  <Button onClick={loadDetailsImobile}>Adicionar</Button>
                </div>
              </Row>
            </Col>
            <Col md={6} style={{marginTop:'40px'}}>
            <DragDropContext onDragEnd={onDragEndImobile}>
              <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    className='container-drag'
                    style={{ backgroundColor: snapshot.isDraggingOver ? 'lightblue' : 'lightgrey' }}
                  >
                    {detailsImobile.map((item, index) => (
                      <Draggable key={item.id} draggableId={item.id.toString()} index={index}>
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className='content-drag'
                            style={{
                              userSelect: 'none',
                              padding: '16px',
                              margin: '4px 0 4px 0',
                              minHeight: '50px',
                              backgroundColor: snapshot.isDragging ? '#3E5E3F' : '#648465',
                              color: 'white',
                              ...provided.draggableProps.style 
                            }}
                          >
                            {item.details}
                            <Button color="danger" size="sm" style={{float: 'right'}} onClick={() => removeDetailImobile(item.id)}>Excluir</Button>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            </Col>
          </Row>
        </Form>
      </div>
        </Form>
    </div>
  )
}

export default Step3