import React, { useState, useEffect } from "react"
// import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { Draggable } from 'react-drag-reorder';
import { Card, Row, Col, Form, Button, Input } from "reactstrap"
import Dropzone from "react-dropzone"
import './style.scss'
import { Link } from "react-router-dom"
import {AiOutlineClose} from 'react-icons/ai'
import { ImageDragCard } from "./imageCard";


const Step5 = ({ handleChildStep5, send, title, url }) => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [photosArray, setPhotosArray] = useState([]);
  const [show, setShow] = useState(false);

  const handleAcceptedFiles = (files) => {
    console.log(files)
    files = files.map((file, index) => ({
      ...file,
      preview: URL.createObjectURL(file),
      formattedSize: formatBytes(file.size),
      file: file,
      title: title,
      url: url,
      position: index
    }));

    setSelectedFiles(files);
    setPhotosArray(files);
  };

  const dataToSend = {
    selectedFiles
  };

  useEffect(() => {
    handleChildStep5(dataToSend);
    setShow(selectedFiles.length > 0)
    setSelectedFiles(photosArray)
  }, [selectedFiles, photosArray])


  const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  const handleTitleChange = (index, newTitle) => {
    setPhotosArray((prevPhotosArray) => {
      const updatedPhotosArray = [...prevPhotosArray];
      updatedPhotosArray[index].title = newTitle;
      return updatedPhotosArray;
    });
  };
  
  const handleUrlChange = (index, newUrl) => {
    setPhotosArray((prevPhotosArray) => {
      const updatedPhotosArray = [...prevPhotosArray];
      updatedPhotosArray[index].url = newUrl;
      return updatedPhotosArray;
    });
  };

  const handlePositionChange = (index, newPosition) => {
    setPhotosArray((prevPhotosArray) => {
      const updatedPhotosArray = [...prevPhotosArray];
      updatedPhotosArray[index].position = newPosition;
      return updatedPhotosArray;
    });
  };

  const handleRemoveImage = (path) => {
    const indexToRemove = photosArray.findIndex(image => image.path === path);

    if (indexToRemove !== -1) {
      const updatedImages = [...photosArray];
      updatedImages.splice(indexToRemove, 1);
      setSelectedFiles(updatedImages);
      setPhotosArray(updatedImages);
    }
  };


  return (
    <div>
        <Form>
          <Dropzone
            accept=".png, .jpeg, .jpg, .heic"
            onDrop={acceptedFiles => handleAcceptedFiles(acceptedFiles)}
          >
            {({ getRootProps, getInputProps }) => (
              <div className="dropzone" {...getRootProps()}>
                <div
                  className="dz-message needsclick"
                  {...getRootProps()}
                >
                  <input {...getInputProps()} />
                  <div className="mb-3">
                    <i className="display-4 text-muted ri-upload-cloud-2-line"></i>
                  </div>
                  <h4>Para adicionar fotos arraste ou clique aqui.</h4>
                </div>
              </div>
            )}
          </Dropzone>
              <div
                className="dropzone-previews mt-3"
                id="file-previews"
              >
                {photosArray.map((image, index) => (
                  <ImageDragCard
                    key={index}
                    image={image}
                    index={index}
                    onTitleChange={handleTitleChange}
                    onUrlChange={handleUrlChange}
                    onPositionChange={handlePositionChange}
                    onRemove={() => handleRemoveImage(image.path)}
                  />
                  ))}
          </div>
        </Form>
    </div>
  )
}

export default Step5