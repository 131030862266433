import {
  Button,
    Card,
    CardBody,
    Col,
    Input,
    Label,
    Row,
    Spinner,
  } from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb";
import styled from 'styled-components';
import React, { useEffect, useState } from "react";
import Dropzone from "react-dropzone";
import Gallery from "./gallery";


const PageSobre = () => {

  const [aboutPage, setAboutPage] = useState([])

  const [title, setTitle] = useState('')
  const [subTitle, setSubTitle] = useState('')
  const [text1, setText1] = useState('')
  const [text2, setText2] = useState('')
  const [text3, setText3] = useState('')
  const [text4, setText4] = useState('')
  const [text5, setText5] = useState('')
  const [background, setBackground] = useState('')
  const [primaryImage, setPrimaryImage] = useState('')
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedBGFile, setSelectedBGFile] = useState(null);
  const [sendProp, setSendPro] = useState([])
  const [gallery, setGallery] = useState([])
  const [atualGallery, setAtualGallery] = useState([])

  const handleGalery = (data) => {
    setGallery(data);
    console.log(data)
  };

  useEffect(() => {
    fetch(process.env.REACT_APP_API_URL + '/page-about/1')
    .then(response => response.json())
    .then(async resp => {
      setAboutPage(resp);
      setTitle(resp.title)
      setSubTitle(resp.sub_title)
      setText1(resp.text1)
      setText2(resp.text2)
      setText3(resp.text3)
      setText4(resp.text4)
      setText5(resp.text5)
      setPrimaryImage(resp.primary_image)
      setBackground(resp.background)
    })

    fetch(process.env.REACT_APP_API_URL + '/gallery-photo/2')
    .then(response => response.json())
    .then(async resp => {
      setAtualGallery(resp)
    })
}, [])


const handleAcceptedFiles = (files) => {
  // Aceitar apenas um arquivo
  const file = files[0];
  if (file) {
    setSelectedFile({
      ...file,
      preview: URL.createObjectURL(file),
      name: file.name,
      formattedSize: formatBytes(file.size),
      file:file
    });
  }
};

const handleAcceptedBGFiles = (files) => {
  // Aceitar apenas um arquivo
  const file = files[0];
  if (file) {
    setSelectedBGFile({
      ...file,
      preview: URL.createObjectURL(file),
      name: file.name,
      formattedSize: formatBytes(file.size),
      file:file
    });
  }
};

const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return "0 Bytes"
  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
  const i = Math.floor(Math.log(bytes) / Math.log(k))
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
}

const updatePage = () => {
    document.getElementById('load').classList.add('active')

    const data = new FormData()

    data.append('title', title)
    data.append('sub_title', subTitle)
    data.append('text1', text1)
    data.append('text2', text2)
    data.append('text3', text3)
    data.append('text4', text4)
    data.append('text5', text5)
    data.append('primary_image', selectedFile?.file)
    data.append('background', selectedBGFile?.file)

    fetch(process.env.REACT_APP_API_URL + '/update-page-about/1', {
      method: 'POST',
      body: data,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
    .then(response => response.json())
    .then(async resp => {

      const atualPhotosForm = new FormData()
      if(atualGallery && atualGallery.length > 0){
        for(let i = 0; i < atualGallery.length; i++){
          atualPhotosForm.append(`photos[${i}][position]`, atualGallery[i].position)
          atualPhotosForm.append(`photos[${i}][id]`, atualGallery[i].id)
        }
        setTimeout(() => {
          fetch(process.env.REACT_APP_API_URL + '/update-gallery-photo', {
            method: 'POST',
            body: atualPhotosForm,
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          })
        }, 300);
      }


      const photos = new FormData()
      if(gallery.selectedFiles && gallery.selectedFiles.length > 0){
        for(let i = 0; i < gallery.selectedFiles.length; i++){
          photos.append(`photo[${i}][file]`, gallery.selectedFiles[i].file)
          photos.append(`photo[${i}][position]`, gallery.selectedFiles[i].position)
          photos.append(`photo[${i}][gallery_id]`, 2)
        }

        setTimeout(() => {
          fetch(process.env.REACT_APP_API_URL + '/create-gallery-photo', {
            method: 'POST',
            body: photos,
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          }).then(resp =>  setTimeout(() => {
              window.location.reload()
          }, 100)).catch(resp => {
            document.getElementById('load').classList.remove('active')
          })
        }, 300);
        
      
      }else{
        document.getElementById('load').classList.remove('active')
      }
    
    })
    .catch(error => {
      console.error(error);
      document.getElementById('load').classList.remove('active')
    });  
}



    return(
    <React.Fragment>
        <div className="page-content">
            <Breadcrumbs title="Páginas" breadcrumbItem="Sobre a Sort" />
            <Row>
            <Col lg="12" md="12">
                <Card>
                <CardBody>
                <Row>
                  <Col md={4}>
                    <InputContainer>
                      <Label>Título da página</Label>
                      <Input
                        value={title}
                        type="text"
                        onChange={(e) => setTitle(e.target.value)}
                      ></Input>
                    </InputContainer>
                  </Col>
                  <Col md={8}>
                    <InputContainer>
                      <Label>Título do texto</Label>
                      <Input
                        value={subTitle}
                        type="text"
                        onChange={(e) => setSubTitle(e.target.value)}
                      ></Input>
                    </InputContainer>
                  </Col>
                </Row>

                <Divider></Divider>
                
                  <InputContainer>
                    <Label>1º Parágrafo</Label>
                    <Input
                      value={text1}
                      type="textarea"
                      rows="4"
                      onChange={(e) => setText1(e.target.value)}
                    ></Input>
                  </InputContainer>
                  <InputContainer>
                    <Label>2º Parágrafo</Label>
                    <Input
                      value={text2}
                      type="textarea"
                      rows="4"
                      onChange={(e) => setText2(e.target.value)}
                    ></Input>
                  </InputContainer>
                  <InputContainer>
                    <Label>3º Parágrafo</Label>
                    <Input
                      value={text3}
                      type="textarea"
                      rows="4"
                      onChange={(e) => setText3(e.target.value)}
                    ></Input>
                  </InputContainer>
                  <InputContainer>
                    <Label>4º Parágrafo</Label>
                    <Input
                      value={text4}
                      type="textarea"
                      rows="4"
                      onChange={(e) => setText4(e.target.value)}
                    ></Input>
                  </InputContainer>
                  <InputContainer>
                    <Label>5º Parágrafo</Label>
                    <Input
                      value={text5}
                      type="textarea"
                      rows="4"
                      onChange={(e) => setText5(e.target.value)}
                    ></Input>
                  </InputContainer>

                  <Divider></Divider>

                  <InputContainer>
                  <Label>Imagem destaque</Label>
                  <Row style={{margin:'30px 0'}}>
                    <Col md={6}>
                      <Dropzone
                        accept=".png, .jpeg, .jpg, .heic"
                        onDrop={acceptedFiles => handleAcceptedFiles(acceptedFiles)}
                        // Limitar a 1 arquivo
                        maxFiles={1}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div className="dropzone" {...getRootProps()}>
                            <div className="dz-message needsclick" {...getRootProps()}>
                              <input {...getInputProps()} />
                              <div className="mb-3">
                                <i className="display-4 text-muted ri-upload-cloud-2-line"></i>
                              </div>
                              <h4>Para adicionar uma imagem, arraste ou clique aqui.</h4>
                            </div>
                          </div>
                        )}
                      </Dropzone>
                    </Col>
                    <Col md={6}>
                      <div className="dropzone-previews mt-3" id="file-previews">
                        {selectedFile ? (
                          <div className="p-2">
                            <Row className="align-items-center">
                              <Col className="col-auto">
                                <img
                                  data-dz-thumbnail=""
                                  height="220"
                                  className="avatar-xl rounded bg-light image-send"
                                  style={{ objectFit: "cover" }}
                                  src={selectedFile.preview}
                                />
                              </Col>
                              <Col>
                                <h5 className="text-muted fw-bold">
                                  {selectedFile.name}
                                </h5>
                                <p className="mb-0">
                                  <strong>{selectedFile.formattedSize}</strong>
                                </p>
                              </Col>
                            </Row>
                          </div>
                        ):(
                          <AtualImage style={{backgroundImage: `url('${primaryImage}')`}}></AtualImage>
                        )}
                      </div>
                    </Col>
                  </Row>
                  </InputContainer>

                  <Divider></Divider>

                  <InputContainer>
                  <Label>Plano de fundo</Label>
                  <Row style={{margin:'30px 0'}}>
                    <Col md={6}>
                      <Dropzone
                        accept=".png, .jpeg, .jpg, .heic"
                        onDrop={acceptedFiles => handleAcceptedBGFiles(acceptedFiles)}
                        // Limitar a 1 arquivo
                        maxFiles={1}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div className="dropzone" {...getRootProps()}>
                            <div className="dz-message needsclick" {...getRootProps()}>
                              <input {...getInputProps()} />
                              <div className="mb-3">
                                <i className="display-4 text-muted ri-upload-cloud-2-line"></i>
                              </div>
                              <h4>Para adicionar uma imagem, arraste ou clique aqui.</h4>
                            </div>
                          </div>
                        )}
                      </Dropzone>
                    </Col>
                    <Col md={6}>
                      <div className="dropzone-previews mt-3" id="file-previews">
                        {selectedBGFile ? (
                          <div className="p-2">
                            <Row className="align-items-center">
                              <Col className="col-auto">
                                <img
                                  data-dz-thumbnail=""
                                  height="220"
                                  className="avatar-xl rounded bg-light image-send"
                                  style={{ objectFit: "cover" }}
                                  src={selectedBGFile.preview}
                                />
                              </Col>
                              <Col>
                                <h5 className="text-muted fw-bold">
                                  {selectedBGFile.name}
                                </h5>
                                <p className="mb-0">
                                  <strong>{selectedBGFile.formattedSize}</strong>
                                </p>
                              </Col>
                            </Row>
                          </div>
                        ):(
                          <AtualImage style={{backgroundImage: `url('${background}')`}}></AtualImage>
                        )}
                      </div>
                    </Col>
                  </Row>
                  </InputContainer>

                  <Divider></Divider>

                  <InputContainer>
                    <Label>Galeria de imagens</Label>
                    <Gallery
                        handleChildStep5={handleGalery}
                        title={''}
                        url={''}
                        send={sendProp}
                        gallery={atualGallery}
                    />
                  </InputContainer>

                  <ButtonContainer>
                    <Button onClick={updatePage}>Salvar</Button>
                  </ButtonContainer>

                </CardBody>
                </Card>
            </Col>
            </Row>
        </div>
        <Load id="load">
          <Spinner className="me-2" color="success" />
        </Load>
    </React.Fragment>
    )
}

export default PageSobre;

const ButtonContainer = styled.div`
  text-align:right;
`;

const InputContainer = styled.div`
  margin-top:20px;
`;

const Load = styled.div`
  display:none;

  &.active{
    display:flex;
    position: fixed;
    top:0;
    bottom:0;
    left:0;
    right:0;
    background-color: rgba(0,0,0,0.5);
    align-items: center;
    justify-content: center;
    z-index:99999999999999;
  }
`;

const AtualImage = styled.div`
  width:200px;
  height:200px;
  background-repeat:no-repeat;
  background-size:contain;
`;

const Divider = styled.span`
  width:100%;
  height:1px;
  background-color:#cdcdcd;
  display:block;
  margin-top:30px;
`;