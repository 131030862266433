import {
  Button,
    Card,
    CardBody,
    Col,
    Input,
    Label,
    Row,
    Spinner,
  } from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb";
import styled from 'styled-components';
import React, { useEffect, useState } from "react";


const Appreciation = () => {

  const [cityList, setCityList] = useState([])
  const [inputValues, setInputValues] = useState({})

  useEffect(() => {
      fetch(process.env.REACT_APP_API_URL + '/products/cities/sell')
        .then(response => response.json())
        .then(async resp => {
          const initialValues = resp.map(city => ({
            city: decodeURIComponent(city),
            sort_tax: '',
            fipe_tax: '',
          }));
          setCityList(resp);
          setInputValues(initialValues);
        })
        .catch(error => {
          console.error(error);
        });
      
      fetch(process.env.REACT_APP_API_URL + '/appreciation')
        .then(response => response.json())
        .then(async resp => {
          setInputValues(prevValues => {
            if (!Array.isArray(prevValues)) {
              console.error("prevValues is not an array:", prevValues);
              return prevValues;
            }
        
            const updatedValues = prevValues.map(prevValue => {
              const matchingCity = resp.appreciations.find(appreciation => {
                return decodeURIComponent(appreciation.city) === prevValue.city;
              });
        
              if (matchingCity) {
                return {
                  ...prevValue,
                  sort_tax: matchingCity.sort_tax,
                  fipe_tax: matchingCity.fipe_tax,
                };
              }
        
              return prevValue;
            });
        
            return updatedValues;
          });
        })
        .catch(error => {
          console.error(error);
        });    
  }, [])

  const handleInputChange = (index, field, value) => {
    setInputValues(prevValues => {
      const newValues = [...prevValues];
      newValues[index] = {
        ...newValues[index],
        [field]: value,
      };
      return newValues;
    });
  };

  const handleSubmit = () => {
      document.getElementById('load').classList.add('active');

      fetch(process.env.REACT_APP_API_URL + '/appreciation-update', {
        method: 'POST',
        body: JSON.stringify(inputValues),
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
      })
      .then(response => response.json())
      .then(async resp => {
        setTimeout(() => {
          window.location.reload()
        }, 300);
        
      })
        .catch(error => {
          console.error(error);
        });  
  };

    return(
    <React.Fragment>
        <div className="page-content">
            <Breadcrumbs title="Taxas de valorização" breadcrumbItem="Gerenciar taxas" />
            <Row>
            <Col lg="8" md="10">
                <Card>
                <CardBody>
                    <TitleContainer>
                      <h4 className="card-title">Gerenciar taxas</h4>
                      <Button onClick={handleSubmit}>Salvar</Button>
                    </TitleContainer>
                    <CityContainer>
                        <p>Cidade</p>
                        <p>Indice FipeZap</p>
                        <p>Indice Sort</p>
                    </CityContainer>
                    {cityList
                    .slice()
                    .sort((a, b) => a.localeCompare(b))
                    .map((city, index) => {
                      const inputValue = inputValues[index] || {};
                  
                      return (
                        <CityContainer key={index}>
                          <Label>{city}</Label>
                          <Input
                            type="number"
                            value={inputValue.fipe_tax !== null && inputValue.fipe_tax !== undefined ? inputValue.fipe_tax : ''}
                            onChange={e => handleInputChange(index, 'fipe_tax', e.target.value)}
                          ></Input>
                          <Input
                            type="number"
                            value={inputValue.sort_tax !== null && inputValue.sort_tax !== undefined ? inputValue.sort_tax : ''}
                            onChange={e => handleInputChange(index, 'sort_tax', e.target.value)}
                          ></Input>
                        </CityContainer>
                      );
                  })}
                </CardBody>
                </Card>
            </Col>
            </Row>
        </div>
        <Load id="load">
          <Spinner className="me-2" color="success" />
        </Load>
    </React.Fragment>
    )
}

export default Appreciation;

const TitleContainer = styled.div`
  display:flex;
  justify-content:space-between;
  align-items:center;
`;

const Load = styled.div`
  display:none;

  &.active{
    display:flex;
    position: fixed;
    top:0;
    bottom:0;
    left:0;
    right:0;
    background-color: rgba(0,0,0,0.5);
    align-items: center;
    justify-content: center;
    z-index:99999999999999;
  }
`;

const CityContainer = styled.div`
  display:flex;
  gap:20px;
  flex-wrap:wrap;
  align-items:center;
  margin:20px 0;

  & label{
    flex:1;
    text-align:right;
  }

  & input{
    flex:1;
  }

  & p{
    flex:1;
    text-align:center;
    font-weight:700;
    text-transform:uppercase;
    margin-bottom:-10px;
  }
`;