import { useState, useEffect } from 'react';
import PasswordChecklist from "react-password-checklist"
import './style.scss';
import {
  Col,
  Form,
  Input,
  Row
} from "reactstrap"
import styled from 'styled-components';
import Dropzone from 'react-dropzone';
import { Link } from 'react-router-dom/cjs/react-router-dom';

const Step1 = ({ handleChildStep1 }) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [permission, setPermission] = useState('');
  const [profileImage, setProfileImage] = useState(null);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [truePassword, setTruePassword] = useState(false)

  const dataToSend = {
    name,
    email,
    permission,
    profileImage,
    password,
    truePassword,
  };

  useEffect(() => {
    handleChildStep1(dataToSend);
  }, [name,
    email,
    permission,
    profileImage,
    truePassword,
    password,]);

    const handleAcceptedFiles = (files) => {
      // Aceitar apenas um arquivo
      const file = files[0];
      if (file) {
        setProfileImage({
          ...file,
          preview: URL.createObjectURL(file),
          name: file.name,
          formattedSize: formatBytes(file.size),
          file:file
        });
      }
    };

    const formatBytes = (bytes, decimals = 2) => {
      if (bytes === 0) return "0 Bytes"
      const k = 1024
      const dm = decimals < 0 ? 0 : decimals
      const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
      const i = Math.floor(Math.log(bytes) / Math.log(k))
      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
    }


  return(
    <div>
      <Form>
        <Row>
          <h5 className="title-proprietario">Sobre o usuário</h5>
          <Row>
            <Col md={4}>
              <Row className="mb-3">
                <label htmlFor="txtFirstNameBilling"
                  className="col-lg-3 col-form-label">Nome</label>
                <div className="col-lg-9">
                  <Input 
                    id="txtFirstNameBilling" 
                    name="txtFirstNameBilling" 
                    placeholder="Nome"
                    type="text" 
                    className="form-control" 
                    value={name}
                    onChange={e => setName(e.target.value)}
                  />
                </div>
              </Row>
            </Col>
            <Col md={4}>
              <Row className="mb-3">
                <label htmlFor="txtLastNameBilling"
                  className="col-lg-3 col-form-label">Email</label>
                <div className="col-lg-9">
                  <Input 
                    id="txtLastNameBilling" 
                    name="txtLastNameBilling" 
                    placeholder="Email"
                    type="text" 
                    className="form-control"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                  />
                </div>
              </Row>
            </Col>
            <Col md={4}>
              <Row className="mb-3">
                <label htmlFor="txtAddress1Billing"
                  className="col-lg-4 col-form-label">Permissão</label>
                <div className="col-lg-7">
                  <Input 
                    type="select" 
                    id="ddlCreditCardType" 
                    name="ddlCreditCardType"
                    className="form-select"
                    value={permission}
                    onChange={e => setPermission(e.target.value)}
                  >
                    <option value="">Selecione a permissão</option>
                    <option value='Admin'>Admin</option>
                  </Input>
                </div>
              </Row>
            </Col>
          </Row>
          <Row>
            <h5 className="title-imovel-endereco">Imagem de perfil</h5>
            <Row style={{margin:'30px 0'}}>
            <Col md={6}>
                <Dropzone
                accept=".png, .jpeg, .jpg, .heic"
                onDrop={acceptedFiles => handleAcceptedFiles(acceptedFiles)}
                // Limitar a 1 arquivo
                maxFiles={1}
              >
                {({ getRootProps, getInputProps }) => (
                  <div className="dropzone" {...getRootProps()}>
                    <div className="dz-message needsclick" {...getRootProps()}>
                      <input {...getInputProps()} />
                      <div className="mb-3">
                        <i className="display-4 text-muted ri-upload-cloud-2-line"></i>
                      </div>
                      <h4>Para adicionar uma imagem, arraste ou clique aqui.</h4>
                    </div>
                  </div>
                )}
              </Dropzone>
            </Col>
            <Col md={6}>
              <div className="dropzone-previews mt-3" id="file-previews">
                {profileImage && (
                  <div className="p-2">
                    <Row className="align-items-center">
                      <Col className="col-auto">
                        <img
                          data-dz-thumbnail=""
                          height="220"
                          className="avatar-xl rounded bg-light image-send"
                          style={{ objectFit: "cover" }}
                          src={profileImage.preview}
                        />
                      </Col>
                      <Col>
                        <Link to="#" className="text-muted fw-bold">
                          {profileImage.name}
                        </Link>
                        <p className="mb-0">
                          <strong>{profileImage.formattedSize}</strong>
                        </p>
                      </Col>
                    </Row>
                  </div>
                )}
              </div>
            </Col>
            </Row>
          </Row>
          <h5 className="title-proprietario">Senha de acesso</h5>
          <Row>
            <Col md={7}>
              <Row className="mb-3">
                <label htmlFor="txtFirstNameBilling"
                  className="col-lg-3 col-form-label">Senha</label>
                <div className="col-lg-9">
                  <Input 
                    id="txtFirstNameBilling" 
                    name="txtFirstNameBilling" 
                    type="password" 
                    className="form-control" 
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                  />
                </div>
              </Row>
              <Row className="mb-3">
                <label htmlFor="txtFirstNameBilling"
                  className="col-lg-3 col-form-label">Confirmação de senha</label>
                <div className="col-lg-9">
                  <Input 
                    id="txtFirstNameBilling" 
                    name="txtFirstNameBilling" 
                    type="password" 
                    className="form-control" 
                    value={confirmPassword}
                    onChange={e => setConfirmPassword(e.target.value)}
                  />
                </div>
              </Row>
            </Col>
            <Col md={5}>
              <Row className="mb-3">
                <PasswordChecklist
                  rules={["minLength","specialChar","number","capital","match"]}
                  minLength={8}
                  iconSize={16}
                  value={password}
                  valueAgain={confirmPassword}
                  onChange={(isValid) => {setTruePassword(isValid)}}
                  messages={{
                      minLength: "Ao menos 8 caracteres.",
                      specialChar: "Ao menos um caractere especial.",
                      number: "Ao menos um número.",
                      capital: "Ao menos uma letra maiúscula.",
                      match: "Suas senhas coincidem.",
                  }}
                  />
              </Row>
            </Col>
          </Row>
        </Row>
      
      </Form>
    </div>
  )
}

export default Step1