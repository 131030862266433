import React, { useState, useRef } from "react"
import {
  Button,
    Card,
    CardBody,
    Col,
    Input,
    NavItem,
    NavLink,
    Row,
    Spinner,
    TabContent,
    TabPane
  } from "reactstrap"
import Breadcrumbs from "../../../../components/Common/Breadcrumb";
import styled from 'styled-components';
import classnames from "classnames"
import { Link } from "react-router-dom"
import Step1 from "./Step1"
import Step2 from "./Step2"
import Step3 from "./Step3"
import Step4 from "./Step4"
import Step5 from "./Step5"
import axios from 'axios';

const AddAp = () => {
    const [activeTab, setactiveTab] = useState(1);
    const [childDataStep1, setChildDataStep1] = useState({});
    const [childDataStep2, setChildDataStep2] = useState({});
    const [childDataStep3, setChildDataStep3] = useState({});
    const [childDataStep4, setChildDataStep4] = useState({});
    const [childDataStep5, setChildDataStep5] = useState({});
    const [typeRent, setTypeRent] = useState([]);
    const [sendProp, setSendPro] = useState([])
    const [status, setStatus] = useState(0)
  
  
    function toggleTab(tab) {
      if (activeTab !== tab) {
        if (tab >= 1 && tab <= 6) {
          setactiveTab(tab)
        }
      }
    }
  
    const handleChildStep1 = (receivedChildData) => {
      setChildDataStep1(receivedChildData);
      console.log(receivedChildData)
    };
  
    const handleChildStep2 = (receivedChildData) => {
      setChildDataStep2(receivedChildData);
    };
  
    const handleChildStep3 = (receivedChildData) => {
      setChildDataStep3(receivedChildData);
    };
  
     const handleChildStep4 = (receivedChildData) => {
       setChildDataStep4(receivedChildData);
     };
  
    const handleChildStep5 = (receivedChildData) => {
      setChildDataStep5(receivedChildData);
    };
  
    const handleSubmit = (event) => {
      event.preventDefault();
      document.getElementById('load').classList.add('active');

       const data = {
         type:childDataStep2.appType,
         enterprise_id:childDataStep1.construction?.value,
         owner_name:childDataStep1.owner,
         owner_phone:childDataStep1.whatsapp,
         broker_parc:childDataStep1.partnership,
         capture:childDataStep1.consultant,
         objective:childDataStep1.objective,
         payment_condition:childDataStep1.condition,
         payment_observation:childDataStep1.observation,
         seguro:childDataStep1.seguro ? 1 : 0,
         fiador:childDataStep1.fiador ? 1 : 0,
         calcao:childDataStep1.calcao ? 1 : 0,
         price:childDataStep1.price,
         promo_price:childDataStep1.pricePrevious,
         condominium:childDataStep1.fee,
         tax:childDataStep1.iptu,
         tax_include:childDataStep1.tax ? 1 : 0,
         address:childDataStep2.address,
         cep:childDataStep2.cep,
         city:childDataStep2.city?.value,
         complement:childDataStep2.complement,
         description:childDataStep2.description,
         district:childDataStep2.district,
         state:childDataStep2.state?.value,
         country:childDataStep2.country,
         name:childDataStep2.name,
         number:childDataStep2.number,
         tour:childDataStep2.tour,
         url_parameter:childDataStep2.urlParameter,
         video:childDataStep2.video,
         bathrooms:childDataStep3.bathrooms,
         demi_suite:childDataStep3.demiSuite,
         garage:childDataStep3.garage,
         rooms:childDataStep3.rooms,
         suites:childDataStep3.suites,
         zone:childDataStep3.zone,
         zone_full:childDataStep3.zoneFull,
         unit:childDataStep1.unity,
         extra_tag:childDataStep2.extraTag,
         seo_description:childDataStep2.seoDescription,
         keywords:childDataStep2.keywords,
         status:status,
       };


      fetch(process.env.REACT_APP_API_URL + '/create-property', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify(data),
      })
      .then(response => response.json())
      .then(async resp => {

        
        const propertyId = resp.id;
        
        const details = new FormData()
        if(childDataStep3.detailsImobile && childDataStep3.detailsImobile.length > 0){
          for(let i = 0; i < childDataStep3.detailsImobile.length; i++){
            console.log(childDataStep3.detailsImobile[i])
            details.append(`detail[${i}][detail]`, childDataStep3.detailsImobile[i].details)
            details.append(`detail[${i}][position]`, childDataStep3.detailsImobile[i].position)
            details.append(`detail[${i}][property_id]`, propertyId)
          }
          setTimeout(() => {
            axios.post(process.env.REACT_APP_API_URL + '/create-property-detail',details)
          }, 500);

        }

        const questions = new FormData()
        if(childDataStep2.qaPairs && childDataStep2.qaPairs.length > 0){
          for(let i = 0; i < childDataStep2.qaPairs.length; i++){
            questions.append(`question[${i}][question]`, childDataStep2.qaPairs[i].question)
            questions.append(`question[${i}][response]`, childDataStep2.qaPairs[i].answer)
            questions.append(`question[${i}][property_id]`, propertyId)
          }
          setTimeout(() => {
            axios.post(process.env.REACT_APP_API_URL + '/create-property-question',questions)
          }, 500);

        }

        console.log(childDataStep5.selectedFiles)
        const promises = [];

        if(childDataStep5.selectedFiles && childDataStep5.selectedFiles.length > 0){
          for (let i = 0; i < childDataStep5.selectedFiles.length; i++) {
            const photos = new FormData();
            photos.append(`file`, childDataStep5.selectedFiles[i].file);
            photos.append(`position`, childDataStep5.selectedFiles[i].position);
            photos.append(`alt`, childDataStep5.selectedFiles[i].title);
            photos.append(`url`, childDataStep2.urlParameter + '-' + i);
            photos.append(`property_id`, propertyId);
    
            promises.push(axios.post(process.env.REACT_APP_API_URL + '/create-property-photo', photos));
        }
    
          Promise.all(promises)
            .then(() => {
                window.open('./../cadastro-propriedade', "_parent")
            })
            .catch(error => {
              window.open('./../cadastro-propriedade', "_parent")
            });

        }else{
          setTimeout(() => {
            window.open('./../cadastro-propriedade', "_parent")
          }, 1500);
        }
      
      })
        .catch(error => {
          console.error(error);
        });    
    }

    return(
    <React.Fragment>
        <div className="page-content">
            <Breadcrumbs title="Gestão de Imóveis" breadcrumbItem="Novo Imóvel" />
            <Row>
            <Col lg="12">
                <Card>
                <CardBody>
                    <h4 className="card-title">Adicionar apartamento</h4>
                    <div className="form-wizard-wrapper wizard clearfix">
                        <div className="steps clearfix">
                            <ul>
                            <NavItem
                                className={classnames({ current: activeTab === 1 })}>
                                <NavLink
                                className={classnames({ current: activeTab === 1 })}
                                onClick={() => {
                                    setactiveTab(1)
                                }}
                                >
                                <span className="number">1.</span>{" "}
                                    Sobre o Proprietário e Negócio
                                </NavLink>
                            </NavItem>
                            <NavItem className={classnames({ current: activeTab === 2 })}>
                                <NavLink
                                className={classnames({ active: activeTab === 2 })}
                                onClick={() => {
                                    setactiveTab(2)
                                }}
                                >
                                <span className="number">2.</span>{" "}
                                    Sobre Imóvel
                                </NavLink>
                            </NavItem>
                            <NavItem className={classnames({ current: activeTab === 3 })}>
                                <NavLink
                                className={classnames({ active: activeTab === 3 })}
                                onClick={() => {
                                    setactiveTab(3)
                                }}
                                >
                                <span className="number">3.</span>
                                Características
                                </NavLink>
                            </NavItem>
                            <NavItem className={classnames({ current: activeTab === 4 })}>
                                <NavLink
                                className={classnames({ active: activeTab === 4 })}
                                onClick={() => {
                                    setactiveTab(4)
                                }}
                                >
                                <span className="number">4.</span>
                                Fotos do Imóvel
                                </NavLink>
                            </NavItem>
                            <NavItem className={classnames({ current: activeTab === 5 })}>
                                <NavLink
                                className={classnames({ active: activeTab === 5 })}
                                onClick={() => {
                                    setactiveTab(5)
                                }}
                                >
                                <span className="number">5.</span>
                                Confirmar cadastro
                                </NavLink>
                            </NavItem>
                            </ul>
                        </div>
                        <div className="content clearfix">
                            <TabContent
                            activeTab={activeTab}
                            className="body"
                            >
                            <TabPane tabId={1}>
                                <Step1 handleChildStep1={handleChildStep1}/>
                            </TabPane>

                            <TabPane tabId={2}>
                                <Step2 handleChildStep2={handleChildStep2}/>
                            </TabPane>

                            <TabPane tabId={3}>
                                <Step3 handleChildStep3={handleChildStep3}/>
                            </TabPane>

                            <TabPane tabId={4}>
                                <Step5
                                  handleChildStep5={handleChildStep5}
                                  title={childDataStep2?.name}
                                  url={childDataStep2?.urlParameter}
                                  send={sendProp}
                                />
                            </TabPane>

                            <TabPane tabId={5}>
                                <div className="row justify-content-center">
                                <Col lg="6">
                                    <div className="text-center">
                                    <div className="mb-4">
                                        <i className="mdi mdi-check-circle-outline text-success display-4" />
                                    </div>
                                    <div>
                                        <h5>Tudo pronto, confirmar cadastro.</h5>
                                        <p className="text-muted">
                                        Você pode voltar e conferir tudo antes se quiser.
                                        </p>
                                        <Row>
                                      <Row style={{justifyContent:'center', margin:'20px 0'}}>
                                        <Col md={8}>
                                          <Row className="mb-3">
                                            <label htmlFor="txtAddress1Billing"
                                              className="col-lg-4 col-form-label">Status do imóvel</label>
                                            <div className="col-lg-7">
                                              <Input 
                                                type="select" 
                                                id="ddlCreditCardType" 
                                                name="ddlCreditCardType"
                                                className="form-select"
                                                value={status}
                                                onChange={e => setStatus(e.target.value)}
                                              >
                                                <option value="">Selecione o Status</option>
                                                <option value={0}>Inativo</option>
                                                <option value={1}>Ativo</option>
                                                <option value={2}>Vendido</option>
                                              </Input>
                                            </div>
                                          </Row>
                                        </Col>
                                      </Row>
                                    </Row>
                                        <Button onClick={handleSubmit}>Enviar</Button>
                                    </div>
                                    </div>


                                </Col>
                                </div>
                            </TabPane>
                            </TabContent>
                        </div>
                        <div className="actions clearfix">
                            <ul>
                            <li
                                className={
                                activeTab === 1 ? "previous disabled" : "previous"
                                }
                            >
                                <Link
                                to="#"
                                className="btn btn-primary"
                                onClick={() => {
                                    toggleTab(activeTab - 1)
                                }}
                                >
                                Anterior
                                </Link>
                            </li>
                            <li
                                className={activeTab === 5 ? "next disabled" : "next"}
                            >
                                <Link
                                to="#"
                                className="btn btn-primary"
                                onClick={() => {
                                    toggleTab(activeTab + 1)
                                }}
                                >
                                Próximo
                                </Link>
                            </li>
                            </ul>
                        </div>
                        </div>
                </CardBody>
                </Card>
            </Col>
            </Row>

        </div>

        <Load id="load">
          <Spinner className="me-2" color="success" />
        </Load>
    </React.Fragment>
    )
}

export default AddAp;

const Load = styled.div`
  display:none;

  &.active{
    display:flex;
    position: fixed;
    top:0;
    bottom:0;
    left:0;
    right:0;
    background-color: rgba(0,0,0,0.5);
    align-items: center;
    justify-content: center;
    z-index:99999999999999;
  }
`;