import React, { useEffect, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import styled from 'styled-components';
import {MdDeleteForever} from 'react-icons/md';

const ImmobileCard = ({data}) => {

    const types = [
        {type: 'facing_sea', label: 'Frente mar'},
        {type: 'sea_court', label: 'Quadra mar'},
        {type: 'apartament_dif', label: 'Diferenciado'},
        {type: 'roof', label: 'Cobertura'},
        {type: 'plant', label: 'Na Planta'},
        {type: 'others_apartament', label: 'Outros apartamentos'},
        {type: 'house', label: 'Casa'},
        {type: 'terrain', label: 'Terreno residencial'},
        {type: 'terrain_land_shed', label: 'Terreno logístico'},
        {type: 'land_shed', label: 'Galpão logístico'},
    ]

    const [confirm_both, setconfirm_both] = useState(false)
    const [success_dlg, setsuccess_dlg] = useState(false)
    const [dynamic_title, setdynamic_title] = useState("")
    const [dynamic_description, setdynamic_description] = useState("")

    const [selectedId, setSelectedId] = useState(null)
    const [selectedName, setSelectedName] = useState('')
    const [integrate, setIntegrate] = useState(false)

    useEffect(() => {
        if(data){
            fetch(process.env.REACT_APP_API_URL + '/check-property-exists/' + data.sku, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                  },
            })
            .then((res) => res.json())
            .then(response => {
                if(response.exists){
                    setIntegrate(true)
                }
            })
        }
    }, [data])

    return(
        <>
    <ConstructionCardContainer>
        {integrate && <Overlay>Integrado</Overlay>}
        {data.photos?.filter(photo => photo?.order === 0)[0]?.medium_image ?
            <Cover  style={{backgroundImage:'url(' + data.photos?.filter(photo => photo?.order === 0)[0]?.medium_image + ')'}}></Cover>
            :
            <Cover></Cover>
        }
        <Content>
            <h5>código: {data.sku}</h5>
            <h3>{data.title}</h3>
            {/* <h4>{data.type?.replace(data.type, types?.filter(typeS => typeS.type === data.type)[0]?.label )}</h4> */}
            <p>{data.address_properties?.city}</p>
        </Content>
        {!integrate &&
            <CloseButton onClick={() => {
                setSelectedId(data.id)
                setSelectedName(data.title)
                setconfirm_both(true)
            }}>Integrar</CloseButton>
        }

    </ConstructionCardContainer>

    {confirm_both ? (
            <SweetAlert
                title={`Tem certeza que deseja intregrar o imóvel ${selectedName} ?`}
                warning
                showCancel
                cancelButtonText='Cancelar'
                confirmBtnBsStyle="success"
                confirmButtonText='Excluir'
                cancelBtnBsStyle="danger"
                onConfirm={() => {
                    fetch(process.env.REACT_APP_API_URL + '/clone-integration/' + selectedId, {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem('token')}`,
                          },
                    })
                    .then((res) => res.json())
                    .then(response => {
                        setconfirm_both(false)
                        setsuccess_dlg(true)
                        setdynamic_title("Integrado")
                        setdynamic_description("Imóvel integrado.")
                        setTimeout(() => {
                            window.open(`/editar-propriedade/imoveis/id?${response.property_id}`, '_parent')
                        }, 800);
                    })
                }}
                onCancel={() => {
                setconfirm_both(false)
                setsuccess_dlg(true)
                setdynamic_title("Cancelado")
                setdynamic_description("O imóvel não foi integrado.")
                }}
            >
            </SweetAlert>
            ) : null}

            {success_dlg ? (
                <SweetAlert
                success
                title={dynamic_title}
                onConfirm={() => {
                    setTimeout(() => {
                        window.location.reload()
                    }, 300);
                }}
                >
                {dynamic_description}
                </SweetAlert>
            ) : null}


    </>
    )
}

export default ImmobileCard;

const Overlay = styled.div`
    width:100%;
    height:100%;
    background-color:rgba(0,0,0,0.7);
    position:absolute;
    left:0;
    top:0;
    border-radius:10px;
    display:flex;
    justify-content:center;
    align-items:center;
    color:#fff;
    font-size:20px;
    font-weight:600;
`;

const ConstructionCardContainer = styled.div`
    width:200px;
    height:260px;
    box-shadow:0 0 5px rgba(0,0,0,0.5);
    border-radius:8px;
    padding:10px;
    transition:0.2s;
    position:relative;
    cursor:pointer;

    &:hover{
        transform:scale(1.03);
    }
`;

const Cover = styled.div`
    background-position:center center;
    background-size:cover;
    width:100%;
    height:100px;
    background-color:#f4f4f4;
`;

const Content = styled.div`
    text-align:left;
    padding-top:10px;

    h5{
        font-size:14px;
        background-color:#cdcdcd;
        border-radius:8px;
        padding:3px 5px;
    }
    h4{
        font-size:17px;
    }
    h3{
        font-size:16px;
    }
`;

const CloseButton = styled.div`
    position:absolute;
    top:-8px;
    z-index:10;
    right:-8px;
    padding:0 8px;
    background-color:#101010;
    border-radius:8px;
    cursor:pointer;
    height:30px;
    display:flex;
    color:#fff;
    align-items:center;
    justify-content:center;
    box-shadow:0 0 5px rgba(0,0,0,0.3);

    &:hover{
        transform:scale(1.2);
    }
`;