import React, { useState, useEffect } from "react"
// import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { Draggable } from 'react-drag-reorder';
import { Card, Row, Col, Form, Button, Input, Spinner } from "reactstrap"
import Dropzone from "react-dropzone"
import './style.scss'
import { Link } from "react-router-dom"
import {AiOutlineClose} from 'react-icons/ai'
import { ImageDragCard } from "./imageCard";
import { styled } from "styled-components";
import { ImageDragCardAtual } from "./imageCardAtual";
import axios from 'axios';


const Step5 = ({ handleChildStep5, send, title, url, enterprise }) => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [photosArray, setPhotosArray] = useState([]);
  const [show, setShow] = useState(false);
  const [editShow, setEditShow] = useState(false);
  const [atualPhotos, setAtualPhotos] = useState([]);
  const [loading, setLoading] = useState(false)

  const handleAcceptedFiles = (files) => {
    files = files.map((file, index) => ({
      ...file,
      preview: URL.createObjectURL(file),
      formattedSize: formatBytes(file.size),
      file: file,
      title: title,
      url: url,
      position: index
    }));

    setSelectedFiles(files);
    setPhotosArray(files);
  };

  const dataToSend = {
    selectedFiles,
    atualPhotos
  };

  useEffect(() => {
    handleChildStep5(dataToSend);
    setShow(selectedFiles.length > 0)
    setSelectedFiles(photosArray)
  }, [selectedFiles, photosArray])

  useEffect(() => {
    handleChildStep5(dataToSend);
  }, [atualPhotos])


  const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  const handleAtualTitleChange = (index, newTitle) => {
    setAtualPhotos((prevPhotosArray) => {
      const updatedPhotosArray = [...prevPhotosArray];
      updatedPhotosArray[index].alt = newTitle;
      return updatedPhotosArray;
    });
  };

  const handleAtualPositionChange = (index, newPosition) => {
    setAtualPhotos((prevPhotosArray) => {
      const updatedPhotosArray = [...prevPhotosArray];
      updatedPhotosArray[index].position = newPosition;
      return updatedPhotosArray;
    });
  };

  const handleTitleChange = (index, newTitle) => {
    setPhotosArray((prevPhotosArray) => {
      const updatedPhotosArray = [...prevPhotosArray];
      updatedPhotosArray[index].title = newTitle;
      return updatedPhotosArray;
    });
  };
  
  const handlePositionChange = (index, newPosition) => {
    setPhotosArray((prevPhotosArray) => {
      const updatedPhotosArray = [...prevPhotosArray];
      updatedPhotosArray[index].position = newPosition;
      return updatedPhotosArray;
    });
  };

  const handleUrlChange = (index, newUrl) => {
    setPhotosArray((prevPhotosArray) => {
      const updatedPhotosArray = [...prevPhotosArray];
      updatedPhotosArray[index].url = newUrl;
      return updatedPhotosArray;
    });
  };

  const handleRemoveImage = (path) => {
    const indexToRemove = photosArray.findIndex(image => image.path === path);

    if (indexToRemove !== -1) {
      const updatedImages = [...photosArray];
      updatedImages.splice(indexToRemove, 1);
      setSelectedFiles(updatedImages);
      setPhotosArray(updatedImages);
    }
  };

  useEffect(() => {
    setAtualPhotos(enterprise?.photos?.sort((a, b) => a.position - b.position))
    setTimeout(() => {
      setEditShow(true)
    }, 300);
  },[enterprise])
  
  const handleRemoveImageAtual = (id) => {
    setAtualPhotos([])
     fetch(process.env.REACT_APP_API_URL + '/delete-enterprise-photo/' + id, {
       method: 'GET',
       headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
     })
     .then(response => {
      
      fetch(process.env.REACT_APP_API_URL + '/enterprise-id/' + enterprise.id, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      .then(response => response.json())
      .then(async resp => {
        setAtualPhotos(resp?.newsEnterprise?.photos)
      })
      })
    
  };

  const handleRemoveAll = () => {
    setLoading(true)
     axios.get(process.env.REACT_APP_API_URL + '/delete-enterprise-photo-by-id/' + enterprise.id)
      .then(response => {
        setAtualPhotos([]);
        setLoading(false)
      })
      .catch(error => {
        console.error("Erro ao remover a foto:", error);
        setLoading(false)
      });
  }



  return (
    <div>
        <Form>
          <Dropzone
            accept=".png, .jpeg, .jpg, .heic"
            onDrop={acceptedFiles => handleAcceptedFiles(acceptedFiles)}
          >
            {({ getRootProps, getInputProps }) => (
              <div className="dropzone" {...getRootProps()}>
                <div
                  className="dz-message needsclick"
                  {...getRootProps()}
                >
                  <input {...getInputProps()} />
                  <div className="mb-3">
                    <i className="display-4 text-muted ri-upload-cloud-2-line"></i>
                  </div>
                  <h4>Para adicionar fotos arraste ou clique aqui.</h4>
                </div>
              </div>
            )}
          </Dropzone>
              <div
                className="dropzone-previews mt-3"
                id="file-previews"
              >
                {photosArray.map((image, index) => (
                  <ImageDragCard
                    key={index}
                    image={image}
                    index={index}
                    onTitleChange={handleTitleChange}
                    onUrlChange={handleUrlChange}
                    onPositionChange={handlePositionChange}
                    onRemove={() => handleRemoveImage(image.path)}
                  />
                ))}
          </div>
        </Form>
        <div style={{widht:'100%', height:'1px', backgroundColor:"#cdcdcd", margin:'20px 0'}}></div>
        {atualPhotos?.length > 0 &&
        <div style={{
            display:'flex',
            justifyContent:'space-between'
          }}>
            <h3 style={{fontSize:'16px'}}>Imagens adicionadas</h3>
            <Button
              onClick={handleRemoveAll}
              style={{
                backgroundColor: '#da2c3e',
                border:'none'
              }}
            >Apagar todas imagens</Button>
          </div>
          }
        <div
          className="dropzone-previews mt-3"
          id="file-previews2"
        >
            {atualPhotos?.map((image, index) => (
              <ImageDragCardAtual
                key={index}
                image={image}
                index={index}
                onTitleChange={handleAtualTitleChange}
                onPositionChange={handleAtualPositionChange}
                onRemove={() => handleRemoveImageAtual(image.id)}
              />
            ))}
        </div>

        {loading &&
          <Loading>
            <Spinner></Spinner>
          </Loading>
        }
    </div>
  )
}

export default Step5;


const Loading = styled.div`
  width:100%;
  height:100%;
  position:fixed;
  background-color:rgba(0,0,0,0.6);
  top:0;
  bottom:0;
  left:0;
  right:0;
  display:flex;
  align-items:center;
  justify-content:center;
  z-index:9999;
`;