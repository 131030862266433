import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, Col } from 'reactstrap';

const Overview = () => {

    const [recentLogin, setRecentLogin] = useState([])

    useEffect(() => {
      fetch(process.env.REACT_APP_API_URL + '/recent-login',{
        headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
      })
      .then(response => response.json())
      .then(async resp => {
          console.log(resp)
          setRecentLogin(resp.recent_logins)
      })
        .catch(error => {
          console.error(error);
        });    
    }, [])

    return (
        <React.Fragment>
            <Col lg={4}>
                <Card>
                    <CardBody style={{minHeight:'410px'}}>
                        <h4 className="card-title mb-4">Login de usuários</h4>

                        <ul className="inbox-wid list-unstyled">
                        {recentLogin?.map((inbox, key) => (
                            <li className="inbox-list-item" key={key}>
                                <Link to="#">
                                    <div className="d-flex align-items-start">
                                        <div className="me-3 align-self-center">
                                            <img src={inbox.profile_image} alt=""
                                                className="avatar-sm rounded-circle" />
                                        </div>
                                        <div className="flex-1 overflow-hidden">
                                            <h5 className="font-size-16 mb-1">{inbox.name}</h5>
                                            <p className="text-truncate mb-0">{inbox.email}</p>
                                        </div>
                                    </div>
                                </Link>
                            </li>
                        ))}
                            
                        </ul>
                    </CardBody>
                </Card>
            </Col>
        </React.Fragment>
    )
}

export default Overview;