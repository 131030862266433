import { useState, useEffect } from 'react';
import './style.scss';
import {
  Col,
  Form,
  Input,
  Row
} from "reactstrap"

const Step3 = ({ handleChildStep3 }) => {
  const [consultant, setConsultant] = useState('');
  const [titleDescription, setTitleDescription] = useState('');
  const [description, setDescription] = useState('');
  const [tax, setTax] = useState('');
  const [video, setVideo] = useState('');
  const [tour, setTour] = useState('');
  const [status, setStatus] = useState('0');

  const dataToSend = {
    consultant,
    titleDescription,
    description,
    tax,
    video,
    tour,
    status
  };

  useEffect(() => {
    handleChildStep3(dataToSend);
  }, [consultant, 
      titleDescription,
      description,
      tax,
      video,
      tour,
      status])

  return(
    <div>
        <Form>
          <Row>
            <h5 className="title-description">Descrição e Captador</h5>
            <Row>
              <Col md={12}>
                <Row className="mb-3">
                  <label htmlFor="txtNameCard" className="col-lg-2 col-form-label">Captação</label>
                  <div className="col-lg-9">
                    <Input 
                      id="txtNameCard" 
                      name="txtNameCard" 
                      type="text" 
                      placeholder="Captação"
                      className="form-control" 
                      value={consultant}
                      onChange={e => setConsultant(e.target.value)}
                    />
                  </div>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <Row className="mb-3">
                  <label htmlFor="txtNameCard" className="col-lg-2 col-form-label">Título da Descrição</label>
                  <div className="col-lg-9">
                    <Input 
                      id="txtNameCard" 
                      name="txtNameCard" 
                      type="text" 
                      placeholder="Título da Descrição"
                      className="form-control" 
                      value={titleDescription}
                      onChange={e => setTitleDescription(e.target.value)}
                    />
                  </div>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <Row className="mb-3">
                  <label htmlFor="txtNameCard" className="col-lg-2 col-form-label">Descrição</label>
                  <div className="col-lg-9">
                    <Input 
                      id="txtNameCard" 
                      name="txtNameCard" 
                      type="textarea" 
                      placeholder="Descrição"
                      className="form-control" 
                      value={description}
                      onChange={e => setDescription(e.target.value)}
                    />
                  </div>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                  <Row className="mb-3">
                    <label htmlFor="txtTelephoneBilling"
                      className="col-lg-3 col-form-label"></label>
                    <div className="col-lg-9">
                      <div className="content-location">
                        <Input 
                          id="txtTelephoneBilling" 
                          name="txtTelephoneBilling"
                          type="checkbox" 
                          className="form-control" 
                          value={tax}
                          onChange={e => setTax(e.target.value)}
                        />
                        <span>Taxas inclusas?</span>
                      </div>
                    </div>
                  </Row>
              </Col>
            </Row>
          </Row>
          <Row>
            <h5 className="title-video">Video e Tour 360º</h5>
            <Row>
              <Col md={12}>
                <Row className="mb-3">
                  <label htmlFor="txtNameCard" className="col-lg-2 col-form-label">Link Vídeo</label>
                  <div className="col-lg-9">
                    <Input 
                      id="txtNameCard" 
                      name="txtNameCard" 
                      type="text" 
                      placeholder="Link Vídeo"
                      className="form-control" 
                      value={video}
                      onChange={e => setVideo(e => e.target.value)}
                    />
                  </div>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <Row className="mb-3">
                  <label htmlFor="txtNameCard" className="col-lg-2 col-form-label">Link Tour 360º</label>
                  <div className="col-lg-9">
                    <Input 
                      id="txtNameCard" 
                      name="txtNameCard" 
                      type="text" 
                      placeholder="Link Tour 360º"
                      className="form-control" 
                      value={tour}
                      onChange={e => setTour(e.target.value)}
                    />
                  </div>
                </Row>
              </Col>
            </Row>
          </Row>
          <Row>
            <h5 className="title-status">Status</h5>
            <Row>
              <Col md={12}>
                <Row className="mb-3">
                  <label htmlFor="txtAddress1Billing"
                    className="col-lg-2 col-form-label">Tipo Imóvel</label>
                  <div className="col-lg-9">
                    <Input 
                      type="select" 
                      id="ddlCreditCardType" 
                      name="ddlCreditCardType"
                      className="form-select"
                      value={status}
                      onChange={e => setStatus(e.target.value)}
                    >
                      <option value="">Selecione o Status</option>
                      <option value="AE">Inativo</option>
                      <option value="VI">Ativo</option>
                      <option value="VI">Captado</option>
                    </Input>
                  </div>
                </Row>
              </Col>
            </Row>
          </Row>
        </Form>
    </div>
  )
}

export default Step3