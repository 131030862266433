import React, { useState, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Button, Col, Form, Input, Label, Row } from "reactstrap";
import './style.scss'

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const Step4 = ({ handleChildStep4 }) => {
  const [valueImobile, setValueImobile] = useState('');
  const [detailsImobile, setDetailsImobile] = useState([
      {id: 1, details: 'Característica 1', position: '1'},
      {id: 2, details: 'Característica 2', position: '2'},
  ]);
  const [valueEnterprise, setValueEnterprise] = useState('');
  const [detailsEnterprise, setDetailsEnterprise] = useState([
    {id: 1, details: 'Característica Empreendimento 1', position: '1'},
    {id: 2, details: 'Característica Empreendimento 2', position: '2'},
  ]);

  const dataToSend = {
    detailsImobile
  }

  /* IMÓVEL */
  const handleChangeImobile = (event) => {
    setValueImobile(event.target.value);
  };

  const loadDetailsImobile = () => {
    if(valueImobile.trim() !== '') { 
      setDetailsImobile([...detailsImobile, { id: detailsImobile.length + 1, details: valueImobile, position: (detailsImobile.length + 1).toString() }]);
      setValueImobile('');
    }
  };

  const removeDetailImobile = (id) => { 
    setDetailsImobile(detailsImobile.filter(detail => detail.id !== id));
  };

  const onDragEndImobile = (result) => {
    if (!result.destination) return;
  
    const updatedDetails = reorder(
      detailsImobile,
      result.source.index,
      result.destination.index
    ).map((item, index) => ({
      ...item,
      position: (index + 1).toString(),
    }));
  
    setDetailsImobile(updatedDetails);
  
    console.log('Nova ordem das características:', updatedDetails);
  };

  /* EMPREENDIMENTO */
  const handleChangeEnterprise = (event) => {
    setValueEnterprise(event.target.value);
  };

  const loadDetailsEnterprise = () => {
    if(valueEnterprise.trim() !== '') { 
      setDetailsEnterprise([...detailsEnterprise, { id: detailsEnterprise.length + 1, details: valueEnterprise, position: (detailsEnterprise.length + 1).toString() }]);
      setValueEnterprise('');
    }
  };

  const removeDetailEnterprise = (id) => { 
    setDetailsEnterprise(detailsEnterprise.filter(detail => detail.id !== id));
  };

  const onDragEndEnterprise = (result) => {
    if (!result.destination) return;
  
    const updatedDetailsEnterprise = reorder(
      detailsEnterprise,
      result.source.index,
      result.destination.index
    ).map((item, index) => ({
      ...item,
      position: (index + 1).toString(),
    }));
  
    setDetailsEnterprise(updatedDetailsEnterprise);
  
    console.log('Nova ordem das características:', updatedDetailsEnterprise);
  };

  useEffect(() => {
    handleChildStep4(dataToSend);
  }, [detailsImobile]);

  return (
    <>
      <div style={{ maxWidth: '600px', margin: '1rem auto 3rem auto' }}>
        <h5>Características do imóvel</h5>
        <Form>
          <Row>
            <Col md={12}>
              <Row className="mb-3">
                <Label htmlFor="txtNameCard" className="col-lg-4 col-form-label title">Característica do Imóvel</Label>
                <div className="col-lg-9">
                  <Input 
                    id="txtNameCard" 
                    name="txtNameCard" 
                    type="textarea" 
                    placeholder="Descrição"
                    className="form-control"
                    value={valueImobile}
                    onChange={handleChangeImobile}
                  />
                </div>
                <div className='add-button'>
                  <Button onClick={loadDetailsImobile}>Adicionar</Button>
                </div>
              </Row>
            </Col>
          </Row>

          <Row>
            <DragDropContext onDragEnd={onDragEndImobile}>
              <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    className='container-drag'
                    style={{ backgroundColor: snapshot.isDraggingOver ? 'lightblue' : 'lightgrey' }}
                  >
                    {detailsImobile.map((item, index) => (
                      <Draggable key={item.id} draggableId={item.id.toString()} index={index}>
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className='content-drag'
                            style={{
                              userSelect: 'none',
                              padding: '16px',
                              margin: '4px 0 4px 0',
                              minHeight: '50px',
                              backgroundColor: snapshot.isDragging ? '#3E5E3F' : '#648465',
                              color: 'white',
                              ...provided.draggableProps.style 
                            }}
                          >
                            {item.details}
                            <Button color="danger" size="sm" style={{float: 'right'}} onClick={() => removeDetailImobile(item.id)}>Excluir</Button>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </Row>
        </Form>
      </div>

      <div style={{ margin: '2rem auto', width: '80%', borderBottom: '2px solid #ccc' }}></div>

      <div style={{ maxWidth: '600px', margin: '3rem auto' }}>
        <h5>Características do Empreendimento</h5>
        <Form>
          <Row>
            <Col md={12}>
              <Row className="mb-3">
                <Label htmlFor="txtNameCard" className="col-lg-4 col-form-label title">Característica do Imóvel</Label>
                <div className="col-lg-9">
                  <Input 
                    id="txtNameCard" 
                    name="txtNameCard" 
                    type="textarea" 
                    placeholder="Descrição"
                    className="form-control"
                    value={valueEnterprise}
                    onChange={handleChangeEnterprise}
                  />
                </div>
                <div className='add-button'>
                  <Button onClick={loadDetailsEnterprise}>Adicionar</Button>
                </div>
              </Row>
            </Col>
          </Row>

          <Row>
            <DragDropContext onDragEnd={onDragEndEnterprise}>
              <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    className='container-drag'
                    style={{ backgroundColor: snapshot.isDraggingOver ? 'lightblue' : 'lightgrey' }}
                  >
                    {detailsEnterprise.map((item, index) => (
                      <Draggable key={item.id} draggableId={item.id.toString()} index={index}>
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className='content-drag'
                            style={{
                              userSelect: 'none',
                              padding: '16px',
                              margin: '8px 0 8px 0',
                              minHeight: '50px',
                              backgroundColor: snapshot.isDragging ? '#3E5E3F' : '#648465',
                              color: 'white',
                              ...provided.draggableProps.style 
                            }}
                          >
                            {item.details}
                            <Button color="danger" size="sm" style={{float: 'right'}} onClick={() => removeDetailEnterprise(item.id)}>Excluir</Button>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </Row>
        </Form>
      </div>
    </>
  );
}

export default Step4;
