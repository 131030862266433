import React, { useState, useRef } from "react"
import {
  Card,
  CardBody,
  Col,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane
} from "reactstrap"
import classnames from "classnames"
import { Link } from "react-router-dom"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import Step1 from "../../../components/Imobile/Step1"
import Step2 from "../../../components/Imobile/Step2"
import Step3 from "../../../components/Imobile/Step3"
import Step4 from "../../../components/Imobile/Step4"
import Step5 from "../../../components/Imobile/Step5"
import Axios from "axios"

const NewImobile = () => {
  const [activeTab, setactiveTab] = useState(1);
  const [childDataStep1, setChildDataStep1] = useState({});
  const [childDataStep2, setChildDataStep2] = useState({});
  const [childDataStep3, setChildDataStep3] = useState({});
  const [childDataStep4, setChildDataStep4] = useState({});
  const [childDataStep5, setChildDataStep5] = useState({});
  const [typeRent, setTypeRent] = useState([]);
  const [sendProp, setSendPro] = useState([])


  function toggleTab(tab) {
    if (activeTab !== tab) {
      if (tab >= 1 && tab <= 6) {
        setactiveTab(tab)
      }
    }
  }

  const handleChildStep1 = (receivedChildData) => {
    setChildDataStep1(receivedChildData);
  };

  const handleChildStep2 = (receivedChildData) => {
    setChildDataStep2(receivedChildData);
  };

  const handleChildStep3 = (receivedChildData) => {
    setChildDataStep3(receivedChildData);
  };

  const handleChildStep4 = (receivedChildData) => {
    setChildDataStep4(receivedChildData);
  };

  const handleChildStep5 = (receivedChildData) => {
    setChildDataStep5(receivedChildData);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    
    const dataOwner = childDataStep1.owner;
    const dataWhatsapp = childDataStep1.whatsapp;
    const dataUnity = childDataStep1.unity;
    const dataObjective = childDataStep1.objective;
    const dataType = childDataStep1.typeImmobile;
    const dataFee = childDataStep1.fee;
    const dataIptu = childDataStep1.iptu;
    const dataObservation = childDataStep1.observation;
    const dataPartnership = childDataStep1.partnership;
    const dataUrl = childDataStep2.urlParameter;
    const dataPrice = parseInt(childDataStep2.price.replace('.', '').replace('.', ''));
    const dataZone = childDataStep2.zone;
    const dataZoneFull = childDataStep2.zoneFull;
    const dataRooms = childDataStep2.rooms;
    const dataDemiSuite = childDataStep2.demiSuite;
    const dataSuites = childDataStep2.suites;
    const dataBathrooms = childDataStep2.bathrooms;
    const dataGarage = childDataStep2.garage;
    const dataCondition = childDataStep2.condition;
    const dataCep = childDataStep2.cep;
    const dataAddress = childDataStep2.address;
    const dataNumber = childDataStep2.number;
    const dataComplement = childDataStep2.complement;
    const dataDistrict = childDataStep2.district;
    const dataCity = childDataStep2.city;
    const dataShowMap = childDataStep2.showMapData;
    const dataCapture = childDataStep3.child;
    const dataTax = childDataStep3.tax;
    const dataVideo = childDataStep3.video;
    const dataTour = childDataStep3.tour;
    const dataStatus = childDataStep3.status;
    const typeRentState = typeRent;
    const dataTypeRent = typeRentState.toString(typeRentState);
    const dataPriceFinal = dataPrice;

    const data = {
      status: dataStatus,
      id_building: 1,
      url_parameter: dataUrl.replace('--', '-'),
      type_immobile: dataType,
      objective: dataObjective,
      price: dataPriceFinal,
      offer: 123,
      type_rent: dataTypeRent,
      fee: dataFee,
      iptu: dataIptu,
      city: dataCity,
      address: dataAddress,
      complement: dataComplement,
      number: dataNumber,
      cep: dataCep,
      district: dataDistrict,
      state: 'SC',
      country: 'Brasil', 
      whatsapp: dataWhatsapp,
      phone: '123123',
      consultant: dataCapture,
      unity: dataUnity,
      owner: dataOwner,
      partnership: dataPartnership,
      garage: dataGarage,
      rooms: dataRooms,
      suites: dataSuites,
      demi_suite: dataDemiSuite,
      bathrooms: dataBathrooms,
      zone: dataZone,
      zone_full: dataZoneFull,
      title: 'Teste',
      observation: dataObservation,
      business_condition: dataCondition,
      tax: dataTax,
      show_map: dataShowMap,
      description: 'Teste',
      video: dataVideo,
      tour: dataTour,
    };
    fetch(process.env.REACT_APP_API_URL + '/add-property', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify(data),
    })
    .then(response => response.json())
      .then(resp => {
        
         const detailsData = {
           entries: childDataStep4.detailsImobile.map((detail, index) => {
             return {
               property_id: resp.id,
               details: detail.details,
               position: index + 1  
             };
           })
         };
    
         fetch(process.env.REACT_APP_API_URL + '/property-details', {
           method: 'POST',
           headers: {
             'Content-Type': 'application/json',
              'Authorization': `Bearer ${localStorage.getItem('token')}`,
           },
           body: JSON.stringify(detailsData),
         });

         setSendPro([resp.id, resp.title]);
      })
      .catch(error => {
        console.error(error);
      });    
  }

  return (
    <React.Fragment>
      <div className="page-content">

        <Breadcrumbs title="Gestão de Imóveis" breadcrumbItem="Novo Imóvel" />
        <Row>
          <Col lg="12">
            <Card>
              <CardBody>
                <h4 className="card-title">Novo Imóvel</h4>
                <p className="card-title-desc">Preencha o maior número de informações possível.</p>
                <div className="form-wizard-wrapper wizard clearfix">
                  <div className="steps clearfix">
                    <ul>
                      <NavItem
                        className={classnames({ current: activeTab === 1 })}>
                        <NavLink
                          className={classnames({ current: activeTab === 1 })}
                          onClick={() => {
                            setactiveTab(1)
                          }}
                        >
                          <span className="number">1.</span>{" "}
                            Sobre o Propri./Negócio
                          </NavLink>
                      </NavItem>
                      <NavItem className={classnames({ current: activeTab === 2 })}>
                        <NavLink
                          className={classnames({ active: activeTab === 2 })}
                          onClick={() => {
                            setactiveTab(2)
                          }}
                        >
                          <span className="number">2.</span>{" "}
                            Sobre Imóvel
                          </NavLink>
                      </NavItem>
                      <NavItem className={classnames({ current: activeTab === 3 })}>
                        <NavLink
                          className={classnames({ active: activeTab === 3 })}
                          onClick={() => {
                            setactiveTab(3)
                          }}
                        >
                          <span className="number">3.</span>
                          Descrições
                        </NavLink>
                      </NavItem>
                      <NavItem className={classnames({ current: activeTab === 4 })}>
                        <NavLink
                          className={classnames({ active: activeTab === 4 })}
                          onClick={() => {
                            setactiveTab(4)
                          }}
                        >
                          <span className="number">4.</span>
                          Caract. do Imóvel
                        </NavLink>
                      </NavItem>
                      <NavItem className={classnames({ current: activeTab === 5 })}>
                        <NavLink
                          className={classnames({ active: activeTab === 5 })}
                          onClick={() => {
                            setactiveTab(5)
                          }}
                        >
                          <span className="number">5.</span>
                          Fotos do Imóvel
                        </NavLink>
                      </NavItem>
                      <NavItem className={classnames({ current: activeTab === 6 })}>
                        <NavLink
                          className={classnames({ active: activeTab === 6 })}
                          onClick={() => {
                            setactiveTab(6)
                          }}
                        >
                          <span className="number">6.</span>
                          Confirm Detail
                        </NavLink>
                      </NavItem>
                    </ul>
                  </div>
                  <div className="content clearfix">
                    <TabContent
                      activeTab={activeTab}
                      className="body"
                    >
                      <TabPane tabId={1}>
                        <Step1 handleChildStep1={handleChildStep1}/>
                      </TabPane>

                      <TabPane tabId={2}>
                        <Step2 handleChildStep2={handleChildStep2}/>
                      </TabPane>

                      <TabPane tabId={3}>
                        <Step3 handleChildStep3={handleChildStep3}/>
                      </TabPane>

                      <TabPane tabId={4}>
                        <Step4 handleChildStep4={handleChildStep4} />
                      </TabPane>

                      <TabPane tabId={5}>
                        <Step5 handleChildStep5={handleChildStep5} send={sendProp} />
                      </TabPane>

                      <TabPane tabId={6}>
                        <div className="row justify-content-center">
                          <Col lg="6">
                            <div className="text-center">
                              <div className="mb-4">
                                <i className="mdi mdi-check-circle-outline text-success display-4" />
                              </div>
                              <div>
                                <h5>Confirm Detail</h5>
                                <p className="text-muted">
                                  If several languages coalesce, the grammar of
                                  the resulting
                                </p>
                                <button onClick={handleSubmit}>Teste DATA</button>
                              </div>
                            </div>
                          </Col>
                        </div>
                      </TabPane>
                    </TabContent>
                  </div>
                  <div className="actions clearfix">
                    <ul>
                      <li
                        className={
                          activeTab === 1 ? "previous disabled" : "previous"
                        }
                      >
                        <Link
                          to="#"
                          className="btn btn-primary"
                          onClick={() => {
                            toggleTab(activeTab - 1)
                          }}
                        >
                          Anterior
                          </Link>
                      </li>
                      <li
                        className={activeTab === 6 ? "next disabled" : "next"}
                      >
                        <Link
                          to="#"
                          className="btn btn-primary"
                          onClick={() => {
                            toggleTab(activeTab + 1)
                          }}
                        >
                          Próximo
                          </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>

      </div>
    </React.Fragment>
  )
}

export default NewImobile