import React from "react";
import {
  Card,
  CardBody,
  Col,
  Row,
} from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import styled from 'styled-components';
import {BsBuildingAdd, BsBuildingGear, BsBuilding, BsHouseDoor, BsBoundingBoxCircles, BsBoundingBox, BsBoxSeam} from 'react-icons/bs';

const PropertyEdit = () => {

    return(
    <React.Fragment>
        <div className="page-content">
            <Breadcrumbs title="Gestão de Imóveis" breadcrumbItem="Editar imóvel" />
            <Row>
            <Col lg="12">
                <Card>
                <CardBody>
                    <h4 className="card-title">Tipo do imóvel</h4>
                    <OptionsGrid>
                        <Option onClick={() => {
                            setTimeout(() => {
                                window.open('./../editar-propriedade/empreendimento', '_parent')
                            }, 0);
                        }}><BsBuilding size="2.2rem"/><p>Empreendimento</p></Option>
                        <Option onClick={() => {
                            setTimeout(() => {
                                window.open('./../editar-propriedade/construtura', '_parent')
                            }, 0);
                        }}><BsBuildingGear size="2.2rem"/><p>Construtora</p></Option>
                        <Option onClick={() => {
                            setTimeout(() => {
                                window.open('./../editar-propriedade/imoveis', '_parent')
                            }, 0);
                        }}><BsBuildingAdd size="2.2rem"/><p>Imóveis</p></Option>
                    </OptionsGrid>
                </CardBody>
                </Card>
            </Col>
            </Row>
        </div>
    </React.Fragment>
    )
}

export default PropertyEdit;

const OptionsGrid = styled.div`
    display:grid;
    margin:30px 0;
    padding:20px;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap:30px;
`;

const Option = styled.div`
    background-color:#888;
    border-radius:10px;
    height:150px;
    width:200px;
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    color:#fff;
    gap:20px;
    padding-top:15px;
    cursor:pointer;

    &:hover{
        background-color:rgb(0, 62, 14);
    }
`;