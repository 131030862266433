import React, { useEffect, useState } from "react"
import { Row, Col, CardBody, Card, Progress, Spinner } from "reactstrap"
import { Link } from "react-router-dom"

//Import Components
import RevenueChart from "./revenue-chart"
import SalesAnalytics from "./sales-analytics"
import LatestTransaction from "./latest-transaction"

//Import Image
import Inbox  from './Inbox';
import styled from 'styled-components'

const Dashboard = () => {

  const [status1Count, setStatus1Count] = useState(0)
  const [status2Count, setStatus2Count] = useState(0)
  const [status0Count, setStatus0Count] = useState(0)
  const [price1Total, setPrice1Total] = useState(0)
  const [price2Total, setPrice2Total] = useState(0)
  const [recentUpdate, setRecentUpdate] = useState([])
  const [propertyPerMonth, setPropertyPerMonth] = useState([])
  const [loading,setLoading] = useState(false)

  useEffect(() => {
    fetch(process.env.REACT_APP_API_URL + '/dashboard',{
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
    .then(response => response.json())
    .then(async resp => {
        console.log(resp)
        setStatus1Count(resp.total_status_1_count)
        setStatus2Count(resp.total_status_2_count)
        setStatus0Count(resp.total_status_0_count)
        setPrice1Total(resp.total_status_1)
        setPrice2Total(resp.total_status_2)
        setRecentUpdate(resp.recent_properties)
        setPropertyPerMonth(resp.properties_created_per_month_year)
    })
      .catch(error => {
        console.error(error);
      });    
  }, [])


  const handleExport = (e) => {
    setLoading(true)
    fetch(process.env.REACT_APP_API_URL + e, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
    .then(response => response.blob())
    .then(blob => {
      const url = URL.createObjectURL(blob);
  
      const a = document.createElement('a');
      a.href = url;
      a.download = 'listagem.csv';
      document.body.appendChild(a);
  
      a.click();
  
      document.body.removeChild(a);
      setLoading(false)
    })
    .catch(error => {
      console.error('Erro ao exportar dados:', error);
      setLoading(false)
    });
  };



  return (
    <React.Fragment>
      <div className="page-content">
  
        <Row>
          <div className="col-12">
            <div className="page-title-box d-flex align-items-center justify-content-between">
              <h4 className="page-title mb-0 font-size-18">Dashboard</h4>

              <div className="page-title-right">
                <ol className="breadcrumb m-0" style={{gap:'10px'}}>
                  <li><button style={{
                    backgroundColor:'#333',
                    color:'#fff',
                    fontSize:'16px',
                    fontWeight:600,
                    cursor:'pointer',
                    borderRadius:'8px',
                    border:'none'
                  }} onClick={() => handleExport('/export-owners')}>Lista de proprietários</button></li>
                  <li><button style={{
                    backgroundColor:'#333',
                    color:'#fff',
                    fontSize:'16px',
                    fontWeight:600,
                    cursor:'pointer',
                    borderRadius:'8px',
                    border:'none'
                  }} onClick={() => handleExport('/export-captures')}>Lista de captadores</button></li>
                  {/* <li className="breadcrumb-item active">Sort Investimentos</li> */}
                </ol>
              </div>

            </div>
          </div>
        </Row>

        <Row>
          <Col lg={3}>
            <Card>
              <CardBody>
                <div className="d-flex align-items-start">
                  <div className="avatar-sm font-size-20 me-3">
                    <span className="avatar-title bg-soft-primary text-primary rounded">
                      <i className="mdi mdi-home"></i>
                    </span>
                  </div>
                  <div className="flex-1">
                    <div className="font-size-16 mt-2">Imóveis ativos</div>
                  </div>
                </div>
                <h4 className="mt-4">{(price1Total / 100).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</h4>
                <div className="row">
                  <div className="col-7" style={{height:'20px'}}>
                  </div>
                  <div className="col-5 align-self-center">

                    <Progress
                      value="62"
                      color="primary"
                      className="bg-transparent progress-sm"
                      size="sm"
                    />
                  </div>
                </div>
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <div className="d-flex align-items-start">
                  <div className="avatar-sm font-size-20 me-3">
                    <span className="avatar-title bg-soft-primary text-primary rounded">
                      <i className="mdi mdi-home-currency-usd"></i>
                    </span>
                  </div>
                  <div className="flex-1">
                    <div className="font-size-16 mt-2">Imóveis vendidos</div>

                  </div>
                </div>
                <h4 className="mt-4">{(price2Total /100).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</h4>
                <Row>
                  <div className="col-7" style={{height:'20px'}}>
                  </div>
                  <div className="col-5 align-self-center">
                    <Progress
                      value="62"
                      color="success"
                      className="bg-transparent progress-sm"
                      size="sm"
                    />
                  </div>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col lg={6}>
            <SalesAnalytics
              inativos={status0Count}
              ativos={status1Count}
              vendidos={status2Count}
            />

          </Col>
          <Col lg={3}>
            <RevenueChart
              imoveis={propertyPerMonth}
            />
          </Col>
        </Row>

        <Row>
          <Inbox />
          <LatestTransaction
            imoveis={recentUpdate}
          />
        </Row>

        {loading &&
          <Loading>
            <Spinner></Spinner>
          </Loading>
        }

      </div>
    </React.Fragment>
  )
}

export default Dashboard

const Loading = styled.div`
  width:100%;
  height:100%;
  position:fixed;
  top:0;
  bottom:0;
  left:0;
  right:0;
  background-color:rgba(0,0,0,0.6);
  display:flex;
  align-items:center;
  justify-content:center;
  z-index:9999;
`;