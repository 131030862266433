import React, { useEffect, useState } from 'react'
import { Card, CardBody, Col, Row, Spinner } from 'reactstrap'
import styled from 'styled-components';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import moment from 'moment';

export const LeadsList = () => {
  const [leads, setLeads] = useState([])

  useEffect(() => {
    fetch(process.env.REACT_APP_API_URL + '/leads', {
      headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
    })
    .then((res) => res.json())
    .then(response => {
      setLeads(response)
    })
  }, [])

  return(
    <React.Fragment>
        <div className="page-content">
            <Breadcrumbs title="Gestão de usuários" breadcrumbItem="Leads" />
            <Row>
            <Col lg="12">
                <Card>
                <CardBody>
                    <h4 className="card-title">Usuários que fizeram download do Guia do Investidor</h4>
                    <div className="form-wizard-wrapper wizard clearfix">
                      <LeadContainer>
                        <div><b>Data</b></div>
                        <div><b>Nome</b></div>
                        <div><b>E-mail</b></div>
                        <div><b>Telefone</b></div>
                      </LeadContainer>
                      {leads.map((lead, index) => (
                          <LeadContainer key={index}>
                            <div>{moment(lead?.date).format('DD/MM/YYYY')}</div>
                            <div>{lead.name}</div>
                            <div>{lead.email}</div>
                            <div>{lead.phone}</div>
                          </LeadContainer>
                      ))}
                    </div>
                </CardBody>
                </Card>
            </Col>
            </Row>

        </div>

        <Load id="load">
          <Spinner className="me-2" color="success" />
        </Load>
    </React.Fragment>
  )
}

const LeadContainer = styled.div`
  display:flex;
  flex-wrap:wrap;
  width:100%;
  border-bottom:solid 1px #cdcdcd;
  padding:15px 0;
  gap:10px;

  & div{
    flex:2;
  }
  & div:nth-child(1){
    flex:1;
  }
`;

const Load = styled.div`
  display:none;

  &.active{
    display:flex;
    position: fixed;
    top:0;
    bottom:0;
    left:0;
    right:0;
    background-color: rgba(0,0,0,0.5);
    align-items: center;
    justify-content: center;
    z-index:99999999999999;
  }
`;