import { useState, useEffect } from 'react';
import './style.scss';
import {
  Col,
  Form,
  Input,
  Row
} from "reactstrap"
import styled from 'styled-components';
import MoneyInput from '../../../components/moneyInput';
import Select from "react-select";

const Step1 = ({ handleChildStep1 }) => {
  const [owner, setOwner] = useState('');
  const [whatsapp, setWhatsapp] = useState('');
  const [unity, setUnity] = useState('');
  const [objective, setObjective] = useState('');
  const [typeImmobile, setTypeImmobile] = useState('house');
  const [fee, setFee] = useState('');
  const [iptu, setIptu] = useState('');
  const [calcao, setCalcao] = useState(false);
  const [seguro, setSeguro] = useState(false);
  const [fiador, setFiador] = useState(false);
  const [observation, setObservation] = useState('');
  const [construction, setConstruction] = useState([]);
  const [ constructionList, setConstructionList] = useState([]);
  const [partnership, setPartnership] = useState('');
  const [tax, setTax] = useState(false);
  const [consultant, setConsultant] = useState('');
  const [pricePrevious, setPricePrevious] = useState('0');
  const [price, setPrice] = useState('');

  const [condition, setCondition] = useState('');

  const dataToSend = {
    owner,
    whatsapp,
    unity,
    objective,
    typeImmobile,
    fee,
    iptu,
    calcao,
    seguro,
    fiador,
    observation,
    partnership,
    construction,
    condition,
    consultant,
    pricePrevious,
    price,
    tax
  };

  useEffect(() => {
    handleChildStep1(dataToSend);
  }, [owner, 
      whatsapp, 
      unity, 
      objective, 
      typeImmobile, 
      fee, 
      iptu, 
      calcao, 
      seguro, 
      fiador, 
      construction,
      observation, 
      condition,
      tax,
      pricePrevious,
      price,
      consultant,
      partnership]);


      useEffect(() => {
        fetch(process.env.REACT_APP_API_URL + '/all-enterprises-no-paginate',{
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        })
        .then((res) => res.json())
        .then(response => {
          const constructions = []
          for(var i = 0; response.enterprises?.length > i; i++){
            constructions.push({
              value: response.enterprises[i].id,
              label: response.enterprises[i].name
            })
          }
          setConstructionList(constructions)
    
        })
      }, [])

      const valorPromocional = (newValue) => {
        setPricePrevious(newValue)
      };
      const valor = (newValue) => {
        setPrice(newValue)
      };
      const valorCondominio = (newValue) => {
        setFee(newValue)
      };
      const valorIptu = (newValue) => {
        setIptu(newValue)
      };

  return(
    <div>
      <Form>
      <Row>
              <h5 className="title-imovel">Empreendimento do imóvel</h5>
              <Row style={{margin:'30px 0'}}>
                <Col md={4}>
                <Row className="mb-3">
                  <label htmlFor="txtStateProvinceShipping"
                    className="col-lg-3 col-form-label">Empreendimento</label>
                  <div className="col-lg-9">
                    <Select
                        value={construction}
                        placeholder="Selecione"
                        noOptionsMessage={(inputValue) => `Sem resultados`}
                        onChange={(e) => {
                            setConstruction(e)
                        }}
                        options={constructionList}
                        classNamePrefix="select2-selection"
                      />
                  </div>
                </Row>
                </Col>
              </Row>
            </Row>
        <Row>
          <h5 className="title-proprietario">Sobre o proprietário</h5>
          <Row>
            <Col md={6}>
              <Row className="mb-3">
                <label htmlFor="txtFirstNameBilling"
                  className="col-lg-3 col-form-label">Nome</label>
                <div className="col-lg-9">
                  <Input 
                    id="txtFirstNameBilling" 
                    name="txtFirstNameBilling" 
                    placeholder="Nome do proprietário"
                    type="text" 
                    className="form-control" 
                    value={owner}
                    onChange={e => setOwner(e.target.value)}
                  />
                </div>
              </Row>
            </Col>
            <Col md={6}>
              <Row className="mb-3">
                <label htmlFor="txtLastNameBilling"
                  className="col-lg-3 col-form-label">Telefone</label>
                <div className="col-lg-9">
                  <Input 
                    id="txtLastNameBilling" 
                    name="txtLastNameBilling" 
                    placeholder="Telefone do proprietário"
                    type="text" 
                    className="form-control"
                    value={whatsapp}
                    onChange={e => setWhatsapp(e.target.value)}
                  />
                </div>
              </Row>
            </Col>
            <Col md={6}>
              <Row className="mb-3">
                <label htmlFor="txtFaxBilling" className="col-lg-3 col-form-label">Parceria com corretor</label>
                <div className="col-lg-9">
                  <Input 
                    id="txtFaxBilling" 
                    name="txtFaxBilling" 
                    type="text" 
                    placeholder="Parceria com corretor"
                    className="form-control" 
                    value={partnership}
                    onChange={e => setPartnership(e.target.value)}
                  />
                </div>
              </Row>
            </Col>
            <Col md={6}>
              <Row className="mb-3">
                <label htmlFor="txtNameCard" className="col-lg-3 col-form-label">Captação</label>
                <div className="col-lg-9">
                  <Input 
                    id="txtNameCard" 
                    name="txtNameCard" 
                    type="text" 
                    placeholder="Captação"
                    className="form-control" 
                    value={consultant}
                    onChange={e => setConsultant(e.target.value)}
                  />
                </div>
              </Row>
            </Col>
          </Row>
        </Row>

        <Row>
          <h5 className="title-negocio">Sobre o negócio</h5>                            
          <Row>
            <Col md={6}>
              <Row className="mb-3">
                <label htmlFor="txtAddress1Billing"
                  className="col-lg-3 col-form-label">Tipo de negócio</label>
                <div className="col-lg-9">
                  <BusinessTypeContainer>
                    <BusinessType className={objective === 'sell' ? 'active' : ''} onClick={() => setObjective('sell')}>Venda</BusinessType>
                    <BusinessType className={objective === 'rent' ? 'active' : ''} onClick={() => setObjective('rent')}>Locação</BusinessType>
                  </BusinessTypeContainer>
                </div>
              </Row>
            </Col>
          </Row>
          <Row>
            {objective === 'sell' &&
            <>
              <Col md={6}>
                <Row className="mb-3">
                  <label htmlFor="txtCityShipping" className="col-lg-3 col-form-label">Condições de Pagamento</label>
                  <div className="col-lg-9">
                    <Input 
                      id="txtCityShipping" 
                      name="txtCityShipping" 
                      type="textarea" 
                      placeholder="Condições de Pagamento"
                      className="form-control" 
                      value={condition}
                      onChange={e => setCondition(e.target.value)}
                    />
                  </div>
                </Row>
              </Col>
              <Col md={6}>
                <Row className="mb-3">
                  <label htmlFor="txtFaxBilling" className="col-lg-3 col-form-label">Observação</label>
                  <div className="col-lg-9">
                    <Input 
                      id="txtFaxBilling" 
                      name="txtFaxBilling" 
                      type="textarea"
                      className="form-control" 
                      value={observation}
                      onChange={e => setObservation(e.target.value)}
                    />
                  </div>
                </Row>
              </Col>
            </>  
            }
            {objective === 'rent' &&
            <>
              <Col md={6}>
                <Row className="mb-3">
                  <label htmlFor="txtTelephoneBilling"
                    className="col-lg-3 col-form-label">Garantia de Locação</label>
                  <div className="col-lg-9">
                    <CheckBoxContainer>
                      <Input 
                        id="deposito" 
                        name="txtTelephoneBilling"
                        type="checkbox" 
                        className="form-control" 
                        value={calcao}
                        onChange={e => setCalcao(e.target.checked)}
                      />
                      <label htmlFor="deposito">Déposito Caução</label>
                    </CheckBoxContainer>
                    <CheckBoxContainer>
                      <Input 
                        id="seguro"
                        name="txtTelephoneBilling"
                        type="checkbox" 
                        className="form-control" 
                        value={seguro}
                        onChange={e => setSeguro(e.target.checked)}
                      />
                      <label htmlFor="seguro">Seguro Fiança</label>
                    </CheckBoxContainer>
                    <CheckBoxContainer>
                      <Input 
                        id="fiador" 
                        name="txtTelephoneBilling"
                        type="checkbox" 
                        className="form-control" 
                        value={fiador}
                        onChange={e => setFiador(e.target.checked)}
                        />
                      <label htmlFor="fiador">Fiador</label>
                    </CheckBoxContainer>
                  </div>
                </Row>
              </Col>
              <Col md={6}>
                <Row className="mb-3">
                  <label htmlFor="txtFaxBilling" className="col-lg-3 col-form-label">Observação</label>
                  <div className="col-lg-9">
                    <Input 
                      id="txtFaxBilling" 
                      name="txtFaxBilling" 
                      type="textarea"
                      className="form-control" 
                      value={observation}
                      onChange={e => setObservation(e.target.value)}
                    />
                  </div>
                </Row>
              </Col>
            </>  
            }
          </Row>

          <Row>
              <Col md={6}>
                <Row className="mb-3">
                  <label htmlFor="txtEmailAddressShipping"
                    className="col-lg-3 col-form-label">Valor promocional</label>
                  <div className="col-lg-9">
                    <MoneyInput defaultValue={''} onChange={valorPromocional} />
                  </div>
                </Row>
              </Col>
              <Col md={6}>
                <Row className="mb-3">
                  <label htmlFor="txtCityShipping" className="col-lg-3 col-form-label">Valor</label>
                  <div className="col-lg-9">
                    <MoneyInput defaultValue={''} onChange={valor} />
                  </div>
                </Row>
              </Col>
              <Col md={6}>
                <Row className="mb-3">
                  <label htmlFor="txtCityBilling" className="col-lg-3 col-form-label">Condomínio</label>
                  <div className="col-lg-9">
                    <MoneyInput defaultValue={''} onChange={valorCondominio} />
                  </div>
                </Row>
              </Col>
              <Col md={6}>
                <Row className="mb-3">
                  <label htmlFor="txtStateProvinceBilling"
                    className="col-lg-3 col-form-label">IPTU</label>
                  <div className="col-lg-9">
                    <MoneyInput defaultValue={''} onChange={valorIptu} />
                  </div>
                </Row>
              </Col>
              <Col md={6}>
                  <Row className="mb-3">
                  <label htmlFor="txtTelephoneBilling"
                    className="col-lg-3 col-form-label"></label>
                  <div className="col-lg-9">
                    <CheckBoxContainer>
                        <Input 
                          id="tax" 
                          name="txtTelephoneBilling"
                          type="checkbox" 
                          className="form-control" 
                          value={tax}
                          onChange={e => setTax(e.target.checked)}
                        />
                        <label htmlFor='tax'>Taxas inclusas?</label>
                    </CheckBoxContainer>
                    </div>
                  </Row>
              </Col>
            </Row>
        </Row>
      
      </Form>
    </div>
  )
}

export default Step1

const BusinessType = styled.div`
  background-color:#888;
  color:#fff;
  border-radius:5px;
  width:120px;
  height:35px;
  display:flex;
  align-items:center;
  justify-content:center;
  cursor:pointer;

  &.active{
    background-color:rgb(0, 62, 14);
  }
`;

const BusinessTypeContainer = styled.div`
  display:flex;
  gap:20px;
`;

const CheckBoxContainer = styled.div`
  display:flex;
  gap:10px;
  align-items:center;
  cursor:pointer;

  label{
    margin-top:7px;
    cursor:pointer;
  }
`;