import React, { useEffect, useState } from "react"
import {
    Card,
    CardBody,
    Col,
    Input,
    Row,
  } from "reactstrap"
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import styled from 'styled-components';
import UserCard from "./userCard";

const EditUserList = () => {
  const [ userList, setUserList ] = useState([]);
  const [ search, setSearch ] = useState('');

  useEffect(() => {
      fetch(process.env.REACT_APP_API_URL + '/users', {
        headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
      })
      .then((res) => res.json())
      .then(response => {
        setUserList(response.users)
      })
    }, [])

  return(
  <React.Fragment>
      <div className="page-content">
          <Breadcrumbs title="Gestão de usuários" breadcrumbItem="Usuários" />
          <Row>
          <Col xs={12}>
              <Card>
              <CardBody>
                  <h4 className="card-title">Usuários</h4>
                  <Row>
                      <Col md={6}>
                          <Row className="mb-3">
                              <label htmlFor="txtFirstNameBilling"
                              className="col-lg-2 col-form-label">Busca</label>
                              <div className="col-lg-10">
                              <Input 
                                  id="txtFirstNameBilling" 
                                  name="txtFirstNameBilling" 
                                  placeholder="Busque por nome"
                                  type="text" 
                                  className="form-control" 
                                  value={search}
                                  onChange={e => setSearch(e.target.value)}
                              />
                              </div>
                          </Row>
                      </Col>
                  </Row>
                  <OptionsGrid>
                      {userList.filter(users => users.name.toLowerCase().includes(search.toLowerCase())).map((user, index) => (
                          <UserCard key={index} data={user}/>
                      ))}
                  </OptionsGrid>
              </CardBody>
              </Card>
          </Col>
          </Row>
      </div>
  </React.Fragment>
  )
}

export default EditUserList;

const OptionsGrid = styled.div`
    display:grid;
    margin:30px 0;
    padding:20px;
    grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
    gap:20px;
`;