import React, { useState, useRef } from "react"
import {
  Button,
    Card,
    CardBody,
    Col,
    Input,
    NavItem,
    NavLink,
    Row,
    Spinner,
    TabContent,
    TabPane
  } from "reactstrap"
import Breadcrumbs from "../../../../components/Common/Breadcrumb";
import styled from 'styled-components';
import classnames from "classnames"
import { Link } from "react-router-dom"
import Step1 from "./Step1"
import Step2 from "./Step2"
import Step3 from "./Step3"
import Step4 from "./Step4"
import Step5 from "./Step5"
import axios from 'axios';

const AddEnterprise = () => {
    const [activeTab, setactiveTab] = useState(1);
    const [childDataStep1, setChildDataStep1] = useState({});
    const [childDataStep2, setChildDataStep2] = useState({});
    const [childDataStep3, setChildDataStep3] = useState({});
    const [childDataStep4, setChildDataStep4] = useState({});
    const [childDataStep5, setChildDataStep5] = useState({});
    const [typeRent, setTypeRent] = useState([]);
    const [sendProp, setSendPro] = useState([])
    const [status, setStatus] = useState(0)
  
  
    function toggleTab(tab) {
      if (activeTab !== tab) {
        if (tab >= 1 && tab <= 4) {
          setactiveTab(tab)
        }
      }
    }
  
    const handleChildStep1 = (receivedChildData) => {
      setChildDataStep1(receivedChildData);
    };
  
    const handleChildStep2 = (receivedChildData) => {
      setChildDataStep2(receivedChildData);
      console.log(receivedChildData)
    };
  
    const handleChildStep3 = (receivedChildData) => {
      setChildDataStep3(receivedChildData);
      console.log(receivedChildData)
    };
  
     const handleChildStep4 = (receivedChildData) => {
       setChildDataStep4(receivedChildData);
     };
  
    const handleChildStep5 = (receivedChildData) => {
      setChildDataStep5(receivedChildData);
    };
  
    const handleSubmit = (event) => {
      event.preventDefault();
      document.getElementById('load').classList.add('active');

       const data = new FormData()
       
        data.append('type', childDataStep2.type)
        data.append('address', childDataStep2.address)
        data.append('video', childDataStep2.video)
        data.append('cep', childDataStep2.cep)
        if(childDataStep2.city.value && childDataStep2.city.value != 'undefined'){data.append('city', childDataStep2.city?.value)}
        data.append('complement', childDataStep2.complement)
        data.append('description', childDataStep2.description)
        data.append('district', childDataStep2.district)
        if(childDataStep2.state.value && childDataStep2.state.value != 'undefined'){data.append('state', childDataStep2.state?.value)}
        data.append('country', childDataStep2.country)
        data.append('number', childDataStep2.number ? childDataStep2.number : 0)
        if(childDataStep2.construction.value && childDataStep2.construction.value != 'undefined'){data.append('construction_id', childDataStep2.construction.value)}
        data.append('hight_photo', childDataStep2.selectedFile?.file)
        data.append('name', childDataStep2.name)
        data.append('slug', childDataStep2.urlParameter)


      fetch(process.env.REACT_APP_API_URL + '/create-new-enterprise', {
        method: 'POST',
        body: data,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      .then(response => response.json())
      .then(resp => {
        
        const propertyId = resp.id;
        
        const details = new FormData()
        if(childDataStep3.detailsImobile && childDataStep3.detailsImobile.length > 0){
          for(let i = 0; i < childDataStep3.detailsImobile.length; i++){
            console.log(childDataStep3.detailsImobile[i])
            details.append(`detail[${i}][detail]`, childDataStep3.detailsImobile[i].details)
            details.append(`detail[${i}][position]`, childDataStep3.detailsImobile[i].position)
            details.append(`detail[${i}][enterprise_id]`, propertyId)
          }
          setTimeout(() => {
            axios.post(process.env.REACT_APP_API_URL + '/create-enterprise-detail', details)
          }, 300);

        }

        const skills = new FormData()
        if(childDataStep3.skillsImobile && childDataStep3.skillsImobile.length > 0){
          for(let i = 0; i < childDataStep3.skillsImobile.length; i++){
            skills.append(`skill[${i}][skill]`, childDataStep3.skillsImobile[i].details)
            skills.append(`skill[${i}][enterprise_id]`, propertyId)
          }
          setTimeout(() => {
            axios.post(process.env.REACT_APP_API_URL + '/create-enterprise-skill',skills)
          }, 500);

        }

        console.log(childDataStep5.selectedFiles)

        const promises = [];

        if(childDataStep5.selectedFiles && childDataStep5.selectedFiles.length > 0){
          for (let i = 0; i < childDataStep5.selectedFiles.length; i++) {
            const photos = new FormData();
            photos.append(`file`, childDataStep5.selectedFiles[i].file);
            photos.append(`position`, childDataStep5.selectedFiles[i].position);
            photos.append(`alt`, childDataStep5.selectedFiles[i].title);
            photos.append(`url`, childDataStep2.urlParameter + '-' + i);
            photos.append(`enterprise_id`, propertyId);
    
            promises.push(axios.post(process.env.REACT_APP_API_URL + '/create-enterprise-photo', photos));
        }
    
        Promise.all(promises)
            .then(() => {
                window.open('./../cadastro-propriedade', "_parent")
            })
            .catch(error => {
                console.error('Erro ao enviar requisições:', error);
            });
        }else{
          setTimeout(() => {
              window.open('./../cadastro-propriedade', "_parent")
          }, 2000);
        }
      
      })
        .catch(error => {
          console.error(error);
        });    
    }

    return(
    <React.Fragment>
        <div className="page-content">
            <Breadcrumbs title="Gestão de Imóveis" breadcrumbItem="Novo Imóvel" />
            <Row>
            <Col lg="12">
                <Card>
                <CardBody>
                    <h4 className="card-title">Adicionar empreendimento</h4>
                    <div className="form-wizard-wrapper wizard clearfix">
                        <div className="steps clearfix">
                            <ul>
                            {/* <NavItem
                                className={classnames({ current: activeTab === 1 })}>
                                <NavLink
                                className={classnames({ current: activeTab === 1 })}
                                onClick={() => {
                                    setactiveTab(1)
                                }}
                                >
                                <span className="number">1.</span>{" "}
                                    Sobre o Proprietário e Negócio
                                </NavLink>
                            </NavItem> */}
                            <NavItem className={classnames({ current: activeTab === 1 })}>
                                <NavLink
                                className={classnames({ active: activeTab === 1 })}
                                onClick={() => {
                                    setactiveTab(1)
                                }}
                                >
                                <span className="number">1.</span>{" "}
                                    Sobre Imóvel
                                </NavLink>
                            </NavItem>
                            <NavItem className={classnames({ current: activeTab === 2 })}>
                                <NavLink
                                className={classnames({ active: activeTab === 2 })}
                                onClick={() => {
                                    setactiveTab(2)
                                }}
                                >
                                <span className="number">2.</span>
                                Características
                                </NavLink>
                            </NavItem>
                            <NavItem className={classnames({ current: activeTab === 3 })}>
                                <NavLink
                                className={classnames({ active: activeTab === 3 })}
                                onClick={() => {
                                    setactiveTab(3)
                                }}
                                >
                                <span className="number">3.</span>
                                Fotos do Imóvel
                                </NavLink>
                            </NavItem>
                            <NavItem className={classnames({ current: activeTab === 4 })}>
                                <NavLink
                                className={classnames({ active: activeTab === 4 })}
                                onClick={() => {
                                    setactiveTab(4)
                                }}
                                >
                                <span className="number">4.</span>
                                Confirmar cadastro
                                </NavLink>
                            </NavItem>
                            </ul>
                        </div>
                        <div className="content clearfix">
                            <TabContent
                            activeTab={activeTab}
                            className="body"
                            >
                            {/* <TabPane tabId={1}>
                                <Step1 handleChildStep1={handleChildStep1}/>
                            </TabPane> */}

                            <TabPane tabId={1}>
                                <Step2 handleChildStep2={handleChildStep2}/>
                            </TabPane>

                            <TabPane tabId={2}>
                                <Step3 handleChildStep3={handleChildStep3}/>
                            </TabPane>

                            <TabPane tabId={3}>
                                <Step5
                                  handleChildStep5={handleChildStep5}
                                  title={childDataStep2?.name}
                                  url={childDataStep2?.urlParameter}
                                  send={sendProp}
                                />
                            </TabPane>

                            <TabPane tabId={4}>
                                <div className="row justify-content-center">
                                <Col lg="6">
                                    <div className="text-center">
                                    <div className="mb-4">
                                        <i className="mdi mdi-check-circle-outline text-success display-4" />
                                    </div>
                                    <div>
                                        <h5>Tudo pronto, confirmar cadastro.</h5>
                                        <p className="text-muted">
                                        Você pode voltar e conferir tudo antes se quiser.
                                        </p>
                                        <Button onClick={handleSubmit}>Enviar</Button>
                                    </div>
                                    </div>


                                </Col>
                                </div>
                            </TabPane>
                            </TabContent>
                        </div>
                        <div className="actions clearfix">
                            <ul>
                            <li
                                className={
                                activeTab === 1 ? "previous disabled" : "previous"
                                }
                            >
                                <Link
                                to="#"
                                className="btn btn-primary"
                                onClick={() => {
                                    toggleTab(activeTab - 1)
                                }}
                                >
                                Anterior
                                </Link>
                            </li>
                            <li
                                className={activeTab === 4 ? "next disabled" : "next"}
                            >
                                <Link
                                to="#"
                                className="btn btn-primary"
                                onClick={() => {
                                    toggleTab(activeTab + 1)
                                }}
                                >
                                Próximo
                                </Link>
                            </li>
                            </ul>
                        </div>
                        </div>
                </CardBody>
                </Card>
            </Col>
            </Row>

        </div>

        <Load id="load">
          <Spinner className="me-2" color="success" />
        </Load>
    </React.Fragment>
    )
}

export default AddEnterprise;

const Load = styled.div`
  display:none;

  &.active{
    display:flex;
    position: fixed;
    top:0;
    bottom:0;
    left:0;
    right:0;
    background-color: rgba(0,0,0,0.5);
    align-items: center;
    justify-content: center;
    z-index:99999999999999;
  }
`;