import React, { Component } from "react"
import { Row, Col, Card, CardBody } from "reactstrap"
import ReactApexChart from "react-apexcharts"

class SalesAnalytics extends Component {
  constructor(props) {
    super(props)

    this.state = {
      series: [0, 0, 0],
      options: {
        labels: ["Ativos", "Vendidos", "Inativos"],
        plotOptions: {
          pie: {
            donut: {
              size: '75%'
            }
          }
        },
        legend: {
          show: false,
        },
        colors: ['#3b5de7', '#45cb85', '#eeb902'],
      },
    }
  }

  componentDidUpdate(prevProps) {
    // Verificar se as props foram alteradas
    if (
      this.props.ativos !== prevProps.ativos ||
      this.props.vendidos !== prevProps.vendidos ||
      this.props.inativos !== prevProps.inativos
    ) {
      // Atualizar o estado com as novas props
      this.setState({
        series: [this.props.ativos, this.props.vendidos, this.props.inativos],
      });
    }
  }

  render() {
    return (
      <React.Fragment>
        <Card>
          <CardBody>
            <h4 className="card-title mb-4">Imóveis cadastrados</h4>

            <Row className="align-items-center">
              <Col sm={6}>
                <ReactApexChart
                  options={this.state.options}
                  series={this.state.series}
                  type="donut"
                  height={280}
                  className="apex-charts"
                />
              </Col>
              <Col sm={6}>
                <div>
                  <Row>
                    <div className="col-6">
                      <div className="py-3">
                        <p className="mb-1 text-truncate"><i
                          className="mdi mdi-circle text-primary me-1"></i>{" "}Ativos
                            </p>
                        <h5>{this.props.ativos}</h5>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="py-3">
                        <p className="mb-1 text-truncate"><i
                          className="mdi mdi-circle text-success me-1"></i>{" "}Vendidos</p>
                        <h5>{this.props.vendidos}</h5>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="py-3">
                        <p className="mb-1 text-truncate"><i
                          className="mdi mdi-circle text-warning me-1"></i>{" "}Inativos</p>
                        <h5>{this.props.inativos}</h5>
                      </div>
                    </div>
                  </Row>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </React.Fragment>
    )
  }
}

export default SalesAnalytics
